import { clientLogout } from "@/commonSlices/authSlice";
import React, { Component } from "react";
import { convertMonthFormat, httpRequest } from "@/util/common";
import { connect } from "react-redux";
import PlanPay from "./PlanPay";

class PlanPayContainer extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;
    this.state = location.query || { planType: "", planName: "", price: "" , planInfo: {}, paypalId: ""};
  }

  componentDidMount() {
    const { location, history } = this.props;

    if (!location.query) {
      history.push("/");
    } else {

    }
  }
  
  render() {
    const { planType = "", planName = "", price = "" ,planInfo = {} , paypalId = ""} = this.state;
    return (
      <PlanPay
        planType={planType}
        planName={planName}
        price={price}
        planInfo={planInfo}
        paypalId = {paypalId}
        subscriptionInsert={this.handleSubmit}
      />
    );
  }

  /**
   * 구독정보 저장하기
   * @param {*} e
   */
  handleSubmit = async (e) => {
    e.preventDefault();
    const { planType = "", planName = "", price = "" , paypalId = "" } = this.state;


    const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
    const submitInfo = {
      userIdx: userInfo.idx,
      planId: planType,
      paypalId:paypalId,

    };

    const { history } = this.props;
    try {

      const { status } = await httpRequest.post(
        `plan/subscription`,
        submitInfo
      );
      if (status !== 200)
        throw new Error("구독정보 제출 중 에러가 발생하였습니다.");

      //window.location.href = `/join/regist?email=${email}`;
    } catch (err) {
      history.push(`/error?code=${err.response.status}`);
    }

  };


}

export default connect(null, { clientLogout })(PlanPayContainer);
