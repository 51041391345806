import React from "react";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const USGuide = () => {
  const { t } = useTranslation();
  return (
    <div className="card pd-0">
      <div className="card__main pd-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"border-top-0"} scope="col" colSpan="1">
              <Trans >{t('numberType')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('numberFormat')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('example')}</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4"><Trans >{t('applicationNumber')}</Trans></td>
              <td><Trans >{t('y4ss6')}</Trans></td>
              <td>2012-532918</td>
            </tr>
            <tr>
              <td><Trans >{t('usps2ps6')}</Trans></td>
              <td>US13532918</td>
            </tr>
            <tr>
              <td><Trans >{t('s2ss6')}</Trans></td>
              <td>13-532918</td>
            </tr>
            <tr>
              <td><Trans >{t('s8')}</Trans></td>
              <td>13532918</td>
            </tr>
            <tr>
              <td rowSpan="4"><Trans >{t('publicationNumber')}</Trans></td>
              <td><Trans >{t('y4ss7')}</Trans></td>
              <td>2009-0135462</td>
            </tr>
            <tr>
              <td><Trans >{t('uspy4ps7')}</Trans></td>
              <td>US20090135462</td>
            </tr>
            <tr>
              <td><Trans >{t('usps6pdc')}</Trans></td>
              <td>US20090135462A1</td>
            </tr>
            <tr>
              <td><Trans >{t('s11')}</Trans></td>
              <td>20090135462</td>
            </tr>
            <tr>
              <td rowSpan="4"><Trans >{t('regNumber')}</Trans></td>
              <td><Trans >{t('s7')}</Trans></td>
              <td>7787591</td>
            </tr>
            <tr>
              <td><Trans >{t('s7')}</Trans></td>
              <td>07787591</td>
            </tr>
            <tr>
              <td><Trans >{t('usps8')}</Trans></td>
              <td>US7787591</td>
            </tr>
            <tr>
              <td><Trans >{t('usps6pdc')}</Trans></td>
              <td>US7787591B2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default USGuide;
