import React from "react";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const ChangePassword = ({
  passwordInfo,
  onChangeFieldValue,
  errors,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="card w-full">
        <div className="card__header pd-0">Account</div>
        <div className="card__main">
          <div className="form-group mb-15">
            <label><Trans >{t('currentPassword')}</Trans></label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="password"
                name="currentPassword"
                placeholder={t('currentPassword')}
                value={passwordInfo.currentPassword}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.currentPassword}</p>
          </div>
          <div className="form-group mb-15">
            <label><Trans >{t('newPassword')}</Trans></label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="password"
                name="newPassword1"
                placeholder={t('newPasswordCharacter')}
                value={passwordInfo.newPassword1}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.newPassword1}</p>
          </div>
          <div className="form-group mb-15">
            <label><Trans >{t('confirmNewPassword')}</Trans></label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="password"
                name="newPassword2"
                placeholder={t('confirmNewPassword')}
                value={passwordInfo.newPassword2}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.newPassword2}</p>
          </div>
        </div>
        <div className="card__footer align-items-end">
          <button className="btn btn-s mb-5">{t('changePassword')}</button>
        </div>
      </div>
    </form>
  );
};

export default ChangePassword;
