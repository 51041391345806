import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; //

const QuitSuccess = () => {
  const { t } = useTranslation();
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-check" />
          </div>
          <div className="round-text-bold text-lg">
            {/* 회원탈퇴가 완료되었습니다. */}
            <p className={"text-align-center"}>{t('withdrawSuccess1')}</p>
          </div>
        </div>
        <div className="result-page__contents-sub">
          <p className="font-size-20">
            {/* 보다 나은 IP KINGKONG으로 다시 만나 뵐 수 있기를 바랍니다. */}
            {t('withdrawSuccess2')}
          </p>
        </div>
        <div className="result-page__contents-direction">
          <Link to={"/"} className="btn btn-primary btn-xxl">
            {t('home')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default QuitSuccess;
