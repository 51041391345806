import { calculateCash, commafy, TRANS_CODE_DATA } from "@/util/common";
import React from "react";
import "./TransPreview.scss";
import { Trans, useTranslation } from 'react-i18next'; // 

const TransPreview = ({
  chosen,
  savePct,
  onClickTransType,
  planName,
  planCapacity,
  planUsage
 }) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang") || "en"; 
  return (
    <div className={"trans-preview"}>
      <div className={"d-flex w-full"}>
        <table className="table table-bordered w-full">
          <thead>
            <tr>
              <th style={{ width: 250 }} className="text-center">
              {t('literatureName')}
              </th>
              <th style={{ width: 150 }} className="text-center">
              {t('traslationScope')}
              </th>
              <th style={{ width: 100 }} className="text-center">
              {t('translationType')}
              </th>
              <th style={{ width: 130 }} className="text-center">
              {t('characterCount')}
              </th>
              <th className="text-center">{t('amount')}</th>
            </tr>
          </thead>
          <tbody>
            {Object.values(chosen).map((doc, i) => (
              <tr key={`transPreview-${i}`}>
                <td>{doc.documentName}</td>
                <td>
                  {lang === "ko" ? (doc.transCoverage === "F" ? "전체" : "명칭+요약+청구항") : (doc.transCoverage === "F" ? "entire" : "Title + Summary + Claims")}
                </td>
                <td>
                  {
                    TRANS_CODE_DATA[`${doc.orgLang + doc.transLang}`][
                    lang === "ko" ? "typeName" : "typeNameEn"
                    ]
                  }
                </td>
                <td>
                  {
                    TRANS_CODE_DATA[`${doc.orgLang + doc.transLang}`][
                    lang === "ko" ? "orgLangName" : "orgLangNameEn"
                    ]
                  }{" "}
                  {commafy(doc.charCount)}
                  {lang === "ko" ? (doc.orgLang === "EN" ? "단어" : "자") : (doc.orgLang === "EN" ? " word" : " char") }
                </td>
                <td>
                  <div className="c-radio mb-10">
                    <label>
                      <input
                        type="radio"
                        className="check"
                        name={`transType-${i}`}
                        value={"AA"}
                        data-chargepoint={String(
                          calculateCash(
                            doc.charCount,
                            doc.orgLang,
                            doc.transLang,
                            "AA"
                          )
                        )}
                        defaultChecked
                        onChange={(e) => onClickTransType(e, doc)}
                      />
                      <span className="fa fa-check" />
                      <span className={"d-inline-flex"}>
                        <label
                          className="dummy-btn label--bg-basic text-bold mr-10"
                          title={
                            "특허명세서를 학습하여 특허번역에 최적화된 AI 번역엔진으로 번역된 문서로 \n" +
                            "휴먼 번역 대비 평균 90% 정도의 품질의 번역문을 빠르고 저렴하게 제공합니다.\n" +
                            "\n" +
                            " 용도) 특허출원을 위한 명세서의 번역시간 단축을 위한 초벌 번역이 필요한 경우, \n" +
                            " 외국 특허 문서의 내용을 빠르고 심도있게 파악하기 위한 경우"
                          }
                        >
                          BASIC
                        </label>
                        ({t('aiAndHuman')}){" "}
                      </span>
                    </label>
                  </div>
                  <div className="c-radio mb-10">
                    <label>
                      <input
                        type="radio"
                        className="check"
                        name={`transType-${i}`}
                        value={"AB"}
                        data-chargepoint={String(
                          calculateCash(
                            doc.charCount,
                            doc.orgLang,
                            doc.transLang,
                            "AB"
                          )
                        )}
                        onChange={(e) => onClickTransType(e, doc)}
                      />
                      <span className="fa fa-check" />
                      <span className="d-inline-flex">
                        <label
                          className="dummy-btn label--bg-special text-bold mr-10"
                          title={
                            "BASIC 상품에서 제공된 AI 번역문을 기반으로 특허번역전문가가 \n" +
                            "단어나 문장 전반의 번역품질을 검토하고 수정하여 전체적인 번역 품질이 더 높아집니다.\n" +
                            "\n" +
                            " 용도) 휴먼 번역과 유사한 품질의 특허번역문이 필요한 경우\n"
                          }
                        >
                          SPECIAL
                        </label>
                        ({t('translatorReview')}){" "}
                      </span>
                    </label>
                  </div>
                  <div className="c-radio mb-0">
                    <label>
                      <input
                        type="radio"
                        className="check"
                        name={`transType-${i}`}
                        value={"AC"}
                        data-chargepoint={String(
                          calculateCash(
                            doc.charCount,
                            doc.orgLang,
                            doc.transLang,
                            "AC"
                          )
                        )}
                        onChange={(e) => onClickTransType(e, doc)}
                        disabled
                      />
                      <span className="fa fa-check" />
                      <span className={"d-inline-flex text-disabled"}>
                        <label
                          className="dummy-btn label--bg-premium text-bold mr-10"
                          title={
                            "BASIC 상품에서 제공된 AI 번역문을 기반으로 변리사가 \n" +
                            "원문 대비 번역된 권리범위의 품질을 검토하고 수정하여 번역된 특허 품질이 더 높아집니다.\n" +
                            "\n" +
                            " 용도) 별도의 추가검토없이 해외에 출원하는 번역 명세서의 완성이 필요한 경우"
                          }
                        >
                          PREMIUM
                        </label>
                        ({t('attorneyReviewAdd')}){" "}
                      </span>
                    </label>
                  </div>
                </td>

              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
{/* 
      <div className="table-pagination-container">
        <div className="wish-list-control">
          <div>
            <span>{t('PlanDiscount')}</span>
          </div>
        </div>
        <div className="card__main flex-direction-row justify-content-space-evenly">

          <div>
         너군 {t('ratePaln1')} {planName} / {t('maxNumWord')} {commafy(Number(planCapacity))} / {t('planUseCapacity')} {commafy(Number(planUsage))}
          </div>

        </div>
      </div> */}


      <div>
        <p className="total-point text-align-right">
        {t('total')}{" "}
          {commafy(
            Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
          )}
           {t('cash')}
        </p>
        {/* <p className="text-align-right">
          <em className="fa fa-info-circle mr-1" />
          <strong>
          {t('BasicCompareHum')}{" "}
            <span className="text-danger" id="savePct">
              {savePct}%(
              {commafy(
                Object.values(chosen).reduce(
                  (a, c) =>
                    a +
                    Number(
                      calculateCash(c.charCount, c.orgLang, c.transLang, "AA")
                    ),
                  0
                ) * 4
              )}
              {t('won')})
            </span>
            <span className="text-danger">{t('saving')}</span>{t('saving1')}
          </strong>
        </p> */}
      </div>
    </div>
  );
};

export default TransPreview;
