import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Error = ({ location }) => {
  const code = location.search && location.search.split("?code=")[1];

  const { t } = useTranslation();
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-wrench" />
          </div>
          <div className="round-text-bold text-lg">
            <p className={"text-align-center"}>{code || 500}</p>
            <p className={"text-align-center"}>{t('errPageInfo')}</p>
          </div>
        </div>
        <div className="result-page__contents-sub">
          <p className="">{t('errPageInfo1')}</p>
          <p className="">{t('errPageInfo2')}</p>
        </div>
        <div className="result-page__contents-direction">
          <Link to={"/"} className="btn btn-primary btn-xxl">
          {t('home')}
          </Link>
          <Link to={"/login"} className="btn btn-primary btn-xxl">
          <Trans >{t('login')}</Trans>
          </Link>
          <Link to={"/join/form"} className="btn btn-primary btn-xxl">
          {t('register')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Error;
