import React from "react";
import { Link } from "react-router-dom";
import "./BoardInquiryDtl.scss";
import parse from "html-react-parser";
import Loader from "@/commonModule/Loader/Loader";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const BoardInquiryDtl = ({ post, postComment, attachFile, onFileDownload, onDeleteBoard, isLoading }) => {
  const { t } = useTranslation();
  const getTranslatedFaqTypeName = (type) => {
    const typeMapping = {
      '회원정보': 'userInfo',
      '결제/환불': 'payRefune',
      '시스템/에러': 'systemError',
      '사용법': 'howToUse',
      '기타': 'others',
    };
    return t(typeMapping[type] || type);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="page-container">
      {/* 1:1문의상세 */}
      <div className="page__title">{t('inquiryDetail')}</div>
      <div className="divider"></div>
      <div className="card w-full">
        <div className="card__header pd-0">{post.faqTypeName == "" ? post.faqTypeName : getTranslatedFaqTypeName(post.faqTypeName)}</div>
        <div className="board-news-dtl__header">
          <div>{post.title}</div>
          <div>
            <span>
              {/* 작성일시 */}
              <strong>{t('cdat')}</strong>
              {post.regDttmTime}
            </span>
          </div>
        </div>
        <div className="divider"></div>
        <div className={"mb-30"}>
          <div className="board-news-dtl__main">{parse(String(post.contents))}</div>
          <div className="board-attach-file">
            {/* 첨부파일 */}
            <label>{t('attachment')}</label>
            {attachFile.map((v, i) => (
              <div key={`dtl-file-${i}`} className="board-attach-file__item border-warning">
                <Link
                  to="#"
                  onClick={() => onFileDownload(v.fileIdx, v.orgFileName, v.fileName)}
                >
                  {v.orgFileName}
                </Link>
              </div>
            ))}
          </div>
        </div>
        <div className="divider"></div>
        {postComment && postComment.map((v, i) => (
          <div className="card w-full" key={`dtl-comment-${i}`}>
            <div className="card__header pd-0">{v.userName} &nbsp;
              <div className="board-news-dtl__header__sub">
                {v.regDttmTime}
              </div>
              <div className="divider"></div>
            </div>
            <div className="board-news-dtl__main__sub">{v.comment}</div>
            {/* 
            <div className="board-news-dtl__footer">
              <div className="attach-file">
                <label>첨부파일</label>
                {attachFile.map((v, i) => (
                  <div key={`dtl-file-${i}`} className="attach-file__item">
                    <Link
                      to="#"
                      onClick={() => onFileDownload(v.fileIdx, v.orgFileName)}
                    >
                      {v.orgFileName}
                    </Link>
                  </div>
                ))}
              </div>
            </div> 
            */}
          </div>
        ))}
        <div className="divider"></div>
        <div className="card__header d-flex justify-content-space-between align-items-center">
            <div>
              <Link to="/board/inquiry">
                <button className="list-btn">
                  <i className="fas fa-list-ul"></i>
                  {/* 목록 */}
                  <span>{t('inventory')}</span>
                </button>
              </Link>
            </div>
            {(() => {
              const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
              return userInfo && userInfo.idx === post.userIdx ? (
                <div className="d-flex">
                  <Link to={`/board/boardContents/${post.boardContentsIdx}`}>
                    <button className="btn btn-s">
                      <em className="fa fa-check mr-5"></em>
                      {/* 수정 */}
                      <strong>{t('modify')}</strong>
                    </button>
                  </Link>
                  &nbsp;
              <button className="btn btn-s bg-danger" onClick={onDeleteBoard}>
                    <em className="fa fa-times mr-5"></em>
                    {/* 삭제 */}
                    <strong>{t('delete')}</strong>
                  </button>
                </div>
              ) : null; // adminYn이 "Y"가 아닌 경우 null 반환
            })()}
        </div>
      </div>
    </div>  
  );
};

export default BoardInquiryDtl;
