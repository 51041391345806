export const getTranslatedMonthTermInfo = () => {
  const lang = localStorage.getItem("lang") || "ko"; // 기본값은 'ko'로 설정
  return {
    ONE_MONTH: {
      label: lang === "en" ? "1 Month" : "1개월",
      monthTerm: "1",
    },
    THREE_MONTH: {
      label: lang === "en" ? "3 Months" : "3개월",
      monthTerm: "3",
    },
    SIX_MONTH: {
      label: lang === "en" ? "6 Months" : "6개월",
      monthTerm: "6",
    },
    TWELVE_MONTH: {
      label: lang === "en" ? "12 Months" : "12개월",
      monthTerm: "12",
    },
  };
};