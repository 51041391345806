import { clientLogout } from "@/commonSlices/authSlice";
import React, { Component } from "react";
import { convertMonthFormat, httpRequest } from "@/util/common";
import { connect } from "react-redux";
import PlanInfo from "./PlanInfo";

class PlanInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planId: null,  // plan_id를 state로 정의
      userIdx: null,
      planInfo: [],
      paypalId: null,
    };
  }

  componentDidMount() {
    this.getSubscription();
    this.getPlan();
  }

  /**
     * 사용자 구독 가져오기
     * @returns {boolean}
     */
  getSubscription = async () => {
    const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));

    if (userInfo != null) {
      const { history } = this.props;
      try {
        const response = await httpRequest(`plan/subscription/${userInfo.idx}`);
        const { status } = response || {}; // response가 undefined일 경우 대비
        const plan_id = response.data.planId || null;
        const paypal_id = response.data.paypalId || null;

        if (status !== 200) {
          throw new Error("구독정보 제출 중 에러가 발생하였습니다.");
        }
        this.setState({ planId: plan_id, paypalId: paypal_id });  // plan_id를 state로 저장
      } catch (err) {
        console.error("getSubscription에러"+err); // 에러 로그 추가
        history.push(`/error?code=${err.response?.status || 500}`);
      }
    }
  };

  /**
   * 사용자 플랜 가져오기
   */
  getPlan = async () => {
    const { history } = this.props;
    try {
      const response = await httpRequest(`plan/planInfo`);
      const { status } = response || {}; // response가 undefined일 경우 대비
      const planInfo = response.data || null;

      if (status !== 200) {
        throw new Error("구독정보 조회 중 에러가 발생하였습니다.");
      }
      this.setState({ planInfo: planInfo });  // plan_id를 state로 저장
    } catch (err) {
      console.error("getPlan에러"+err); // 에러 로그 추가
      //history.push(`/error?code=${err.response?.status || 500}`);
    }

  };


  render() {
    const {planInfo, paypalId} = this.state
    return (
      <PlanInfo
      getSubscription={this.getSubscription}
      planId={this.state.planId}  
      planInfo={planInfo}
      paypalId = {paypalId}
      />
    );
  }
}

export default connect(null, { clientLogout })(PlanInfoContainer);
