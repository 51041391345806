import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import React from "react";

// 서브 메뉴 리스트를 생성하는 함수
const createSubMenuList = (t) => ({
  userInfo: [
    { name: <Trans>{t('memberInfomation')}</Trans>, url: "/my/userInfo" },
    { name: <Trans>{t('changePassword')}</Trans>, url: "/my/changePassword" },
  ],
  boardInfo: [
    { name: <Trans>{t('news')}</Trans>, url: "/board/news" },
    { name: <Trans>{t('faq')}</Trans>, url: "/board/faq" },
  ],
});

// 서브 메뉴 정보를 반환하는 함수
export const SUB_MENU_INFO = (currentUrl) => {
  const { t } = useTranslation();
  const SUB_MENU_LIST = createSubMenuList(t);

  // 메뉴 정보 객체
  const menuInfo = {
    ["/my/userInfo"]: {
      title: <Trans>{t('myInfo')}</Trans>,
      desc: <Trans>{t('changeMyInfo')}</Trans>,
      menuList: SUB_MENU_LIST.userInfo,
      menuTitle: <Trans>{t('myInfo')}</Trans>,
    },
    ["/my/changePassword"]: {
      title: <Trans>{t('myInfo')}</Trans>,
      desc: <Trans>{t('changeMyInfo')}</Trans>,
      menuList: SUB_MENU_LIST.userInfo,
      menuTitle: <Trans>{t('myInfo')}</Trans>,
    },
    ["/my/quit"]: {
      title: <Trans>{t('myInfo')}</Trans>,
      desc: <Trans>{t('changeMyInfo')}</Trans>,
      menuList: SUB_MENU_LIST.userInfo,
      menuTitle: <Trans>{t('myInfo')}</Trans>,
    },
    ["/board/news"]: {
      title: <Trans>{t('help')}</Trans>,
      desc: <Trans>{t('helpFaqDesc')}</Trans>,
      menuList: SUB_MENU_LIST.boardInfo,
      menuTitle: <Trans>{t('help')}</Trans>,
    },
    ["/board/faq"]: {
      title: <Trans>{t('help')}</Trans>,
      desc: <Trans>{t('helpFaqDesc')}</Trans>,
      menuList: SUB_MENU_LIST.boardInfo, 
      menuTitle: <Trans>{t('help')}</Trans>,
    }, 
  };
 
  return menuInfo[currentUrl] || {};
};
