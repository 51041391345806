import React from "react";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Change = ({
  newPassword1,
  newPassword2,
  onChangeFieldValue,
  onSubmit,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('changePassword')}</Trans></div>

      <div className="divider"></div>
      <form className="w-half mg-center" onSubmit={onSubmit}>
        <div className="card">
          <div className="card__header"><Trans >{t('changePassword')}</Trans></div>
          <div className="card__main">
            <div className="form-group">
              <div className="input-chaining">
                <input
                  className="form-control"
                  type="password"
                  name="newPassword1"
                  placeholder={t('newPasswordCharacter')}
                  value={newPassword1}
                  onChange={onChangeFieldValue}
                />
                <div className="append">
                  <span className="fas fa-lock dummy-btn bg-w h-full"></span>
                </div>
              </div>
              <p className="validation-message mb-15">{errors.newPassword1}</p>
              <div className="input-chaining">
                <input
                  className="form-control"
                  type="password"
                  name="newPassword2"
                  placeholder={t('confirmNewPassword')}
                  value={newPassword2}
                  onChange={onChangeFieldValue}
                />
                <div className="append">
                  <span className="fas fa-lock dummy-btn bg-w h-full"></span>
                </div>
              </div>
              <p className="validation-message mb-15">{errors.newPassword2}</p>

              <button className="btn">{t('changePassword')}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Change;
