import React, { useState } from "react"; 
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import ReactPaginate from "react-paginate";

const CorpInfo = ({ 
  email, 
  onEmailChange, 
  onSubmit ,
  fetchUserInfo,
  userMngList,
  messageResult,
  selectCheckedIdx,
  handleCheckBox,
  handleActivate,
  handleDeactivate,
  handleChangeUserAuth,
  userTotal,
  postsPerPage,
  handleSelectAuthChange,
  handleChangeAuth,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('enterpriseUseMM')}</Trans></div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="wish-list-control">
          <div>
            <span><Trans >{t('invitationEmail')}</Trans></span>
          </div>
        </div>
        <div className="table-container">
          <form className="ml-20">
            <div className="input-chaining"> 
              <div className="input-chaining">
                <input
                  className={"form-control"}
                  type="text"
                  name="email"  
                  value={email}
                  onChange={onEmailChange}  
                  required
                />
                <div className="append">
                  <button
                    id="search"
                    className="btn btn-s h-full border-0 bg-kk"
                    type="button"
                    onClick={onSubmit}
                  >
                    {t('sendMail')}
                  </button>
                </div>
              </div>
              <p className="validation-message">{messageResult}</p>
            </div>
          </form>
        </div>
      </div>
      <div className="pt-30"></div>
      <div className="table-pagination-container">
        <div className="wish-list-control">
          <div>
            <span><Trans >{t('corporateUsers')}</Trans></span>
          </div>
          <div className="d-inline-flex">
            <button className="btn btn-s" onClick={handleActivate}>{t('activate')}</button>
            <button className="btn btn-s"onClick={handleDeactivate}>{t('deactivate')}</button>
            <button className="btn btn-s" clas onClick={handleChangeAuth}>{t('changeAuth')}</button>
            {/* <button className="btn bg-danger btn-s">삭제</button> */}
          </div>
        </div>
        <div className="table-container ">
          <table className="table table-bordered w-full">
            <thead>
              <tr>
                <th></th>
                <th><Trans >{t('userID')}</Trans></th>
                <th><Trans >{t('mmname')}</Trans></th>
                <th><Trans >{t('phoneNumber')}</Trans></th>
                <th><Trans >{t('situation')}</Trans></th>
                <th><Trans >{t('memberAuth')}</Trans></th>
                <th><Trans >{t('invitationEmail')}</Trans></th>
              </tr>
            </thead>
            <tbody>
            {userMngList && userMngList.length === 0 && (
                  <tr>
                    <td colSpan={7}><Trans >{t('noSearchResults')}</Trans></td>
                  </tr>
                )}
                {userMngList &&
                userMngList.length>0 &&
                userMngList.map((data,i) => (
                  <tr key={`userMngList-tbody-${i}`}>
                    <td>
                      <div className="c-checkbox">
                        <label className="c-checkbox">
                          <input
                            type="radio"
                            name="userIdx"
                            className="wishResult"
                            onChange={(e) => handleCheckBox(e, data.idx, data.authName)}
                          />
                          <span className="fa fa-check"></span>
                        </label>
                      </div>
                    </td>
                    <td>{data.mail}</td>
                    <td>{data.name}</td>
                    <td>{data.phoneNo}</td>
                    <td>{data.active === "Y" ? t('corpManage5') : t('corpManage6')}</td>
                    <td>
                      <select value= {data.authName}
                        name="authName"
                        onChange={(e) => handleSelectAuthChange(e, i)}>
                        <option value="member">{t('corpManage1')}</option>
                        <option value="member_translate">{t('corpManage2')}</option>
                        <option value="member_patent">{t('corpManage3')}</option>
                      </select>
                      </td>
                    <td>{data.inviteEmail? data.inviteEmail : t('corpManage4')}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              fetchUserInfo(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(userTotal / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};
export default CorpInfo;
