import React from "react";

import "./PricePolicy.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const PricePolicy = () => {
  const { t } = useTranslation();
  return (
    <div className={"page-container"}>
      <div className={"page__header"}>
        <div className="page__title">이용 요금 안내</div>
        <small className="page__subtitle">
          수번역 대비 80%저렴한 가격으로 특허번역을 이용해보세요.
        </small>
      </div>
      <div className={"page-contents pt-20"}>
        <div className="price-policy pt-4">
          <div className="text-right">
            <div style={{ height: 332 }}></div>
            <p className="round-text-bold" style={{ fontSize: 23 }}>
            <Trans >{t('krEng')}</Trans>
            </p>
            <p className="round-text-bold mt-30" style={{ fontSize: 23 }}>
            <Trans >{t('engKr')}</Trans>
            </p>
            <p className="round-text-bold mt-30" style={{ fontSize: 23 }}>
            <Trans >{t('krCn')}</Trans>
            </p>
            <p className="round-text-bold mt-30" style={{ fontSize: 23 }}>
            <Trans >{t('cnKr')}</Trans>
            </p>
            <p className="round-text-bold mt-30" style={{ fontSize: 23 }}>
            <Trans >{t('krJp')}</Trans>
            </p>
            <p className="round-text-bold mt-30" style={{ fontSize: 23 }}>
            <Trans >{t('JpKr')}</Trans>
            </p>
          </div>
          <div className="price-plan">
            <div className="card b pd-0">
              <div className="card__main text-align-center price-plan__title">
                <div className="text-bold">BASIC</div>
              </div>
              <div className="card__main pd-0">
                <div className={"property"}>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span><Trans>{t('patentAi')}</Trans></span>
                  </p>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span>휴먼 기본 보정 </span>
                  </p>
                  <p>
                    <em className="fa fa-times fa-fw text-warning mr-2" />
                    <span className="text-disabled"><Trans >{t('expertReview')}</Trans></span>
                  </p>
                  <p>
                    <em className="fa fa-times fa-fw text-warning mr-2" />
                    <span className="text-disabled"><Trans >{t('attorneyReview')}</Trans></span>
                  </p>
                </div>
                <div className="price-responsive">
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOEN_AA">8.2</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="ENKO_AA">14</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/단어</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOZH_AA">10</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="ZHKO_AA">8</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOJA_AA">6</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="JAKO_AA"> 5</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="price-plan">
            <div className="card b pd-0">
              <div className="card__main text-align-center price-plan__title">
                <div className="text-bold">SPECIAL</div>
              </div>
              <div className="card__main pd-0">
                <div className={"property"}>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span><Trans>{t('patentAi')}</Trans></span>
                  </p>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span>휴먼 기본 보정 </span>
                  </p>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span><Trans >{t('expertReview')}</Trans></span>
                  </p>
                  <p>
                    <em className="fa fa-times fa-fw text-warning mr-2" />
                    <span className="text-disabled"><Trans >{t('attorneyReview')}</Trans></span>
                  </p>
                </div>
                <div className="price-responsive">
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOEN_AB">28.2</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="ENKO_AB">56</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/단어</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOZH_AB">35</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="ZHKO_AB">29</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOJA_AB">19</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="JAKO_AB">16</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="price-plan">
            <div className="card b pd-0">
              <div className="card__main text-align-center price-plan__title">
                <div className="text-bold">PREMIUM</div>
              </div>
              <div className="card__main pd-0">
                <div className={"property"}>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span><Trans>{t('patentAi')}</Trans></span>
                  </p>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span>휴먼 기본 보정 </span>
                  </p>
                  <p>
                    <em className="fa fa-times fa-fw text-warning mr-2" />
                    <span className="text-disabled"><Trans >{t('expertReview')}</Trans></span>
                  </p>
                  <p>
                    <em className="fa fa-check fa-fw text-green mr-2" />
                    <span><Trans >{t('attorneyReview')}</Trans></span>
                  </p>
                </div>
                <div className="price-responsive">
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOEN_AC">46</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="ENKO_AC">91</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/단어</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOZH_AC">63</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="ZHKO_AC">49</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="KOJA_AC">34</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                  <p className="">
                    <span className={"unit-price"}>
                      <span id="JAKO_AC">32</span>
                    </span>
                    <span className={"unit-info"}>캐시</span>
                    <span className={"unit-info"}>/문자</span>
                  </p>
                </div>
              </div>
            </div>
            <div className={"text-align-center text-primary mt-20 text-bold"}>
              <span><Trans>{t('preparationService')}</Trans></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricePolicy;
