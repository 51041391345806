import React, { useEffect } from 'react';
import {Link} from "react-router-dom";
import './Home.scss';
import {autoWindowScroll} from "@/util/common";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Home = (props) => {
  useEffect(() => {
    document.body.style.overflowX = 'hidden';
    const lang = localStorage.getItem("lang") || "en";
    const elements = document.querySelectorAll('.msec-03 li .ctxt');
    elements.forEach(el => el.setAttribute('data-lang', lang));
  }, []);

  const { t } = useTranslation();
  const lang = localStorage.getItem("lang") || "en"; 
  return (
    <div id="contentsWrapper" className="home">
      <div className="msec-01 active section" id="section-1">
        <div className="visual">
          <div className="txt">
            <p className="btxt"><Trans >{t('needTranslation')}</Trans> <br/><Trans >{t('quality')}</Trans> </p>
            <p className="stxt"><Trans >{t('patentTranslation')}</Trans></p>
           
            <p className="ctxt">
            {lang === "ko" ? 
             <p className="ctxt"><Trans >{t('forPatentDoc')}</Trans> <span><Trans >{t('forPatentDoc_1')}</Trans></span></p>
             :
             <p className="ctxt"><Trans >{t('forPatentDoc')}</Trans> </p>
            }
             </p>
            {/*<a id="ipkingkongInfo" className="sec-btn" style={{ cursor: 'pointer' }} onClick={autoWindowScroll.bind(this, 'section-2')}><span>IP킹콩은?</span></a>*/}
          </div>
          <div className="img">
            <p className="robot"><img src="/images/main/about-img01.png" alt=""/></p>
            <p className="page"><img src="/images/main/about-img02.png" alt=""/></p>
          </div>
        </div>
        <ul>
          <li className="">
            <Link to={'/search/patentPublication'} style={{ textDecoration: 'none' }}>
              <p className="btxt"><Trans >{t('patentGazelteTranslation')}</Trans></p>
              <p className="stxt"><Trans >{t('needPatentGazelteTranslation')}</Trans><span className="break"><Trans >{t('justPatentNumber')}</Trans></span> <span
                  className="block"><Trans >{t('hasPatentDatabases')}</Trans></span></p>
            </Link>
          </li>
          <li>
            <Link to={'/translation/myDoc/uploader'} style={{ textDecoration: 'none' }}>
              <p className="btxt"><Trans >{t('myDocTranslation')}</Trans></p>
              <p className="stxt"><Trans>{t('needTranslationPatentDocFile')}</Trans> <Trans >{t('ipkkProvides')}</Trans></p>
            </Link>
          </li>
        </ul>
      </div>
      <div className="msec-02 active section" id="section-2">
        <h3><Trans >{t('whatIpkk')}</Trans></h3>
        <p className="stit"><Trans>{t('ipkkAIParadigm')}</Trans>     
             <span className="block"><Trans>{t('improvePatentWork')}</Trans></span>
        </p>
        <ul className="info">
          <li>
            <p className="btxt"><Trans>{t('aiTranslationSpecialzing')}</Trans> </p>
            <p className="stxt"><Trans>{t('smarterPatentTranslation')}</Trans> <span><Trans>{t('smarterPatentTranslation_1')}</Trans></span></p>
           
          </li>
          <li>
            <p className="btxt"><Trans>{t('humanTranslationReview')}</Trans></p>
            <p className="stxt"><Trans>{t('humanReview')}</Trans></p>
          </li>
        </ul>
        <ul className="guide">
          <li>
            <p className="btxt"><Trans>{t('justHour')}</Trans><span><Trans>{t('fingertips')}</Trans></span></p>
            <p className="stxt"><Trans>{t('translationResult')}</Trans> <Trans>{t('highQualityLevel')}</Trans></p>
          </li>
          <li>
            <p className="btxt"><Trans>{t('highQualityLevel_1')}</Trans> <span><Trans>{t('highQualityLevel_2')}</Trans></span></p>
            <p className="stxt"><Trans>{t('neuralSystem')}</Trans></p>
          </li>
          <li>
            <p className="btxt"><Trans >{t('compareCost')}</Trans></p>
            <p className="stxt"><Trans >{t('specificArtificial')}</Trans> <Trans >{t('nmtTranslation')}</Trans></p>
          </li>
          <li>
            <p className="btxt"> <Trans >{t('easyAndSimple')}</Trans> <span><Trans >{t('translationProcess')}</Trans></span></p>
            <p className="stxt"><Trans >{t('easilyTranslation')}</Trans></p>
          </li>
        </ul>
      </div>
      <div className="msec-03 active section" id="section-3">
        <h3><Trans >{t('ipkkSpecial')}</Trans></h3>
        <ul>
          <li>
            <p className="btxt"><Trans >{t('cheaper')}</Trans></p>
            <p className="stxt"><Trans >{t('translationPrice')}</Trans><span className="block"><Trans >{t('wordPrice')}</Trans></span><span className="ex"><Trans >{t('omissionCheck')}</Trans></span>
            </p>
            <p className="ctxt"><Trans >{t('humanTranslationCost')}</Trans><span className="block"><Trans >{t('wordPrice_75')}</Trans></span></p>
          </li>
          <li>
            <p className="btxt"><Trans >{t('faster')}</Trans></p>
            <p className="stxt"><Trans >{t('deliveredTime')}</Trans> <span className="block"><Trans >{t('deliveredTime_1')}</Trans></span></p>
            <p className="ctxt"><Trans >{t('translationTime_1')}</Trans> <span className="block"><Trans >{t('translationTime_2')}</Trans></span></p>
          </li>
          <li>
            <p className="btxt"><Trans >{t('accurate')}</Trans></p>
            <p className="stxt"><Trans >{t('maintainingQuality')}</Trans><span className="block"><Trans >{t('maintainingQuality_1')}</Trans></span></p>
            <p className="ctxt"><Trans >{t('translationQuality')}</Trans> <span className="block"><Trans >{t('translationQuality_1')}</Trans></span></p>
          </li>
          <li>
            <p className="btxt"><Trans >{t('moreConvenient')}</Trans></p>
            <p className="stxt"><Trans >{t('applyForTranslation')}</Trans><span className="block"><Trans >{t('applyForTranslation_1')}</Trans></span></p>
            <p className="ctxt">
            <Trans >{t('provideGazette')}</Trans>
              <span className="block"><Trans >{t('complicatedQuotation')}</Trans></span><Trans >{t('seperateFees')}</Trans>
            </p>
          </li>
        </ul>
        <p className="rtxt"><Trans >{t('requestEngGazette')}</Trans></p>
      </div>
      <div className="msec-04 active section" id="section-4">
        <h3><Trans >{t('howToIpkk')}</Trans></h3>
        <ul className="use-step">
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt"><Trans >{t('searchType')}</Trans></p>
              <p className="stxt"><Trans >{t('searchTypeGuide')}</Trans></p>
              <p className="img"><img src="/images/main/use-step01.jpg" alt=""/></p>
            </div>
          </li>
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt"><Trans >{t('selectTranslationQuality')}</Trans></p>
              <p className="stxt"><Trans >{t('chooseTranslationQuality')}</Trans></p>
              <dl className="c-01">
                <dt><p><span>BAsic</span></p></dt>
                <dd><Trans >{t('aiTranslation')}</Trans></dd>
                <dd><Trans >{t('basicHumanProofreading')}</Trans></dd>
              </dl>
              <dl className="c-02">
                <dt><p><span>SPECIAL</span></p></dt>
                <dd><Trans >{t('basicContent')}</Trans></dd>
                <dd><span><strong><Trans >{t('expertReview')}</Trans></strong></span></dd>
              </dl>
              <dl className="c-03">
                <dt><p><span>PREMIUM</span></p></dt>
                <dd><Trans >{t('basicContent')}</Trans></dd>
                <dd><span><strong><Trans >{t('attorneyReview')}</Trans></strong></span></dd>
              </dl>
            </div>
          </li>
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt"><Trans >{t('estimatedCost')}</Trans></p>
              <p className="stxt"><Trans >{t('requestTranslation')}</Trans></p>
              <p className="img"><img src="/images/main/use-step03.jpg" alt=""/></p>
            </div>
          </li>
          <li style={{ height: 400 }}>
            <div>
              <p className="btxt"><Trans >{t('checkTranslation')}</Trans></p>
              <p className="stxt"><Trans >{t('checkEmail')}</Trans></p>
              <p className="img"><img src={'/images/main/use-step04' + t('IMG_LANG') + '.jpg'} alt="<Trans >{t('translationComplete')}</Trans>"/></p>
            </div>
          </li>
        </ul>
      </div>
      <div className="msec-05 active section" id="section-5">
        <h3><Trans >{t('createdIpkk')}</Trans></h3>
        <ul>
          <li>
            <p className="logo"><img src="/images/main/made-logo01.png" alt=""/></p>
            <p className="btxt"><Trans >{t('historySystran')}</Trans></p>
            <p className="stxt">
            <Trans >{t('introSystran')}</Trans>
            </p>
            <a href="http://www.systransoft.com/" target="_blank" className="more"><span><Trans >{t('exploreSystran')}</Trans></span></a>
          </li>
          <li>
            <p className="logo"><img src="/images/main/made-logo02.png" alt=""/></p>
            <p className="btxt"><Trans >{t('introKeywert')}</Trans></p>
            <p className="stxt"><Trans >{t('introWert')}</Trans>
            </p>
            <a href="https://www.keywert.com" target="_blank" className="more"><span><Trans >{t('goToKeywert')}</Trans></span></a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
