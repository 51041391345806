import React, { Component } from "react";
import ChangePassword from "@/serviceModule/ChangePassword/ChangePassword";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { httpRequest } from "@/util/common";
import { connect } from "react-redux";
import { withTranslation  } from 'react-i18next'; 
import { logout} from "@/commonSlices/authSlice";

class ChangePasswordContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordInfo: {
        currentPassword: "",
        newPassword1: "",
        newPassword2: "",
      },
      errors: { currentPassword: "", newPassword1: "", newPassword2: "" },
    };
  }

  render() {
    const { passwordInfo, errors } = this.state;

    return (
      <ChangePassword
        passwordInfo={passwordInfo}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onSubmit={this.handleSubmit}
      />
    );
  }

  /**
   * 인풋 변경하기
   * @param e
   */
  handleChangeFieldValue = (e) => {
    this.setState({
      ...this.state,
      passwordInfo: {
        ...this.state.passwordInfo,
        [e.target.name]: e.target.value,
      },
    });
  };

  /**
   * 비밀번호 변경 전 유효성 검사하기
   * @return {boolean}
   */
  validate = () => {
    let validated = true;
    const { t } = this.props; 
    const { passwordInfo } = this.state;
    const errors = {};
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/i;

    if (!passwordInfo.currentPassword) {
      errors.currentPassword = t('enterPassword');
      validated = false;
    }
    if (!passwordRegex.test(passwordInfo.newPassword1)) {
      errors.newPassword1 =
      t('passWord8to32');
      validated = false;
    }
    if (!passwordInfo.newPassword1) {
      errors.newPassword1 = t('enterPassword');;
      validated = false;
    }
    if (passwordInfo.newPassword1 !== passwordInfo.newPassword2) {
      errors.newPassword2 = t('passWordnotMatch');
      validated = false;
    }
    if (!passwordInfo.newPassword2) {
      errors.newPassword2 =  t('passwordReConfirm');
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 비밀번호 변경 요청하기
   * @param e
   */
  handleSubmit = async (e) => {
    const { t } = this.props; 
    e.preventDefault();
    if (this.validate()) {
      const {
        auth: {
          user: { mail, idx },
        },
        logout,
      } = this.props;
      const {
        passwordInfo: { currentPassword, newPassword2 },
      } = this.state;
      try {
        /* 현재 비밀번호 일치여부 확인 */
        const {
          data: { status },
        } = await httpRequest.post("auth/check", {
          key: "password",
          value: {
            mail,
            password: currentPassword,
          },
        });
        /* 현재 비밀번호 일치하지 않는 경우 */
       // if (!status) throw new Error("비밀번호를 다시 확인해주세요.");
        if (!status) throw new Error("Please check your password again.");
        /* 현재 비밀번호 일치하는 경우 */
        httpRequest
          .put(`user/${idx}`, { password: newPassword2 })
          .then(() => {
            toast.success(t('changesuccess'));
            this.setState({
              passwordInfo: {
                currentPassword: "",
                newPassword1: "",
                newPassword2: "",
              },
              errors: {
                currentPassword: "",
                newPassword1: "",
                newPassword2: "",
              },
            });
          })
          .catch(() => toast.error(t('passwordchangefail')));
          console.log("여기들어온거임?")
          setTimeout(async () => {
            logout();
          },3000)
      } catch (err) {
        this.setState({
          ...this.state,
          errors: { ...this.state.errors, currentPassword: err.message },
        });
      }
    }
  };
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()), 
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangePasswordContainer));
