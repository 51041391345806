import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import Quit from "./Quit";
import { clientLogout } from "@/commonSlices/authSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation  } from 'react-i18next'; 

class QuitContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      withdrawReason: [],
      quitInfo: { quitReason: "", quitPassword: "" },
      errors: { quitReason: "", quitPassword: "" },
    };
  }

  componentDidMount() {
    this.fetchWithdrawReason();
  }

  render() {
    const { withdrawReason, quitInfo, errors } = this.state;
    const {
      auth: {
        user: { name, point },
      },
    } = this.props;

    return (
      <Quit
        withdrawReason={withdrawReason}
        quitInfo={quitInfo}
        name={name}
        point={point}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onSubmit={this.handleSubmit}
      />
    );
  }

  /**
   * 탈퇴사유 서버에서 받아오기
   */
  fetchWithdrawReason = async () => {
    const { history } = this.props;
    try {
      const { data } = await httpRequest.get("code/withdrawReason");
      this.setState({ ...this.state, withdrawReason: data });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 인풋 변경하기
   * @param e
   */

  handleChangeFieldValue = (e) => {
    this.setState({
      ...this.state,
      quitInfo: { ...this.state.quitInfo, [e.target.name]: e.target.value },
    });
  };

  /**
   * 유효성 검증하기
   * @return {boolean}
   */
  validate = () => {
    const { t } = this.props; 
    const { quitReason, quitPassword } = this.state.quitInfo;
    let validated = true;
    const errors = {};
    if (!quitReason) {
      errors.quitReason = (t('reasonWithdrawl'));
      validated = false;
    }
    if (!quitPassword) {
      errors.quitPassword = (t('enterPassword'));
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 탈퇴 요청하기
   * @param e
   */
  handleSubmit = async (e) => {
    const { t } = this.props; 
    e.preventDefault();
    if (this.validate()) {
      const {
        auth: {
          user: { mail, idx },
        },
        clientLogout,
        history,
      } = this.props;
      const {
        quitInfo: { quitReason, quitPassword },
      } = this.state;
      
      try {
        /* 현재 비밀번호 일치여부 확인 */
        const {
          data: { status },
        } = await httpRequest.post("auth/check", {
          key: "password",
          value: {
            mail,
            password: quitPassword,
          },
        });
        /* 현재 비밀번호 일치하지 않는 경우 */
        //if (!status) throw new Error("비밀번호가 일치하지 않습니다.");
        if (!status) throw new Error("Password does not match.");
        /* 현재 비밀번호 일치하는 경우 탈퇴요청*/

        httpRequest
          .delete(`user/${idx}`, { data: { code: quitReason } })
          .then(() => {
            clientLogout();
            history.push("/quit/success");
          })
          .catch((err) => {
            toast.error(t('failedcancleMem'));
          });
      } catch (err) {
        this.setState({
          ...this.state,
          errors: {
            ...this.state.errors,
            quitPassword: err.message,
          },
        });
      }
    }
  };
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { clientLogout })(withTranslation()(QuitContainer));
