import { commafy } from "@/util/common";
import React from "react";
import "./Quit.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Quit = ({
  withdrawReason,
  quitInfo,
  name,
  point,
  errors,
  onChangeFieldValue,
  onSubmit,
}) => {
  const { t } = useTranslation();
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="card w-full quit">
        <div className="card__header"><Trans>{t('withdrawalMember')}</Trans></div>
        <div className="card__notification">
          <div>
          {t('QuitCurrent')} {`${name}`}{t('QuitHold')}
            {`${commafy(point)}`} {t('QuitPoint')}
          </div>
          <div><Trans>{t('QuitwithdrawMemvership')}</Trans></div>
        </div>
        <div className="card__main">
          <fieldset className="pt-10 pb-10 pl-20 pr-20">
            {" "}
            <div className="form-group radio">
              <label className="mb-5">{t('reasonForWithdrawal')}</label>
              <div className="h-full d-flex flex-direction-column align-items-flex-start line-height-6">
                {withdrawReason.map((v, i) => (
                  <label key={`withdrawReason-${i}`} className="c-radio mg-0">
                    <input
                      type="radio"
                      name="quitReason"
                      value={v.code}
                      onChange={onChangeFieldValue}
                    />
                    <span className="fa fa-circle" />
                    {t(v.codeName)}
                  </label>
                ))}
              </div>
            </div>
            <p className="validation-message">{errors.quitReason}</p>
          </fieldset>
          <fieldset className="pt-10 pb-10 pl-20 pr-20">
            {" "}
            <div className="form-group mb-15">
              <label>{t('confirmPassword')}</label>
              <div className="input-chaining">
                <input
                  className="form-control"
                  type="password"
                  name="quitPassword"
                  defaultValue={quitInfo.quitPassword}
                  onChange={onChangeFieldValue}
                />
              </div>
            </div>
            <p className="validation-message">{errors.quitPassword}</p>
          </fieldset>
        </div>

        <div className="card__footer align-items-end">
          <button className="btn btn-s bg-danger mb-10 mr-20">{t('withdrawal')}</button>
        </div>
      </div>
    </form>
  );
};

export default Quit;
