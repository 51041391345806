import React from "react";
import "./ChargeInfo.scss";
import { commafy, checkPlan } from "@/util/common";
//import PayPalButtonWrapper from "@/commonModule/Paypal/PaypalButtonWrapper";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const ChargeInfo = ({ chargeInfo = {}, onSubmit, onUnsubscribe, onChangePlan ,amount}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('claimStatus')}</Trans></div>
      <div className="divider"></div>
      <div className="sub-menu">
        <div className="sub-menu__right-post">
          <div className="card w-full">
            <div className="card__header pd-0"><Trans >{t('billingInformation')}</Trans></div>
            <div className="card__main">
              <div className="form-group mb-5">
                <label><Trans >{t('billingDate')}</Trans></label>
                <div className="input-chaining">
                  <input className="form-control disabled" type="text" name="paymentDate" placeholder={t('enterEmail')} disabled="" value={chargeInfo.paymentDate} />
                </div>
              </div>
              <div className="form-group mb-15">
                <label><Trans >{t('chargedAmount')}</Trans></label>
                <div className="input-chaining">
                  <input className="form-control disabled" type="text" name="price" maxlength="12" value={chargeInfo.price.replace("USD", "")}/>
                </div>
              </div>
              <div className="form-group mb-15">
                <label><Trans >{t('paymentExpecDate')}</Trans></label>
                <div className="input-chaining">
                  <input className="form-control disabled" type="text" name="paymentDate" maxlength="12" value={chargeInfo.paymentDate} />
                </div>
              </div>
              <div className="form-group mb-15">
                <label><Trans >{t('paymentStatus')}</Trans></label>
                <div className="input-chaining">
                  <input className="form-control disabled" type="text" name="status" maxlength="12" 
                  value={
                    chargeInfo.status === "active"
                      ? t('paymentcompleted')
                      :  t('paymentNOTcompleted')
                  } />
                </div>
              </div>
            </div>
          </div>
          <div className="card w-full">
            <div className="card__header pd-0"><Trans >{t('planInformation')}</Trans></div>
            <div className="card__main">
              <div className="form-group mb-10">
                <label><Trans >{t('ratePaln')}</Trans></label>
                <div className="input-chaining">
                  <input className="form-control disabled" type="text" name="description" disabled="" value={t(chargeInfo.description)} />
                </div>
              </div>
              <div className="form-group mb-10">
                <label><Trans >{t('subscriptionPeriod')}</Trans></label>
                <div className="input-chaining">
                  <input className="form-control disabled" type="text" name="planPeriod" disabled="" value={(chargeInfo.planPeriod)} />
                </div>
              </div>
              <div className="card__footer align-items-end">
                <div className="d-inline-flex" >
                  <button className="btn bg-danger btn-s mb-5" onClick={onUnsubscribe}>{t('unsubscribe')}</button>
                  <button className="btn btn-s mb-5" onClick={onChangePlan}>{t('changeplan')}</button>
                </div>
              </div>
            </div>
            {/* <PayPalButtonWrapper
            amount={chargeInfo.price} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargeInfo;
