import React from "react";


const OrderHistory = ({}) => {
  return (<form className="w-full">
        <div className="card w-full">
          <div className="card__header pd-0">구독 정보</div>
          <div className="card__main">
            <div className="form-group mb-5">
              <table>
                <colgroup>
                  <col width="150px"/>
                  <col/>
                  <col width="150px"/>
                  <col/>
                </colgroup>
                <tr>
                  <th scope="row">구독플랜</th>
                  <td>Basic</td>
                  <th scope="row">사용 가능 기간</th>
                  <td>2024.01.01 ~ 2024.01.31</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="card w-full">
          <div className="card__header pd-0">결제현황</div>
          <div className="card__main">
            <div className="form-group mb-5">
              <table>
                <colgroup>
                  <col width="25%"/>
                  <col width="25%"/>
                  <col width="25%"/>
                  <col width="25%"/>
                </colgroup>
                <tr>
                  <th scope="row">총 결제금액</th>
                  <th scope="row">기본결제액(요금제 사용량)</th>
                  <th scope="row">캐시 사용</th>
                  <th scope="row">카드결제</th>
                </tr>
                <tr>
                  <td>12000</td>
                  <td>10000</td>
                  <td>2000</td>
                  <td>2000</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div className="card w-full">
          <div className="card__header pd-0">결제내역</div>
          <div className="card__main">
            <div className="form-group mb-5">
              <table>
                <colgroup>
                  <col width="15%"/>
                  <col width="10%"/>
                  <col width="15%"/>
                  <col width="20%"/>
                  <col width="20%"/>
                  <col width="20%"/>
                </colgroup>
                <tr>
                  <th scope="row">사용일시</th>
                  <th scope="row">사용자</th>
                  <th scope="row">결제금액</th>
                  <th scope="row">기본 결제액</th>
                  <th scope="row">추가결제 구분</th>
                  <th scope="row">추가결제액</th>
                </tr>
                <tr>
                  <td>2024.09.30</td>
                  <td>User1</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>캐시</td>
                  <td>1000</td>
                </tr>
                <tr>
                  <td>2024.08.30</td>
                  <td>User2</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>캐시</td>
                  <td>1000</td>
                </tr>
                <tr>
                  <td>2024.07.30</td>
                  <td>User3</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>캐시</td>
                  <td>1000</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </form>

  );
};

export default OrderHistory;
