import React, { Fragment } from "react";
import "./SubMenuLayout.scss";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'; // i18next 사용
import { SUB_MENU_INFO } from "@/layout/SubMenuLayout/SubMenuInfo";
import NavbarContainer from "@/commonModule/Navbar/NavbarContainer";
import FooterContainer from "@/commonModule/Footer/FooterContainer";

const SubMenuLayout = (props) => { 
  const { t } = useTranslation(); // useTranslation 훅 호출
  let currentUrl = props.children.props.match.url;
  if (props.children.props.match.url.indexOf("/board/news/")  == 0){
    currentUrl = "/board/news";
  }

  const { menuList, menuTitle, title, desc } = SUB_MENU_INFO(currentUrl); // SUB_MENU_INFO 호출
  
  return (
    <Fragment>
      <NavbarContainer {...props} />
      <div className="layout">
        <div className="page-container">
          <div className="page__title">{title}</div>
          <small className="page__subtitle">{desc}</small>
          <div className="divider" />
          <div className="sub-menu">
            <div className="sub-menu__left-post">
              <ul className="navigation">
                {menuTitle && <span>{menuTitle}</span>}
                {menuList &&
                  menuList.map((menuInfo, idx) => {
                    return (
                      <Link to={menuInfo.url} key={`sub-menu-${idx}`}>
                        <li
                          className={`${
                            currentUrl.match(menuInfo.url) ? "active" : ""
                          }`}
                        >
                          {menuInfo.name}
                        </li>
                      </Link>
                    );
                  })}
              </ul>
            </div>
            <div className="sub-menu__right-post">{props.children}</div>
          </div>
        </div>
      </div>
      <FooterContainer {...props} />
    </Fragment>
  );
};

export default SubMenuLayout;
