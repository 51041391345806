import React, { Component } from "react";
import Send from "./Send";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { httpRequest } from "@/util/common";

class SendContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
  }

  render() {
    const { email } = this.state;
    return (
      <Send
        email={email}
        onChangeFieldValue={this.handleChangeFieldValue}
        onSubmit={this.handleSubmit}
      />
    );
  }

  /**
   * 인풋변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ email: e.target.value });
  };

  /**
   * 이메일 재발송 요청 전송하기
   * @param {*} e
   */
  handleSubmit = async (e) => {
    const lang = localStorage.getItem("lang") || "en"; 
    const messages = {
      en: {
        emailNotRegistered: "This email address is not registered.",
        urlSentSuccess: "The URL has been successfully sent.",
        urlSentFailure: "Failed to send the URL."
      },
      ko: {
        emailNotRegistered: "가입되지 않은 이메일 주소입니다.",
        urlSentSuccess: "URL 전송에 성공하였습니다.",
        urlSentFailure: "URL 전송에 실패하였습니다."
      }
    };

    e.preventDefault();
    const { email } = this.state;
    try {
      const {
        data: { exist },
      } = await httpRequest.post("access/check", {
        key: "duplicate",
        value: email,
      });
      /* 이메일 가입여부 확인 */
      if (!exist) {
        toast.error(messages[lang].emailNotRegistered);
        return;
      }
      await httpRequest.post("access/recover", { mail: email });
      toast.success(messages[lang].urlSentSuccess); 
      this.setState({ email: "" });
    } catch (err) {
      toast.error(messages[lang].urlSentFailure); 
    }
  };
}

export default SendContainer;
