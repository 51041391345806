import React from "react";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const CNGuide = () => {
  const { t } = useTranslation();
  return (
    <div className="card pd-0">
      <div className="card__main pd-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"border-top-0"} scope="col" colSpan="1">
                <Trans >{t('numberType')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('numberFormat')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('example')}</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="4"><Trans >{t('applicationNumber')}</Trans></td>
              <td><Trans >{t('y4ss8')}</Trans></td>
              <td>2014-10666725</td>
            </tr>
            <tr>
              <td><Trans >{t('y4ps8')}</Trans></td>
              <td>201410666725</td>
            </tr>
            <tr>
              <td><Trans >{t('cnpy4ps8')}</Trans></td>
              <td>CN201410666725</td>
            </tr>
            <tr>
              <td><Trans >{t('y4ps8pid')}</Trans></td>
              <td>201410666725.4</td>
            </tr>
            <tr>
              <td rowSpan="4"><Trans >{t('prNumber')}</Trans></td>
              <td><Trans >{t('s7')}</Trans></td>
              <td>2472715</td>
            </tr>
            <tr>
              <td><Trans >{t('s9')}</Trans></td>
              <td>101969455</td>
            </tr>
            <tr>
              <td><Trans >{t('cnps9')}</Trans></td>
              <td>CN101969455</td>
            </tr>
            <tr>
              <td><Trans >{t('cnps7')}</Trans></td>
              <td>CN2472715</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CNGuide;
