// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from "./us-en.json";
import translationKO from "./kr-ko.json";

// 번역 리소스
const resources = {
    en: {
        translation: translationEN
    },
    ko: {
        translation: translationKO
    }
};

i18n.use(initReactI18next).init({
    resources,
    lng: localStorage.getItem('lang') == null ? 'en' : localStorage.getItem('lang'), // 기본 언어 설정
    fallbackLng: 'ko', // 선택된 언어가 없을 때 사용할 언어
    interpolation: {
        escapeValue: false, // React에서는 XSS 방지가 기본적으로 되어 있으므로 비활성화
    },
    react: {
        // ...
        hashTransKey: function (defaultValue) {
            // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
        },
        defaultTransParent: 'div', // a valid react element - required before react 16
        transEmptyNodeValue: '', // what to return for empty Trans
        transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'span'], // don't convert to <1></1> if simple react elements
    }
});
export default i18n;