import React, { Component } from "react";
import UserInfo from "@/serviceModule/UserInfo/UserInfo";
import { httpRequest } from "@/util/common";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clientLogout } from "@/commonSlices/authSlice";
import { withTranslation  } from 'react-i18next'; 

class UserInfoContainer extends Component {
  
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {
        email: "",
        memberType: "" /* 1번: 개인, 2번: 기업 */,
        name: "",
        hphone: "",
        adminYn:"",
        company:"",
      },
      errors: { hphone: "" },
    };
  }

  componentDidMount() {
    this.fetchUserInfo();
  }

  render() {
    const { userInfo, errors } = this.state;
    return (
      <UserInfo
        userInfo={userInfo}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onSubmit={this.handleSubmit}
      />
    );
  }

  /**
   * 사용자정보 받아오기(이메일, 회원타입, 이름, 휴대전화번호)
   */
  fetchUserInfo = async () => {
    const {
      auth: {
        user: { idx },
      },
      history,
    } = this.props;
    try {
      const {
        data: { mail, member_type, name, hphone, admin_yn, company},
      } = await httpRequest.get(`user/${idx}`);
      this.setState({
        ...this.state,
        userInfo: { email: mail, memberType: member_type, name, hphone, adminYn: admin_yn, company},
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 인풋 변경하기
   * @param fieldName
   * @param value
   */
  handleChangeFieldValue = (e) => {
    this.setState({
      ...this.state,
      userInfo: { ...this.state.userInfo, [e.target.name]: e.target.value },
    });
  };

  /**
   * 유효성 검사
   * @return {boolean}
   */
  validate = () => {
    const { hphone } = this.state.userInfo;
    const { t } = this.props; 
    let validated = true;
    const errors = {};
    const hphoneRegex =
      /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
    if (!hphone || !hphoneRegex.test(hphone)) {
      errors.hphone = t('EnterMobilePhoneNumber');
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 회원정보(전화번호) 수정요청하기
   * @param e
   */
  handleSubmit = async (e) => {
    const { t } = this.props; 
    e.preventDefault();
    if (this.validate()) {
      const {
        auth: {
          user: { idx },
        },
      } = this.props;
      const { hphone } = this.state.userInfo;
      try {
        await httpRequest.put(`user/${idx}`, { hphone });
        toast.success(t('correctsProperly'));
      } catch (err) {
        toast.error(t('editFail'));
      }
    }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(withTranslation()(UserInfoContainer));