import React from "react";
import ReactPaginate from "react-paginate";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import { commafy, checkPlan } from "@/util/common";

const UsageHistory = ({
  memberInfo,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  currentUsage,
  currentSub,
  currentPlan,
  currentHist,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('usageManagement')}</Trans> </div>
      <small className="page__subtitle"><Trans >{t('checkNumberOfwords')}</Trans> </small>
      <div className="divider"></div>
        <div className="table-pagination-container">
          <div className="wish-list-control">
            <div>
              <span><Trans >{t('planInformation')}</Trans></span>
            </div>
          </div>
          <div className="table-container ">
            <table>
              <thead>
                <tr>
                  <td width="25%"><Trans >{t('ratePaln')}</Trans></td>
                  <th width="25%">{checkPlan(currentPlan == null? "": currentPlan.planType)}</th>
                  <td width="25%"><Trans >{t('preiodOfUse')}</Trans></td>
                  <th width="25%">{currentPlan == null? "": currentPlan.startDttm} ~ {currentPlan == null? "": currentPlan.endDttm}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
        <div className="pt-30"></div>
        <div className="table-pagination-container">
          <div className="wish-list-control">
            <div>
              <span><Trans >{t('usageStatus')}</Trans></span>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>  
                  <th><Trans >{t('planData')}</Trans></th>
                  <th><Trans >{t('usageData')}</Trans></th>
                  <th><Trans >{t('residualData')}</Trans></th>
                  <th><Trans >{t('userYourCache1')}</Trans></th>
                  <th><Trans >{t('singleDirectPayment')}</Trans></th> 
                </tr>
              </thead>
              <tbody>
                <tr>
                {currentSub ? (
                    <>
                      <td>{commafy(currentSub?.capacity)}</td>
                      <td>{commafy((Number(currentSub?.capacity) || 0) - (Number(currentSub?.usages) || 0))}</td>
                      <td>{commafy(currentSub?.usages)}</td>
                      <td>{commafy(currentSub?.usePointAmount)}</td>
                      <td>{commafy(currentSub?.usePayAmount)}</td>
                    </>
                  ) : (
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      {t('noSearchResults')}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="pt-30"></div>
      {memberInfo && memberInfo.adminYn === "Y" && memberInfo.memberType === 2 && (
        <div className="table-pagination-container">
          <div className="wish-list-control">
            <div>
              <span><Trans >{t('statusByUser')}</Trans></span>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead> 
                <tr> 
                  <th><Trans >{t('companyName')}</Trans></th>
                  <th><Trans >{t('division')}</Trans></th>
                  <th><Trans >{t('userName')}</Trans></th>
                  <th><Trans >{t('userID')}</Trans></th>
                  <th><Trans >{t('usageData')}</Trans></th>
                  <th><Trans >{t('userYourCache1')}</Trans></th>
                  <th><Trans >{t('singleDirectPayment')}</Trans></th> 
                </tr>
              </thead>
              <tbody>
                {currentHist && currentHist.length === 0 && (
                  <tr>
                    <td colSpan={8}><Trans >{t('noSearchResults')}</Trans></td>
                  </tr>
                )}
                {currentHist &&
                  currentHist.length > 0 &&
                  currentHist.map((hist, i) => (
                  <tr key={`tbody-${i}`}>
                    <td>{hist.company}</td>
                    <td>{t(hist.userType)}</td>  
                    <td>{hist.name}</td>
                    <td>{hist.mail}</td>
                    <td>{commafy(hist.usePlanAmount)}</td>
                    <td>{commafy(hist.usePointAmount)}</td>
                    <td>{commafy(hist.usePayAmount)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
        <div className="pt-30"></div>
        <div className="table-pagination-container">
        <div className="wish-list-control">
            <div>
              <span><Trans >{t('useHistory')}</Trans></span>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th><Trans >{t('requestDateTime')}</Trans></th>
                  <th><Trans >{t('plans')}</Trans></th>
                  <th><Trans >{t('division')}</Trans></th>
                  <th><Trans >{t('userName')}</Trans></th>
                  <th><Trans >{t('userID')}</Trans></th>
                  <th><Trans >{t('type')}</Trans></th>
                  <th><Trans >{t('numWordsTrans')}</Trans></th>
                  <th><Trans >{t('usageData')}</Trans></th>
                  <th><Trans >{t('userYourCache1')}</Trans></th>
                  <th><Trans >{t('singlePaymentAmount1')}</Trans></th>  
                </tr>
              </thead>
              <tbody>
                {currentUsage && currentUsage.length === 0 && (
                  <tr>
                    <td colSpan={10}><Trans >{t('noSearchResults')}</Trans></td>
                  </tr>
                )}
                {currentUsage &&
                  currentUsage.length > 0 &&
                  currentUsage.map((usage, i) => (
                  <tr key={`tbody-${i}`}>
                    <td>{usage.regDttm}</td> {/* 요청일시 */}
                    <td>{usage.planName}</td> {/* 플랜구분 */}
                    <td>{t(usage.userType)}</td> {/* 구분 */}
                    <td>{usage.name}</td> {/* 이용자명 */}
                    <td>{usage.mail}</td> {/* 이용자ID */}
                    <td>{t(usage.memo)}</td> {/* 종류 */}
                    <td>{commafy(usage.charCount)}</td> {/* 번역 대상 단어수 */}
                    <td>{commafy(usage.usePlanAmount)}</td> {/* 사용 데이터 */}
                    <td>{commafy(usage.usePointAmount)}</td> {/* 보유캐시 사용(캐시) */}
                    <td>{commafy(usage.usePayAmount)}</td> {/*  단건 결제금액(원) */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>  
  );
};
export default UsageHistory;
