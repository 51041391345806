import React from "react";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Privacy from "@/commonModule/Privacy/Privacy";
import Policy from "@/commonModule/Policy/Policy";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import { useEffect } from "react";

const Join = ({
  joinInfo,
  onChangeFieldValue,
  onDupCheck,
  onSubmit,
  onRadio,
  onCheckbox,
  onCheckboxAll,
  errors,
  showPrivacy,
  showPolicy,
  togglePrivacy,
  togglePolicy,
  onRecaptcha,
  showPassword,
  showConfirmPassword,
  togglePasswordVisibility,
  handleCompanyDupCheck,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    const lang = localStorage.getItem("lang") || "en";
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?hl=${lang}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="page-container">
      <div className={"page__header"}>
        <div className="page__title"><Trans >{t('freeSignUp')}</Trans> </div>
        <small className="page__subtitle">
        <Trans >{t('joinIpkkFree')}</Trans>
        </small>
      </div>
      <form className="d-flex" onSubmit={onSubmit}>
        <div className="card w-half">
          <div className="card__header"><Trans >{t('enterMemberInfo')}</Trans></div>
          <div className="card__main">
            <fieldset className="mb-20">
              <div className="form-group">
                <label><Trans >{t('email')}</Trans></label>
                <div className="input-chaining">
                  <input type="hidden"
                  name="inviteEmail"
                  value ={joinInfo.inviteEmail}
                  />
                  <input
                    className={"form-control"}
                    type="text"
                    name="email"
                    placeholder={t('enterEmail')}
                    value={joinInfo.email}
                    onChange={onChangeFieldValue}
                    required
                  />
                  <div className="append" onClick={onDupCheck}>
                    <button
                      id="search"
                      className={"btn btn-s h-full border-0 bg-w"}
                      type="button"
                    >
                      <Trans >{t('duplicateCheck')}</Trans>
                    </button>
                  </div>
                </div>
                <p className="validation-message">{errors.email}</p>
                {joinInfo.dupCheck && (
                  <p className="validation-message--success">Good!</p>
                )}
              </div>
              <div className="form-group">
                <label>
                  <Trans>{t('password')} </Trans>
                  <small><Trans>{t('combinationCharacter')} </Trans></small>
                </label>
                <div className="input-chaining">
                  <input
                    className={"form-control"}
                    type={showPassword ? "text" : "password"} // 상태에 따라 타입 변경
                    name="password"
                    placeholder={t('enterPassword')}
                    value={joinInfo.password}
                    onChange={onChangeFieldValue}
                  />
                  <div className="append" onClick={() => togglePasswordVisibility('showPassword')} style={{ cursor: "pointer" }} title="[비밀번호 보기]">
                    <span className="fas fa-lock dummy-btn bg-w h-full" />
                  </div>
                </div>
                <p className="validation-message">{errors.password}</p>
              </div>

              <div className="form-group">
                <label><Trans>{t('passwordConfirm')} </Trans></label>
                <div className="input-chaining">
                  <input
                    className={"form-control"}
                    type={showConfirmPassword ? "text" : "password"} // 상태에 따라 타입 변경
                    name="retypePassword"
                    placeholder={t('passwordReConfirm')}
                    value={joinInfo.retypePassword}
                    onChange={onChangeFieldValue}
                  />
                  <div className="append" onClick={() => togglePasswordVisibility('showConfirmPassword')} style={{ cursor: "pointer" }} title="[비밀번호 보기]">
                    <span className="fas fa-lock dummy-btn bg-w h-full" />
                  </div>
                </div>
                <p className="validation-message">{errors.retypePassword}</p>
              </div>
            </fieldset>
            <fieldset>
              <div className="form-group radio">
                <label><Trans >{t('type')} </Trans></label>

                <div className="mt-5 mb-10 ">
                  <label className="c-radio">
                    <input
                      type="radio"
                      name="memberType"
                      value="2"
                      checked={joinInfo.memberType === `2`}
                      onChange={onRadio}
                      disabled={joinInfo.inviteEmailYn === true} 
                    />{" "}
                    <span className="fa fa-circle" />
                    {t('company')}
                  </label>
                  <label className="c-radio ml-5">
                    <input
                      type="radio"
                      name="memberType"
                      value="1"
                      checked={joinInfo.memberType === `1`}
                      onChange={onRadio}
                      disabled={joinInfo.inviteEmailYn === true} 
                    />
                    <span className="fa fa-circle" />
                    {t('individual')}
                  </label>
                </div>
              </div>
              
              <div className="c-checkbox"
              style={{
                display: joinInfo.memberType === "2" ? "block" : "none",
              }}>
                <label>
                  <input 
                    type="checkbox"
                    className="check"
                    name="adminRequest"
                    onChange={onCheckbox}
                    checked={joinInfo.adminRequest}
                    disabled={joinInfo.inviteEmailYn === true} 
                    />
                  <span className="fa fa-check" />
                  {t('adminRequest')} 
                </label>
              </div>

              <div className="form-group" 
              style={{
                display: joinInfo.memberType === "2" ? "block" : "none",
              }}
              >
                <label><Trans >{t('companyName')} </Trans></label>
                <div className="input-chaining">
                <input
                  className={"form-control"}
                  type="text"
                  name="company"
                  placeholder={t('pleaseCompName')} 
                  value={joinInfo.company}
                  onChange={onChangeFieldValue}
                  disabled={joinInfo.inviteEmailYn === true} 
                />
                <div className="append" onClick={handleCompanyDupCheck}>
                  <button
                    id="search"
                    className={"btn btn-s h-full border-0 bg-w"}
                    type="button"
                  >
                    <Trans >{t('duplicateCheck')}</Trans>
                  </button>
                </div>

                </div>
                {joinInfo.companyDupCheck && (
                  <p className="validation-message--success">Good!</p>
                )}
                <p className="validation-message">{errors.company}</p>
              </div>

              <div className="form-group">
                <label><Trans >{t('name')} </Trans></label>
                <input
                  className={"form-control"}
                  type="text"
                  name="name"
                  placeholder ={t('pleaseYouName')}
                  value={joinInfo.name}
                  onChange={onChangeFieldValue}
                />
                <p className="validation-message">{errors.name}</p>
              </div>
              <div className="form-group">
                <label><Trans >{t('mobilePhoneNumber')} </Trans></label>
                <input
                  className={"form-control"}
                  type="text"
                  name="hphone"
                  placeholder={t('EnterMobilePhoneNumber')} 
                  maxLength="12"
                  value={joinInfo.hphone}
                  onChange={onChangeFieldValue}
                />
                <p className="validation-message">{errors.hphone}</p>
              </div>
              <div className="form-group">
                <label><Trans >{t('phoneNumber')} </Trans></label>
                <input
                  className={"form-control"}
                  type="text"
                  name="phoneNo"
                  placeholder={t('landlineNumber')}
                  maxLength="12"
                  value={joinInfo.phoneNo}
                  onChange={onChangeFieldValue}
                />
                <p className="validation-message">{errors.phoneNo}</p>
              </div>
            </fieldset>
          </div>
        </div>
        <div className="card w-half mt-0 ml-30">
          <div className="card__header"><Trans >{t('agreeToTerms')} </Trans></div>
          <div className="card__main">
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  onChange={onCheckboxAll}
                  checked={
                    joinInfo.agree14over &&
                    joinInfo.agreeServiceUse &&
                    joinInfo.agreePersonalInfo &&
                    joinInfo.agreeAd
                  }
                />
                <span className="fa fa-check" />
                <span>{t('agreeToAll')}</span>
              </label>
            </div>

            <div className="divider"></div>
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agree14over"
                  checked={joinInfo.agree14over}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>{t('checkOlder')} </span>
              </label>
              <p className="validation-message">{errors.agree14over}</p>
            </div>
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agreeServiceUse"
                  checked={joinInfo.agreeServiceUse}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>{t('agreeService')}</span>
              </label>

              <small onClick={togglePolicy}>{t('viewContent')} </small>
              <p className="validation-message">{errors.agreeServiceUse}</p>
            </div>
            <Policy showPolicy={showPolicy} togglePolicy={togglePolicy} />
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agreePersonalInfo"
                  checked={joinInfo.agreePersonalInfo}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>{t('agreeRequired')} </span>
              </label>

              <small onClick={togglePrivacy}>{t('viewContent')} </small>
              <p className="validation-message">{errors.agreePersonalInfo}</p>
            </div>

            <Privacy showPrivacy={showPrivacy} togglePrivacy={togglePrivacy} />
            <div className="c-checkbox">
              <label>
                <input
                  type="checkbox"
                  className="check"
                  name="agreeAd"
                  checked={joinInfo.agreeAd}
                  onChange={onCheckbox}
                />
                <span className="fa fa-check" />
                <span>{t('agreeOptional')} </span>
              </label>
            </div>

            <button className="btn mt-15 mb-15"><Trans >{t('agreeSignUp')} </Trans></button>
            <small className="mb-20">
            <Trans >{t('alreadyAccount')} </Trans> <Link to="/login"> <Trans >{t('login')} </Trans></Link>
            </small>
            <ReCAPTCHA
              sitekey={
                process.env.RECAPCHA_KEY ||
                "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" /* 로컬 테스트키 */
              }
              onChange={onRecaptcha}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Join;
