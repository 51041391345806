import React, { Component } from "react";
import PreviewWrapper from "./PreviewWrapper";
import Swal from "sweetalert2";
import { calculateCash, httpRequest, TRANS_CODE_DATA } from "@/util/common";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'; 

class PreviewWrapperContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chosen: {},
      planName: "",
      planId: "",
      planUsage:0,
      planUsageUse:0,
      planCapacity:0
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.chosen !== prevProps.chosen) {
      const chosen = { ...this.props.chosen };
      Object.keys(chosen).forEach((key) => {
        const obj = chosen[key];
        obj.costUnit = TRANS_CODE_DATA[obj.orgLang + obj.transLang]["unitCost"];
        obj.transType = "AA";
        obj.chargePoint = calculateCash(
          obj.charCount,
          obj.orgLang,
          obj.transLang,
          "AA"
        );
      });

      this.setState({
        chosen,
        planName: this.props.planName || "",
        planId: this.props.planId || "",
        planUsage: this.props.planUsage || 0,
        planUsageUse: this.props.planUsageUse || 0,
        planCapacity: this.props.planCapacity || 0,
      });
    }
  }

  render() {
    const { onClickClosePreview } = this.props;
    const { chosen, planId, planName, planUsage, planUsageUse, planCapacity } = this.state;

    return (
      <PreviewWrapper
        chosen={chosen}
        onClickClosePreview={onClickClosePreview}
        onClickTransType={this.handleClickTransType}
        onAddWishList={this.handleAddWishList}
        planId={planId}
        planName={planName}
        planUsage={planUsage}
        planUsageUse={planUsageUse}
        planCapacity={planCapacity}
      />
    );
  }

  /**
   * 번역비용 금액 라디오버튼 BASIC/SPECIAL/PREMIUM 중 선택하기
   * @param {*} e
   * @param {*} post
   */
  handleClickTransType = (e, post) => {
    const chosen = { ...this.state.chosen };
    chosen[post.documentId].transType = e.target.value;
    chosen[post.documentId].chargePoint = e.target.dataset.chargepoint;
    this.setState({ chosen });
  };

  /**
   * 보관함 추가 버튼 클릭 시 보관함추가 및 Alert 실행하기.
   */
  handleAddWishList = async () => {
    const { auth, t } = this.props;
    const cartItems = Object.values(this.state.chosen).map((doc) => ({
      ...doc,
      transCode: doc.orgLang + doc.transLang,
      transUnit: "CHAR",
      transState: "A", // 번역 상태 (A: 번역요청, P: 진행중, E:진행완료)
      transReqType: "P", // 번역 요청 유형 (P: 특허공보, N: 사용자문서, O: 기타)
    }));

    try {
      /* 보관함 추가 */
      await httpRequest.post(`/cart/${auth.user.idx}`, cartItems);
      Swal.fire({
        icon: "success",
        title: t('addedToStorage'),
        showCancelButton: true,
        cancelButtonText: t('close'),
        confirmButtonText: t('goToStorage'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.props.history.push("/my/wishList");
        }
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: t('failToAddStorage'),
        text: t('failToAddStorageDetail'),
      });
    }
  };
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(withTranslation()(PreviewWrapperContainer));
