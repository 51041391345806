import { login } from "@/commonSlices/authSlice";
import React, { Component } from "react";
import { connect } from "react-redux";
import SendAuthMail from "./SendAuthMail";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from 'react-i18next';

toast.configure();
class SendAuthMailContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.location.query && this.props.location.query.email,
      password: "",
    };
  }

  render() {
    const { email, password } = this.state;

    return (
      <SendAuthMail
        email={email}
        password={password}
        onChangeFieldValue={this.handleChangeFieldValue}
        onSubmit={this.handleSubmit}
      />
    );
  }

  /**
   * 인풋변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * 인증메일 재발송요청(로그인과 동일)
   * @param {*} e
   */
  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.props
      .login({ email, password })
      .unwrap()
      .catch((err) => {
        //이메일 또는 비밀번호를 확인해주세요.
        toast.error(t('loginError3'));
      });
  };
}

export default connect(null, { login })(withTranslation()(SendAuthMailContainer));
