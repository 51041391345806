import { httpRequest , updateHttpRequest} from "@/util/common";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

/**
 * 비동기 액션 - 로그인
 */
export const login = createAsyncThunk(`auth/login`, async (arg) => {
  try{
    const info = { mail: arg.email, password: arg.password };
    const response = await httpRequest.post(`access/loginCognito`, info);
    debugger
    const { jwtToken } = response.data;
    
    // JWT 토큰을 sessionStorage에 저장
    if (jwtToken ) {
      const jwtTokenString = jwtToken.split('Token=')[1].split(';')[0];

      sessionStorage.setItem('id_token', jwtTokenString);
      console.log('JWT Token saved to sessionStorage:', jwtTokenString);
      updateHttpRequest();
    }else{
      return Promise.reject({
        status: error.response ? error.response.status : 500,
        message: error.response.data.message,
        error: error.response.data.error,
      });
    }
    return response.data;
  }catch (error) {
    return Promise.reject({
      status: error.response ? error.response.status : 500,
      message: error.response.data.message,
      error: error.response.data.error,
    });
  }
});



/**
 * 비동기 액션 - 로그아웃(서버히스토리 기록용)
 */
export const logout = createAsyncThunk(`auth/logout`, async () => {
  const response = await httpRequest.post("access/logout", {});
  sessionStorage.removeItem(`ipkk_user`);
  sessionStorage.removeItem(`id_token`);
  delete httpRequest.defaults.headers['Authorization']; 
  document.cookie = '';

  return response;
});

/**
 * 비동기 액선 - 새로고침 시 보유포인트 업데이트하기
 */
export const refreshPoint = createAsyncThunk("auth/refreshPoint", async () => {
  const {
    data: { point },
  } = await httpRequest.get(
    `user/${JSON.parse(sessionStorage.getItem("ipkk_user")).idx}`
  );

  return point;
});

/**
 * auth 슬라이스(리듀서+액션)
 */
export const authSlice = createSlice({
  name: `auth`,
  initialState: {
    isAuthenticated: sessionStorage.getItem(`ipkk_user`) ? true : false,
    user: sessionStorage.getItem(`ipkk_user`) !== undefined ? JSON.parse(sessionStorage.getItem(`ipkk_user`)) : null,
    point: 0,
    isLoading: false,
    error: null,
  },

  reducers: {
    /* 동기 처리 */
    updatePoint: (state, action) => {
      state.point = action.payload;
    },
    clientLogout: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
      state.error = null;
    },
    clearError: (state, action) => {
      state.error = null;
    }
  },

  extraReducers: {
    /*  비동기 처리 */
    [login.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [login.fulfilled]: (state, action) => {
      /** 이메일인증 된 사용자가 로그인하는 경우 */
      if(action.payload.data != undefined){
        sessionStorage.setItem(`ipkk_user`, JSON.stringify(action.payload.data));
        state.isAuthenticated = true;
        state.user = action.payload.data;
        state.point = action.payload.data.point;
        state.isLoading = false;
        state.error = null;
      }else if (action.payload.code === "AUTHENTICATION_MAIL") {
        window.location.href = `/join/regist?email=${action.meta.arg.email}`;
        return;
      }else if (action.payload.error === "AUTHENTICATION_MAIL") {
        window.location.href = `/join/regist?email=${action.meta.arg.email}`;
        return;
      }
      
    },
    [login.rejected]: (state, action) => {
      const lang = localStorage.getItem("lang") || "ko";
      const errorMessages = {
        loginError1: lang === "ko" ? "비활성화 된 계정입니다 기업 관리자에게 문의하세요." : "Your account has been deactivated. Please contact your company administrator.",
        loginError2: lang === "ko" ? "탈퇴된 계정입니다 관리자에게 문의하세요." : "This account has been canceled. Please contact the administrator.",
        loginError3: lang === "ko" ? "이메일 또는 비밀번호를 확인해주세요." : "Please check your email or password.",
      };
      /**관리자가 비활성화한 계정으로 로그인하는 경우 */
      if(action.error.message.includes("406")) {
        //비활성화 된 계정입니다 기업 관리자에게 문의하세요.
        state.error = errorMessages.loginError1;
      }else if (action.error.message.includes("400")) {
        //탈퇴된 계정입니다 관리자에게 문의하세요.
        state.error = errorMessages.loginError2;
      }else{
        //이메일 또는 비밀번호를 확인해주세요.
        state.error = errorMessages.loginError3;
      }
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
    },

    [logout.pending]: (state, action) => {
      state.isLoading = true;
      state.error = null;
    },
    [logout.fulfilled]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
      state.error = null;
    },
    [logout.rejected]: (state, action) => {
      sessionStorage.removeItem(`ipkk_user`);
      sessionStorage.removeItem(`id_token`);
      state.isAuthenticated = false;
      state.user = null;
      state.point = null;
      state.isLoading = false;
      state.error = null;
      console.error("로그아웃 중 에러가 발생하였습니다.");
    },
    [refreshPoint.fulfilled]: (state, action) => {
      state.point = action.payload;
    },
    [refreshPoint.rejected]: (state, action) => {
      return state;
    },
  },
});

export const { updatePoint, clientLogout, clearError } = authSlice.actions;

export default authSlice;
