import React from "react";
import { Link } from "react-router-dom";
import "./RegistFail.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const RegistFail = () => {
  const { t } = useTranslation();
  return (
    <section className="regist-fail">
      <div className="regist-fail__logo">
        <em className="fas fa-times"></em>
      </div>
      <div className="regist-fail__title">{t('registerFail1')}</div>
      <div className="regist-fail__subtitle">
        <Link className="text-kk text-bold" to="/sendAuthMail">
          <em className="fas fa-envelope"></em> {t('registerFail2')}
        </Link>
      </div>
      <Link to="/" className="btn btn-xl">
        <span>{t('home')}</span>
      </Link>
    </section>
  );
};

export default RegistFail;
