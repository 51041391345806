import { clientLogout } from "@/commonSlices/authSlice";
import { convertDateFormat, httpRequest, MONTH_TERM_INFO } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import PaymentHistory from "./PaymentHistory";

class PaymentHistoryContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 3);
    this.state = {
      fromDate: d,
      endDate: new Date(),
      histories: [],
      postsPerPage: 6,
      total: 0,
    };
    this.monthTermList = Object.keys(MONTH_TERM_INFO).map((key) => {
      const obj = MONTH_TERM_INFO[key];
      return {
        label: obj.label,
        value: obj.monthTerm,
        defaultChecked: key === `THREE_MONTH`,
      };
    });
  }

  componentDidMount() {
    this.fetchPaymentHistory(1);
  }

  render() {
    const { fromDate, endDate, histories, postsPerPage, total } = this.state;
    return (
      <PaymentHistory
        monthTermList={this.monthTermList}
        onChangeMonthTerm={this.handleMonthTerm}
        fromDate={fromDate}
        endDate={endDate}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        currentPosts={histories}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchPaymentHistory}
      />
    );
  }

  /**
   * 서버에서 결제현황 정보 받아오기
   */
  fetchPaymentHistory = async (number) => {
    const { fromDate, endDate, postsPerPage } = this.state;
    const {
      auth: { user }, history,
    } = this.props;
    try {
      const {
        data: { total, items },
      } = await httpRequest.get(
        `paymentUser/history?page=${number}&size=${postsPerPage}&from=${convertDateFormat(
          fromDate
        )}&to=${convertDateFormat(endDate)}&userIdx=${user.idx}&company=${user.company}`
      );

      this.setState({ histories: items, total });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 라디오버튼 선택 시 조회기간 변경하기
   * @param {*} e
   */
  handleMonthTerm = (e) => {
    const d = new Date();
    if (e.target.value === `3`) {
      d.setMonth(d.getMonth() - 3);
      this.setState({ ...this.state, fromDate: d });
    } else if (e.target.value === `6`) {
      d.setMonth(d.getMonth() - 6);
      this.setState({ ...this.state, fromDate: d });
    }
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ endDate: date });
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(PaymentHistoryContainer);
