import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; //

const OrderCancel = () => {
  const { t } = useTranslation();
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-times-circle" />
          </div>
          <div className="round-text-bold text-lg">
            {/* 번역 신청이 취소되었습니다. */}
            {t('transFailed')}
          </div>
        </div>
        <div className="result-page__contents-direction">
          <Link to={"/my/transHistory"} className="btn btn-primary btn-xxl">
            {t('myTranslation')}
          </Link>
          <Link to={"/"} className="btn btn-primary btn-xxl">
            {t('home')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OrderCancel;
