import { clientLogout } from "@/commonSlices/authSlice";
import { convertDateFormat, httpRequest } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import TotalUsageHistory from "./TotalUsageHistory";
import { toast } from "react-toastify";

class TotalUsageHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      histories: [],
      postsPerPage: 6,
      total: 0,
      userHist: [],
      userPlan: [],
      memberInfo: JSON.parse(sessionStorage.getItem('ipkk_user')),
      isUnSubcribed:false,
      modalYn:false,
    };
  }

  componentDidMount() {
    this.fetchTotalUsageHistory(1);
  }

  render() {
    const { histories, postsPerPage, total, userHist, userPlan, memberInfo, isUnSubcribed, modalYn } =
      this.state;
    return (
      <TotalUsageHistory
        memberInfo={memberInfo}
        onChangeFieldValue={this.handleChangeFieldValue}
        onUnsubscribe={this.handleUnsubscribe}
        onChangePlan={this.handleChangePlan}
        currentPosts={histories}
        currentPlan={userPlan}
        currentHist={userHist}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchTotalUsageHistory}
        isUnSubcribed={isUnSubcribed}
        cancelSubscription ={this.cancelSubscription}
        modalYn = {modalYn}
        modalYnChange = {this.modalYnChange}
      />
    );
  }

  modalYnChange = () => {
    this.setState((prevState) => ({
      modalYn: !prevState.modalYn,
    }));
  }
  /**
   * 서버에서 캐시이용내역 정보 받아오기
   */
  fetchTotalUsageHistory = async (number) => {
    const { postsPerPage } = this.state;
    const {
      auth: {
        user: { idx, company },
      },
      history,
    } = this.props;
    try {
      const response = await httpRequest.get(
        `usage/total?page=${number}&size=${postsPerPage}&idx=${idx}&company=${company}`
      );
      console.log("response" + JSON.stringify(response))
      const { data } = response || {};
      const { total = 0, items = {}, hist = [] } = data || {};

      const planUsage = Number(items?.plan_usage || 0);
      const planCapacity = Number(items?.plan_capacity || 0);
  
      let isUnsubcribed = planUsage > planCapacity;
  

      this.setState({ histories: items, total, userHist: hist, userPlan: items , isUnsubcribed:isUnsubcribed});
      // if(items === null){
      //   alert("가입된 요금제가 없습니다.")
      //   window.location.href="/info/planInfo";
      // }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 403) {
            this.props.clientLogout();
            return;
          }
          //history.push(`/error?code=${err.response.status}`);
        } else {
          //console.error("응답이 없습니다. 네트워크 문제일 수 있습니다.");
          //history.push(`/error?code=500`); // 임의로 500 에러 페이지로 리다이렉트
        }
    }
  };

  /**
   * 포인트타입 셀렉트박스 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ endDate: date });
  };

  /**
   * 구독시 subscriptionId가 이미 존재할경우 해당구독 데이터 취소 요청 
   * @param {*} subscriptionId  paypal subscriptionId
   */
  cancelSubscription = async (subscriptionId) => {
    const lang = localStorage.getItem("lang") || "en"; 
    //paypal구독취소
    try {
      const response = await httpRequest.get(`plan/cancleSubscription/${subscriptionId}`);
      if(response.data === "success"){
        //db 구독취소
        const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
        const submitInfo = {
          userIdx: userInfo.idx,
          type:3,
        };
        const { status } = await httpRequest.post(`plan/unsubscription`, submitInfo);
        if (status !== 200) {
          throw new Error(lang === "ko" ?"구독정보 제출 중 에러가 발생하였습니다.":"An error occurred while submitting subscription information.");
        } else {
          userInfo.subYn = "N";
          sessionStorage.setItem("ipkk_user", JSON.stringify(userInfo));
          this.setState({setIsPlanExpireModule:true})
          toast.info(lang === "ko" ? "구독이 해지되었습니다." : "Subscription has been canceled.");
          this.modalYnChange();
          this.fetchTotalUsageHistory(1);
        }
      }else{
        console.log(JSON.stringify(response));
        return false;
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
    }
  };

}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(TotalUsageHistoryContainer);
