import { httpRequest, TRANS_CODE_DATA } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import WishList from "./WishList";
import Swal from "sweetalert2";
import { clientLogout } from "@/commonSlices/authSlice";
import { withTranslation } from 'react-i18next'; 

class WishListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wishLists: [],
      chosen: {},
      planId: "",
      planName: "",
      planUsage: 0,
      planUsageUse: 0,
      planCapacity: 0
    };
  }

  componentDidMount() {
    this.fetchWishLists();
  }

  render() {
    const { wishLists, chosen, planName, planId, planUsage, planCapacity } = this.state;
    return (
      <WishList
        wishLists={wishLists}
        chosen={chosen}
        onCheckbox={this.handleCheckbox}
        onCheckboxAll={this.handleCheckboxAll}
        onDeleteByIds={this.handleDeleteByIds}
        onOrder={this.handleOrder}
        planName={planName}
        planId={planId}
        planUsage={planUsage}
        planCapacity={planCapacity}
      />
    );
  }

  /**
   * 서버에서 wishList 정보 받아오기
   */
  fetchWishLists = async () => {
    await this.getSubscription()
    const { user } = this.props.auth;
    const { history } = this.props;
    try {
      const { data: wishLists } = await httpRequest.get(`cart/${user.idx}`);
      this.setState({ wishLists, chosen: {} });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    } finally {
      // 다건주문을 단건주문으로 변경하며 체크박스를 라이오버튼으로 변경했는데 삭제해도 라디오버튼이 체크상태로 남는 문제 초기화 -> 추후 다건주문 변경 시 삭제
      const $radioBtns = document.querySelectorAll(".wishResult");
      if ($radioBtns && $radioBtns.length > 0) {
        $radioBtns.forEach((btn) => {
          btn.checked = false;
        });
      }
    }
  };

  /**
   * 보관함 리스트 중 체크박스 선택하기
   * @param {*} post
   */
  handleCheckbox = (post) => {
    const chosen = {};

    chosen[post.wishIdx] = {
      charCount: post.charCount,
      documentDate: post.documentDate,
      documentId: post.documentId,
      documentNo: post.documentNo,
      documentName: post.documentName,
      orgLang: post.orgLang,
      transLang: post.transLang,
      transCoverage: post.transCoverage,
      costUnit: TRANS_CODE_DATA[post.orgLang + post.transLang]["unitCost"],
      transType: post.transType,
      chargePoint: post.chargePoint,
    };
    this.setState({ chosen });
  };

  /**
   * 체크박스 전체 선택하기 -> 추후 다건 주문으로 수정 시 필요함.
   * @param {*} e
   */
  handleCheckboxAll = (e) => {
    const value = e.target.checked;
    let chosen = {};
    if (value) {
      chosen = this.state.wishLists.reduce((a, c) => {
        a[c.wishIdx] = {
          charCount: c.charCount,
          documentDate: c.documentDate,
          documentId: c.documentId,
          documentNo: c.documentNo,
          documentName: c.documentName,
          orgLang: c.orgLang,
          transLang: c.transLang,
          transCoverage: c.transCoverage,
          costUnit: TRANS_CODE_DATA[c.orgLang + c.transLang]["unitCost"],
          transType: c.transType,
          chargePoint: c.chargePoint,
        };
        return a;
      }, {});
    }

    this.setState({ chosen });
  };

  /**
   * 선택한 목록 삭제하기
   */
  handleDeleteByIds = () => {
    const { t } = this.props;
    Swal.fire({
      title: t('deleteYn'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t('delete'),
      cancelButtonText: t('cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        httpRequest
          .delete(`cart`, { data: Object.keys(this.state.chosen) })
          .then((res) => {
            this.fetchWishLists();
            Swal.fire(t('deleted'));
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: t('failToDeletedStorage'),
            });
          });
      }
    });
  };

  /**
   * 주문 페이지로 이동하기
   * @param {*} previewDocs
   */
  handleOrder = () => {
    const { chosen } = this.state;
    const planUsage = this.state.planUsage;
    this.props.history.push({
      pathname: "/payment/orderPayment",
      query: {
        chosen,
        transReqType: "P",
        planUsageUse:
          (planUsage < Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
            ? planUsage
            : Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
          )
      },
    });
  };

  /**
  * 플랜 가져오기
  * @returns {boolean}
  */
  getSubscription = async () => {
    const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));


    if (userInfo != null) {
      const { history } = this.props;
      if (userInfo.memberType == 1) {

        try {
          const response = await httpRequest(`plan/subscription/${userInfo.idx}`);
          const { status } = response || {}; // response가 undefined일 경우 대비
          const planId = response.data.planId || "";
          const charCount = response.data.charCount || 0;

          if (status !== 200) {
            // throw new Error("구독정보 제출 중 에러가 발생하였습니다.");
            throw new Error("An error occurred while submitting subscription information.");
          }
          this.setState({ planId: planId, charCount: charCount, planName: response.data.planName || "", planUsage: response.data.planUsage || 0, planCapacity: response.data.planCapacity || 0 });  // plan_id를 state로 저장
        } catch (err) {
          console.log("에러")
          console.error(err); // 에러 로그 추가
          history.push(`/error?code=${err.response?.status || 500}`);
        }
      } else if (userInfo.memberType == 2) {

        try {
          const response = await httpRequest(`plan/subscription/company/${userInfo.company}`);
          const { status } = response || {}; // response가 undefined일 경우 대비
          const planId = response.data.planId || "";
          const charCount = response.data.charCount || 0;

          if (status !== 200) {
            //throw new Error("구독정보 제출 중 에러가 발생하였습니다.");
            throw new Error("An error occurred while submitting subscription information.");
          }
          this.setState({ planId: planId, charCount: charCount, planName: response.data.planName || "", planUsage: response.data.planUsage || 0, planCapacity: response.data.planCapacity || 0 });  // plan_id를 state로 저장
        } catch (err) {
          console.log("에러")
          console.error(err); // 에러 로그 추가
          history.push(`/error?code=${err.response?.status || 500}`);
        }
      }
    }

  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default withTranslation()(connect(mapStateToProps, { clientLogout })(WishListContainer));
