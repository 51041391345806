import React, { Fragment } from "react";
import "./PatentPublication.scss";
import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import Tooltip from "@/commonModule/Tooltip/Tooltip";
import parse from "html-react-parser";
import KRGuide from "./fragments/Guide/KRGuide";
import USGuide from "./fragments/Guide/USGuide";
import CNGuide from "./fragments/Guide/CNGuide";
import JPGuide from "./fragments/Guide/JPGuide";
import { convertIntoLangName } from "@/util/common";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용


const PatentPublication = ({
  countryTypeList,
  patentList,
  hasChosen,
  onClickPreview,
  countryEnum,
  onChangeCountryEnum,
  numbers,
  onChangeFieldValue,
  onSearch,
  error,
  onCheckbox,
  onSelectOption,
  isLoading,
}) => {

  const lang = localStorage.getItem('lang') || 'en';
  const { t } = useTranslation();

  return (
    <div className="page-container patent-publication">
      <div className={"page__header"}>
        <div className="page__title"><Trans >{t('patentGazelteTranslation')}</Trans></div>
        <small className="page__subtitle">
        <Trans >{t('searchByPatentNumber')}</Trans>
        </small>
      </div>
      <div>
        <RadioBtn
          name={"countryType"}
          btnList={countryTypeList}
          className={"mb-20"}
          callback={onChangeCountryEnum}
        />
        <div className={"mb-30"}>
          <div className="input-chaining">
            <textarea
              className={"form-control"}
              rows="3"
              id="numbers"
              name="numbers"
              placeholder={t('enterPatentNumber')}
              value={numbers}
              onChange={onChangeFieldValue}
            />
            <div className="append">
              <button
                id="search"
                className={`btn btn-l h-full border-0 bg-kk ${
                  isLoading ? "disabled" : ""
                }`}
                type="button"
                onClick={onSearch}
              >
                <b><Trans >{t('numberSearch')}</Trans></b>
              </button>
            </div>
          </div>
          {error && <p className="validation-message">{error}</p>}
        </div>
        {isLoading ? (
          <div className="loading mg-center"></div>
        ) : patentList && patentList.length > 0 ? (
          <div className="card pd-0">
            <div className="card__main pd-0">
              <table className="table table-bordered" style={{ width:"100%"}}>
                <thead>
                  <tr>
                    <th
                      className={"border-top-0"}
                      width={60}
                      scope="col"
                      colSpan="1"
                    ></th>
                    <th className={"border-top-0"} scope="col">
                      <div className={"d-flex justify-content-end"}>
                        <button
                          className={`btn btn-s ${
                            hasChosen ? "" : "disabled"
                          }  `}
                          onClick={onClickPreview}
                        >
                          <em className={"fa fa-calculator mr-10"} />
                          {t('calulateSelCost')}
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {patentList.map((patent, i) => (
                    <tr key={`patentPublication-${i}`}>
                      <td className={"text-align-center"}>
                        <div className="c-checkbox mr-0">
                          <label className="c-radio mr-0">
                            <input
                              type="radio"
                              name="searchResult"
                              onChange={() => onCheckbox(patent, i)}
                              defaultChecked={false}
                            />
                            <span className="fa fa-check" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div className={"data-row"}>
                          <div className={"d-flex align-items-center"}>
                            <div className="mr-30">
                              <strong>
                              {convertIntoLangName(countryEnum).kName}
                              </strong>
                            </div>
                            <div className="mr-30">
                              <em className="fa fa-arrow-right" />
                            </div>
                            <div className="mr-30">
                              <select
                                id={`selectTransCode${i}`}
                                className="form-control"
                                onChange={() => onSelectOption(patent, i)}
                              >
                                {countryEnum === "KR" ? (
                                  <Fragment>
                                    <option value="EN">{lang === 'ko' ? '영어' : 'English'}</option>
                                    <option value="ZH">{lang === 'ko' ? '중국어' : 'Chinese'}</option>
                                    <option value="JA">{lang === 'ko' ? '일본어' : 'Japanese'}</option>
                                    {/* 일본어, 중국어 옵션추가예정 */}
                                  </Fragment>
                                ) : (
                                  <option value="KO">{lang === 'ko' ? '한국어' : 'Korean'}</option>
                                )}
                              </select>
                            </div>
                            <div className="">
                              <label className="c-radio">
                                <input
                                  type="radio"
                                  id={`coverageFull${i}`}
                                  name={`coverage${i}`}
                                  value="F"
                                  onChange={() => onSelectOption(patent, i)}
                                  defaultChecked
                                />
                                <span className="fa fa-circle" />
                                {lang === 'ko' ? '전문(full-text)' : 'Full-text'}
                              </label>
                              <label className="c-radio">
                                <input
                                  type="radio"
                                  id={`coverageKeyField${i}`}
                                  name={`coverage${i}`}
                                  value="P"
                                  onChange={() => onSelectOption(patent, i)}
                                />
                                <span className="fa fa-circle" />
                                {lang === 'ko' ? '명칭+요약+청구항' : 'Title + Summary + Claims'}
                              </label>
                            </div>
                          </div>
                          <div className={"mt-20 mb-20 text-bold"}>
                            {lang === 'ko' 
                              ? `${patent.patentNumber}(${patent.date})` 
                              : `${patent.patentNumber} (${patent.date})`}
                          </div>
                          <div>
                            <Tooltip
                              message={patent.summary ? parse(patent.summary[0]) : null}
                            >
                              <i className="fa fa-question-circle" /> 
                              {lang === 'ko' ? '명칭' : 'Title'} :{" "}
                              {patent.inventionTitle}
                            </Tooltip>
                          </div>
                          <div>
                            <span>{t('applicationNumber')} : </span>
                            <span>{patent.applicationNumber}</span>
                          </div>
                          <div>
                            <span>{lang === 'ko' ? '출원인' : 'Applicant'} : </span>
                            <span>{patent.owner}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          pickPatentNumberGuide(countryEnum)
        )}
      </div>
    </div>
  );
};

function pickPatentNumberGuide(countryEnum) {
  switch (countryEnum) {
    case "KR":
      return <KRGuide />;
    case "US":
      return <USGuide />;
    case "CN":
      return <CNGuide />;
    case "JP":
      return <JPGuide />;
    default:
      throw new Error("wrong guide arg...");
  }
}

export default PatentPublication;
