import React, { Component } from "react";
import ChargeInfo from "@/serviceModule/ChargeInfo/ChargeInfo";
import { httpRequest, commafy } from "@/util/common";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clientLogout } from "@/commonSlices/authSlice";
import { withTranslation  } from 'react-i18next'; 

class ChargeInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chargeInfo: {
        memberType: "" /* 1번: 개인, 2번: 기업 */,
        company: "",
        mail: "",
        adminYn: "",
        planId: "",
        price: "",
        status: "",
        startDate: "",
        endDate: "",
        description: "",
        paymentDate: "",
        nextPaymentDate: "",
        chargeDttm: "",      
        planPeriod: "",
        currency: "",
        amount:"",
      },
      amount:"10",
      errors: { hphone: "" },
    };
  }

  componentDidMount() {
    this.fetchChargeInfo();
  }

  render() {
    const { chargeInfo, errors, amount } = this.state;

    return (
      <ChargeInfo
        chargeInfo={chargeInfo}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onUnsubscribe={this.handleUnsubscribe}
        onChangePlan={this.handleChangePlan}
        onSubmit={this.handleSubmit}
        amount={amount}
      />
    );
  }

  /**
   * 사용자 플랜 정보 받아오기(회원타입, 플랜, 포인트, 청구일자, 결제 포인트량)
   */
  fetchChargeInfo = async () => {
    const {
      auth: {
        user: { idx },
      },
      history,
    } = this.props;
    try {
      const {
        data: { member_type, company, email, admin_yn, plan_id, status, price, start_date, end_date, description, payment_date, next_payment_date, plan_period, currency },
      } = await httpRequest.get(`charge/${idx}`);
      this.setState({
        ...this.state,
        chargeInfo: { 
          memberType: member_type, 
          company,
          mail: email,
          adminYn: admin_yn,
          planId: plan_id,
          status,
          price: currency + " " +commafy(price),
          startDate: start_date,
          endDate: end_date,
          description,
          paymentDate: payment_date,
          nextPaymentDate: next_payment_date,
          planPeriod: plan_period
        },
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 인풋 변경하기
   * @param fieldName
   * @param value
   */
  handleChangeFieldValue = (e) => {
    this.setState({
      ...this.state,
      userInfo: { ...this.state.chargeInfo, [e.target.name]: e.target.value },
    });
  };

  /**
   * 유효성 검사
   * @return {boolean}
   */
  validate = () => {
    const { t } = this.props; 
    const { hphone } = this.state.chargeInfo;
    let validated = true;
    const errors = {};
    const hphoneRegex =
      /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
    if (!hphone || !hphoneRegex.test(hphone)) {
      errors.hphone = t('EnterMobilePhoneNumber');
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
  * 구독 해지
  * @param e
  */
  handleUnsubscribe = async (e) => {
    const { t } = this.props; 
    e.preventDefault();
    toast.success(t('subscriptionsucesscancell'));
    if (this.validate()) {
      const {
        auth: {
          user: { idx },
        },
      } = this.props;
      const { hphone } = this.state.chargeInfo;
      try {
        //await httpRequest.put(`charge/${idx}`, { hphone });
        toast.success(t('subscriptionsucesscancell'));
      } catch (err) {
        toast.error(t('unsubscriptionfail'));
      }
    }
  };

  /**
  * 요금제 변경
  * @param e
  */
  handleChangePlan = async (e) => {
    const { t } = this.props; 
    e.preventDefault();
    toast.success(t('rateplanchangesucc'));
    if (this.validate()) {
      const {
        auth: {
          user: { idx },
        },
      } = this.props;
      const { hphone } = this.state.chargeInfo;
      try {
        //await httpRequest.put(`charge/${idx}`, { hphone });
        toast.success(t('rateplanchangesucc'));
      } catch (err) {
        toast.error(t('rateplanchangefail'));
      }
    }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(withTranslation()(ChargeInfoContainer));
