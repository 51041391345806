import { Link } from "react-router-dom";
import React from "react";
import SingleMonthCalendar from "@/commonModule/Calendar/SingleMonthCalendar";
import { commafy } from "@/util/common";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const MyInfo = ({ 
  myInfo = {},
  selDate,
  setSelDate,
  onFetchPage,
  onUnsubscribe,
  onChangePlan,
  onChangePayment,
}) => {
  const { t } = useTranslation();
  return (
    <div class="page-container">
      <div class="page__title"><Trans >{t('rateplanpaymentInfo')}</Trans></div>
      <div class="divider"></div>
      <div class="sub-menu">
        <div class="sub-menu__right-post">
          <div class="card w-full">
            <div class="card__header pd-0"><Trans >{t('planInformation')}</Trans></div>
            <div class="card__main">
              <div class="form-group mb-5">
                <label>{t('currentPlan')}</label>
                <div class="input-chaining">
                  <input class="form-control disabled" type="text" name="description" placeholder={t('planInformation')} disabled="" value={t(myInfo.description)}/>
                </div>
              </div>
              <div class="form-group mb-10">
                <label>{t('paymentCategory')} </label>
                <div class="input-chaining">
                  <input class="form-control disabled" type="text" name="payMethod" disabled="" value={t(myInfo.payMethodNm)} />
                </div>
              </div>
              <div class="form-group mb-15">
                <label>{t('paymentMethod1')} </label>
                <div class="input-chaining">
                  <input class="form-control disabled" type="text" name="payType" maxlength="12" value={t(myInfo.payTypeNm)} />
                </div>
              </div>
              <div class="card__footer align-items-end">
                <div class="d-inline-flex" >
                  <button class="btn bg-danger btn-s mb-5" onClick={onUnsubscribe}>{t('unsubscribe')}</button>
                  <button class="btn btn-s mb-5" onClick={onChangePlan}>{t('changeplan')}</button>
                </div>
              </div>
              <div class="pt-30"></div>
              <div class="table-pagination-container">
                <div class="wish-list-control">
                  <div>
                    <span>{t('numOfusageStatus')}</span>
                  </div>
                    <SingleMonthCalendar
                      selDate={selDate}
                      setSelDate={setSelDate}
                      onFetchPage={onFetchPage}
                    />
                </div>
                <div class="table-container">
                  <table>
                    <thead>
                      <tr>
                        <th><Trans >{t('subscriptionMonth')}</Trans></th>
                        <th><Trans >{t('numberOfWordsPro')}</Trans></th>
                        <th><Trans >{t('numberOfWordUse')}</Trans></th>
                        <th><Trans >{t('numberOfWordRemain')}</Trans></th>
                        <th><Trans >{t('userYourCache')}</Trans></th>
                        <th><Trans >{t('unityDirectPayment')}</Trans></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{myInfo.startDate}</td>
                        <td>{commafy(myInfo.planCapacity)}</td>
                        <td>{commafy(myInfo.planCharge)}</td>
                        <td>{commafy(myInfo.planUsage)}</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="card w-full">
            <div class="card__header pd-0"><Trans >{t('paymentInfo')}</Trans></div>
            <div class="card__main">
              <div class="form-group mb-5">
                <label><Trans >{t('paymentInfo')}</Trans></label>
                <div class="input-chaining">
                  <input class="form-control disabled" type="text" name="payTypeNm" placeholder={t('enterEmail')}disabled="" value={t(myInfo.payTypeNm)} />
                </div>
              </div>
            </div>
            <div class="card__footer align-items-end">
              {/* <button class="btn btn-s mb-5" onClick={onChangePayment}>{t('editpaymentInformation1')}</button> */}
              <button class="btn btn-s mb-5"><a href="https://www.paypal.com/signin" target="_blank" className="more">{t('editpaymentInformation1')}</a></button>
              <span>{t('editpaymentInformation')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyInfo;
