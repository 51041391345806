import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./PlanPay.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import PayPalButtonWrapper from "@/commonModule/Paypal/PaypalButtonWrapper";

const PlanPay = ({
  planName,
  planType,
  price,
  planInfo,
  paypalId,
}) => {
  const { t } = useTranslation();
  const [isPlanSignModule, setIsPlanSignModule] = useState(false);

  const amount = "USD " + price
  const planInfoReal = planInfo
  const paypalIdReal = paypalId 
  const handlePlanSignModule = () => {
    setIsPlanSignModule(prevState => !prevState);
  };


  return (
    <>
      <div className="page-container">
        <div className="page__title"><Trans >{t('paymentplan')}</Trans></div>
        <div className="divider"></div>

        <div className="sub-menu">
          <div className="sub-menu__right-post">
            <div className="card w-full">
              <div className="card__header pd-0"><Trans >{t('planInformation')}</Trans></div>
              <div className="card__main">
                <div className="form-group mb-5">
                  <label><Trans >{t('selectPlan')}</Trans></label>
                  <div className="input-chaining"><input className="form-control disabled" type="text" value={planName} /></div>
                </div>
                <div className="form-group mb-10">
                  <label><Trans >{t('paymentCategory')}</Trans></label>
                  <div className="input-chaining"><input className="form-control disabled" type="text" value={price + t('won_month')} /></div>
                </div>
              </div>
            </div>
            <div className="inline-box card w-full">
              <div className="card__header pd-0"><Trans >{t('paymentInformation')}</Trans></div>
              <div className="card__main">
                <div className="form-group mb-5">
                  <label><Trans >{t('paymentInformation')}</Trans></label>
                  <div className="input-chaining"><input className="form-control disabled" type="text" name="email"
                    placeholder={t('enterEmail')} disabled="" value={t('paypalpayment')} /></div>
                </div>
              </div>
              <div className="form-group mb-5 mt-5 inline">
                <label><Trans >{t('nation')}</Trans></label>
                <div className="pad-top input-chaining wd-98">
                  <select className="form-control" name="country">
                    <option value="" disabled selected>{t('pleaseSelectCountry')}</option>
                    <option value="KR">South Korea</option>
                    <option value="US">United States</option>
                    <option value="JP">Japan</option>
                  </select>
                </div>
              </div>
              <div className="form-group mb-5 inline">
                <label><Trans >{t('zipCode')}</Trans></label>
                <div className="pad-top input-chaining">
                  <input className="form-control" type="text" name="text" placeholder={t('pleasZipCode')} />
                </div>
              </div>
            </div>
            <div className="card w-full">
              <div className="card__header pd-0"><Trans >{t('agreeTermsConditions')}</Trans></div>
              <div className="p-info-box">
                <div className="terms-box">
                  <h4><Trans >{t('PolicyArtticle1')}</Trans></h4>
                  <p>
                    <Trans >{t('PolicyArtticle1Detail')}</Trans>
                  </p>
                  <h4><Trans >{t('PolicyArtticle2')}</Trans></h4>
                  <p><Trans >{t('PolicyArtticle2Detail1')}</Trans></p>
                  <ul className="mb-15">
                    <li>
                      <Trans >{t('PolicyArtticle2Detail2')}</Trans>
                    </li>
                    <li>
                      <Trans >{t('PolicyArtticle2Detail3')}</Trans>
                    </li>
                    <li>
                      <Trans >{t('PolicyArtticle2Detail4')}</Trans>
                    </li>
                    <li>
                      <Trans >{t('PolicyArtticle2Detail5')}</Trans>
                    </li>
                    <li>
                      <Trans >{t('PolicyArtticle2Detail6')}</Trans>
                    </li>
                    <li>
                      <Trans >{t('PolicyArtticle2Detail7')}</Trans>
                    </li>
                    <li>
                      <Trans >{t('PolicyArtticle2Detail8')}</Trans>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="pad-top card__footer align-items-end">
                {/* <button className="btn btn-s mb-5" onClick={onSubmit}>요금제 가입</button> */}
                <PayPalButtonWrapper
                  amount={amount}
                  planType={planType}
                  type="3"
                  planInfo={planInfoReal}
                  paypalId={paypalIdReal}
                />
                <span><Trans >{t('toeditpaymentInformation')}</Trans></span>
              </div>
            </div>
          </div>
        </div>


        {/* <div className="payment-plan-wrap">
          <div className="p-plan">
            <span className="p-title">선택 플랜</span>
            <div className="p-info-box">
              <p className="p-info-point-txt">{planType}</p>
              <p className="p-info-nm-txt">{price}원/월</p>
            </div>
          </div>
          <div className="p-plan">
            <span className="p-title">결제 구분</span>
            <div className="p-info-box">
              <p className="p-info-point-txt">매월결제</p>
            </div>
          </div>
          <div className="p-plan">
            <span className="p-title">결제 수단 </span>
            <div className="p-info-box">
              <p className="p-info-point-txt">PayPal</p>
              <div className="form-group mb-5 mt-5">
                <label>국가</label>
                <div className="input-chaining">
                  <select className="form-control" name="country">
                    <option value="" disabled selected>국가를 선택해주세요.</option>
                    <option value="KR">South Korea</option>
                    <option value="US">United States</option>
                    <option value="JP">Japan</option>
                  </select>
                </div>
              </div>
              <div className="form-group mb-5">
                <label>우편번호</label>
                <div className="input-chaining">
                  <input className="form-control" type="text" name="text" placeholder="우편번호를 입력해주세요." />
                </div>
              </div>
            </div>
          </div>
          <div className="p-plan flex-cl">
            <span className="p-title">요금제 이용 약관 동의</span>
            <div className="p-info-box">
              <div className="terms-box">
                요금제 이용 약관 동의....
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="card__footer align-items-end">
          <button type="button" className="btn mt-15" onClick={handlePlanSignModule}>요금제 가입</button>
        </div> */}

      </div >

      {isPlanSignModule && (<div className="modal-wrap">
        <section className="modal-contents">
          <div className="modal-contents__header">
            <span><Trans >{t('subscriptionCompleted')}</Trans></span>
          </div>
          <div className="modal-contents__main">
            <p><Trans >{t('thankYouMessage')}</Trans> </p>
          </div>
          <div className="modal-contents__footer">
            <button type="button" className="btn btn-close" onClick={handlePlanSignModule}>{t('close')}</button>
          </div>
        </section>
      </div>
      )
      }
    </>
  );
};

export default PlanPay;
