import React from "react";
import "./Loader.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Loader = () => {
  const { t } = useTranslation();
  return (
    <section className="loader">
      <div className="loader__logo">
        <i className="fas fa-spinner"></i>
      </div>
      <div className="loader__title">Loading...</div>
      <div className="loader__text">{t('loader')}</div>
    </section>
  );
};

export default Loader;
