import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./Navbar.scss";
import { commafy } from "@/util/common";
import LoginModal from "./fragments/LoginModal/LoginModal";
//import { useTranslation } from 'react-i18next'; // i18next 사용
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import i18n from "@/locale/i18n";
import { useEffect } from "react"; 


const Navbar = ({
  hamburgerClicked,
  onHamburgerClick,
  showModal,
  onShowModal,
  showDropdown,
  onMouseEnter,
  langMouseLeave,
  langMouseEnter,
  showLangDropDown,
  onMouseLeave,
  onClickPriceLink,
  onClickIntroLink,
  onClickMainPage,
  user,
  isAuthenticated,
  point,
  logout,
}) => {

  useEffect(() => {
    const storedLang = localStorage.getItem('lang');
    
    if (!storedLang) {
      // 기본 언어를 영어로 설정
      localStorage.setItem('lang', 'en');
      i18n.changeLanguage('en');
      updateLanguageButton('en');
    } else {
      // 저장된 언어가 있으면 해당 언어로 설정
      i18n.changeLanguage(storedLang);
      updateLanguageButton(storedLang);
    }
  }, []);


  const updateLanguageButton = (lang) => {
    var buttonElement = document.querySelector('.dropbtn');
    if (buttonElement) {
      buttonElement.textContent = lang === 'ko' ? "한국어" : "English";
    }
    const dropdownLinks = document.querySelectorAll('.dropdown-content a');
    dropdownLinks.forEach(link => link.classList.remove('checked'));

    const selectedLink = Array.from(dropdownLinks).find(link => link.textContent.trim() === (lang === 'ko' ? "한국어" : "English"));
    if (selectedLink) selectedLink.classList.add('checked');
  };

  const changeLanguage = (lang) => {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    updateLanguageButton(lang);
    window.location.href = "/";
  };
  
  const { t } = useTranslation();
  const authLink = (
    <Fragment>
      {" "}

      <li>
        <Link className="nav-menu__link" to="/info/planInfo">
        {t('rateplaninformation')}
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="/my/cashHistory">
          <img src="/images/kcash_icon.png" alt="kcash" className="mr-5" />
          {point && commafy(point)}
        </Link>
      </li>
      <li>
        <Link to="#" className="nav-menu__link" onClick={onClickIntroLink}>
          {t('ipkkIntro')}
        </Link>
      </li>
      {(() => 
      {
        const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
        return userInfo && !userInfo.roles.includes("ROLE_MEMBER_PATENT") ? (
          <li>
            <Link className="nav-menu__link" to="/search/patentPublication">
              {t('patentGazelteTranslation')}
            </Link>
          </li>
        ) : null; // roles에 "ROLE_MEMBER_PATENT"가 포함될 경우 null 반환
      })()}
      {(() => 
      {
        const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
        return userInfo && !userInfo.roles.includes("ROLE_MEMBER_TRANSLATE") ? (
          <li>
            <Link className="nav-menu__link" to="/translation/myDoc/uploader">
              {t('myDocTranslation')}
            </Link>
          </li>
        ) : null; // roles에 "ROLE_MEMBER_TRANSLATE"가 포함될 경우 null 반환
      })()}
      
      
      <li>
        <Link className="nav-menu__link" to="/my/transHistory">
          {t('myTranslation')}
        </Link>
      </li>
      <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <Link className="nav-menu__link" to="#">
          <div>
            <i className="fas fa-user-circle" />
            {(() => {
              const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
              return userInfo && userInfo.adminYn === "Y" && userInfo.memberType === 2 ? (
                <span>{user && user.company}</span>
              ) : userInfo && userInfo.adminYn === "N" && userInfo.memberType === 2 ? (
                  <span>{user && user.company}</span>
              ) : <span>{user && user.name}</span>; // adminYn이 "Y"가 아닌 경우 null 반환
            })()}
            <i className="fas fa-caret-down ml-10" />
          </div>
        </Link>
        {showDropdown && (
          <Fragment>
            {/* 장바구니 */}
            <ul className="dropdown-menu" onClick={onMouseLeave}>
              <li>
                <Link to="/my/wishList" className="dropdown-menu__link">
                  <i className="fas fa-archive" />
                  <span>{t('storage')}</span>
                </Link>
              </li>
              {/* 내정보관리 */}
              <li>
                <Link to="/my/userInfo" className="dropdown-menu__link">
                  <i className="fas fa-id-card" />
                  <span>{t('myInformation')}</span>
                </Link>
              </li>
              {/* 1:1 문의 */}
              <li>
                <Link to="/board/inquiry" className="dropdown-menu__link">
                  <i className="fas fa-archive"></i>
                  <span>{t('inquiry')}</span>
                </Link>
              </li>
              {/* 사용자관리 */}
              {(() => {
                const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
                return userInfo && userInfo.adminYn === "Y" && userInfo.memberType === 2 ? (
                  <li>
                    <Link to="/info/corpMange" className="dropdown-menu__link"> 
                      <i className="fas fa-id-card" />
                      <span>{t('userMangemnent')}</span>
                    </Link>
                  </li>
                ) : null; // adminYn이 "Y"가 아닌 경우 null 반환
              })()}
              {/* <li>
                <Link to="/my/pointHistory" className="dropdown-menu__link">
                  <i className="fas fa-file-invoice-dollar" />
                  <span>{t('cashUsageHistory')}</span>
                </Link>
              </li> */}
              {/* 요금제 및 결제정보 */}
              {/* {(() => {
                const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
                return userInfo && userInfo.adminYn === "Y" && userInfo.subYn === "Y" ? (
                  <li>
                    <Link to="/my/myInfo" className="dropdown-menu__link">
                      <i className="fas fa-id-card" />
                      <span>{t('personInfoManagement')}</span>
                    </Link>
                  </li>
                ) : null; // adminYn이 "Y"가 아닌 경우 null 반환
              })()} */}
              {/* 청구현황 */}
              {/* {(() => {
                const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
                return userInfo && userInfo.adminYn === "Y" && userInfo.subYn === "Y" ? (
                  <li>
                    <Link to="/my/chargeInfo" className="dropdown-menu__link">
                      <i className="fas fa-file-invoice-dollar" />
                      <span>{t('claimStatus')}</span>
                    </Link>
                  </li>
                ) : null; // adminYn이 "Y"가 아닌 경우 null 반환
              })()} */}
             
              
              {/* <li>
                <Link to="/my/cashHistory" className="dropdown-menu__link">
                  <i className="fas fa-file-invoice-dollar" />
                  <span>{t('nCashUgeHistory')}</span>
                </Link>
              </li> */}
              {/* 사용량관리 */}
              <li>
                <Link to="/my/usageHistory" className="dropdown-menu__link">
                  <i className="fas fa-file-invoice-dollar" />
                  <span>{t('usageManagement')}</span>
                </Link>
              </li>
              {/* 사용내역관리 */}
              {(() => {
                const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
                return userInfo && userInfo.adminYn === "Y" && userInfo.subYn === "Y" ? (
                  <li>
                    <Link to="/my/totalUsageHistory" className="dropdown-menu__link">
                      <i className="fas fa-file-invoice-dollar" />
                      <span>{t('UsageHistoryMn')}</span>
                    </Link>
                  </li>
                ) : null; // adminYn이 "Y"가 아닌 경우 null 반환
              })()}
              
              {/* <li>
                <Link to="/my/planOrderPayment" className="dropdown-menu__link">
                  <i className="fas fa-file-invoice-dollar" />
                  <span>{t('oderPayment')}</span>
                </Link>
              </li> */}
              {/* 결제현황 */}
              <li>
                <Link to="/my/paymentHistory" className="dropdown-menu__link">
                  <i className="fas fa-file-invoice-dollar" />
                  <span>{t('paymentStatus1')}</span>
                </Link>
              </li>
              {/* 로그아웃 */}
              <li>
                <Link to="#" className="dropdown-menu__link" onClick={logout}>
                  <i className="fas fa-sign-out-alt" />
                  <span> {t('logout')}</span>
                </Link>
              </li>
              
            </ul>
          </Fragment>
        )}
      </li>
      <li onMouseEnter={langMouseEnter} onMouseLeave={langMouseLeave}>
        <div className="dropdown">
          <button className="dropbtn">{localStorage.getItem('lang') === 'ko' ? '한국어' : localStorage.getItem('lang') === 'en' ? 'English' : 'Select Language'}</button>
          {showLangDropDown && (
            <div id="myDropdown" className="dropdown-content">
              <a href="#none" onClick={() => changeLanguage("ko")} className={localStorage.getItem('lang') === 'ko' ? 'checked' : ''}>한국어</a>
              <a href="#none" onClick={() => changeLanguage("en")} className={localStorage.getItem('lang') === 'en' ? 'checked' : ''}>English</a>
            </div>
          )}
        </div>
      </li>
    </Fragment>
  );

  const guestLink = (
    <Fragment>
      <li>
        <Link to="#" className="nav-menu__link" onClick={onClickIntroLink}>
          {t('ipkkIntro')}
        </Link>
      </li>
      <li>
        <Link className="nav-menu__link" to="/info/planInfo">
        {t('rateplaninformation')}
        </Link>
      </li>
      {/* <li>
        <Link to="#" className="nav-menu__link" onClick={onClickPriceLink}>
          {t('quote')}
        </Link>
      </li> */}
      <li>
        <Link className="nav-menu__link" to="#" onClick={onShowModal}>
          {t('login')}
        </Link>
      </li>

      <li>
        <Link className="nav-menu__link" to="/join/form">
          {t('signUp')}
        </Link>
      </li>
      <li onMouseEnter={langMouseEnter} onMouseLeave={langMouseLeave}>
        <div className="dropdown">
          <button className="dropbtn">{localStorage.getItem('lang') === 'ko' ? '한국어' : localStorage.getItem('lang') === 'en' ? 'English' : 'Select Language'}</button>
          {showLangDropDown && (
            <div id="myDropdown" className="dropdown-content">
              <a href="#none" onClick={() => changeLanguage("ko")} className={localStorage.getItem('lang') === 'ko' ? 'checked' : ''}>한국어</a>
              <a href="#none" onClick={() => changeLanguage("en")} className={localStorage.getItem('lang') === 'en' ? 'checked' : ''}>English</a>
            </div>
          )}
        </div>
      </li>
    </Fragment>
  );

  return (
    <div>
      <nav className="navbar">
        <div className="navbar__logo">
          <Link to="#" onClick={onClickMainPage}>
            <img src="/images/logo.png" alt="logo" />
          </Link>
        </div>
        <div className="navbar__hamburger-btn" onClick={onHamburgerClick}>
          <i className={hamburgerClicked ? `fas fa-times` : `fas fa-bars`}></i>
        </div>
        <ul
          className={hamburgerClicked ? `nav-menu active` : `nav-menu`}
          onClick={onHamburgerClick}
        >
          {isAuthenticated ? authLink : guestLink}
        </ul>
      </nav>
      <LoginModal show={showModal} onShowModal={onShowModal} />
    </div>
  );
};

export default Navbar;
