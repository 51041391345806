import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import BoardInquiryDtl from "./BoardInquiryDtl";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from 'react-i18next'; 
import { clientLogout } from "@/commonSlices/authSlice";
import { connect } from "react-redux";

class BoardInquiryDtlContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: {},
      comment: {},
      attachFile: [],
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchBoardInquiryDtl();
  }
  render() {
    const { content, comment, attachFile, isLoading } = this.state;
    return (
      <BoardInquiryDtl
        post={content}
        postComment={comment}
        attachFile={attachFile}
        onFileDownload={this.handleFileDownload}
        isLoading={isLoading}
        onDeleteBoard={this.handleDeleteBoard}
      />
    );
  }

  /**
   * 서버에서 새소식 상세정보 받아오기
   */
  fetchBoardInquiryDtl = async () => {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    try {
      const {
        data: { content, comment, attachFile },
      } = await httpRequest.get(`board/inquiry/${id}`);
      this.setState({ content, comment, attachFile });
    } catch (err) {
      history.push(`/error?code=${err.response.status}`);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  };

  /**
   * 첨부파일 다운로드 받기
   * @param {*} fileIdx
   * @returns
   */
  handleFileDownload = async (fileIdx, orgFileName, fileName) => {
    const url = `${ip_config.baseUrl}/file/downloadBoard?requestId=${fileIdx}&fileName=${fileName}`;
    const { t } = this.props;
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", orgFileName);
      document.body.append(link);
      link.click();
    } catch (err) {
      //다운로드 실패하였습니다.
      toast.error(t('inquiryAlert1'));
    }
  };

  /**
   * 게시글 삭제 요청하기
   */
  handleDeleteBoard = async () => {
    const { t, history } = this.props;
    const {
      params: { id },
    } = this.props.match;
    try {
      //삭제하시겠습니까?
      if (confirm(t('inquiryAlert8'))) {
        await httpRequest.delete(`board/inquiry/${id}`, { data: {} });
        //정상적으로 삭제되었습니다.
        alert(t('inquiryAlert9'));
        history.push('/board/inquiry');
      }
    } catch (err) {
      //삭제 실패하였습니다.
      alert(t('inquiryAlert10'));
    }
  };
}

export default connect(null, { clientLogout })(withTranslation()(BoardInquiryDtlContainer));
