import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Send = ({ email, onChangeFieldValue, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('findPassword')}</Trans></div>

      <div className="divider"></div>
      <form className="w-half mg-center" onSubmit={onSubmit}>
        <div className="card">
          <div className="card__header"><Trans >{t('findPassword')}</Trans></div>
          <div className="card__main">
            <fieldset>
              <div className="form-group">
                <label><Trans >{t('email')}</Trans></label>
                <div className="input-chaining">
                  <input
                    className={"form-control"}
                    type="email"
                    name="email"
                    placeholder={t('enterEmail')}
                    value={email}
                    onChange={onChangeFieldValue}
                    required
                  />
                  <div className="append">
                    <span className="fas fa-envelope dummy-btn bg-w h-full" />
                  </div>
                </div>
                <small>
                  <Trans >{t('sentUponEnter')}</Trans>
                </small>
                <button className="btn mt-15"><Trans >{t('sendPassword')}</Trans></button>
              </div>
              <div className="mt-5 d-flex justify-content-space-between">
                <Link to="/login"><Trans >{t('login')} </Trans></Link>
                <Link to="/join/form"><Trans >{t('signUp')}</Trans></Link>
              </div>
            </fieldset>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Send;
