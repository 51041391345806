import React from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용


const RecoverSuccess = () => {
  const { t } = useTranslation();
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-check" />
          </div>
          <div className="round-text-bold text-lg">
            <p className={"text-align-center"}>
              {t('passwordChangeSuccess')}
            </p>
          </div>
        </div>

        <div className="result-page__contents-direction">
          <Link to={"/login"} className="btn btn-primary btn-xxl">
          <Trans >{t('login')} </Trans>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default RecoverSuccess;
