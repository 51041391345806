import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import Change from "./Change";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation  } from 'react-i18next'; 

class ChangeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword1: "",
      newPassword2: "",
      errors: { newPassword1: "", newPassword2: "" },
    };
  }

  render() {
    const { newPassword1, newPassword2, errors } = this.state;
    return (
      <Change
        newPassword1={newPassword1}
        newPassword2={newPassword2}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onSubmit={this.handleSubmit}
      />
    );
  }

  /**
   * 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * 비밀번호 변경 요청하기
   * @param {*} e
   */
  handleSubmit = async (e) => {
    e.preventDefault();
    const { t } = this.props; 
    if (this.validate()) {
      const { newPassword2 } = this.state;
      const { history } = this.props;
      const token = this.props.location.search.split("?token=")[1];
      try {
        const {
          data: { status },
        } = await httpRequest.put(
          "auth",
          { password: newPassword2 },
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json",
            },
          }
        );
        //if (!status) throw new Error("비밀번호 변경 실패");
        if (!status) throw new Error("Password change failed");
        history.push("/recover/success");
      } catch (err) {
        toast.error(t('passwordchangefail'));
      }
    }
  };

  /**
   * 제출 전 유효성검사
   * @returns
   */
  validate = () => {
    let validated = true;
    const errors = {};
    const passwordRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/i;

    if (!passwordRegex.test(this.state.newPassword1)) {
      errors.newPassword1 = t('passWord8to32');
      validated = false;
    }

    if (!this.state.newPassword1) {
      errors.newPassword1 = t('enternewpassword');
      validated = false;
    }

    if (this.state.newPassword1 !== this.state.newPassword2) {
      errors.newPassword2 = t('passWordnotMatch');
      validated = false;
    }
    if (!this.state.newPassword2) {
      errors.newPassword2 = t('passwordReConfirm');
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };
}

export default (withTranslation()(ChangeContainer));
