import React from "react";
import "./PlanOrderPayment.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
const PlanOrderPayment = ({
  isLoading,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('oderPayment')}</Trans></div>
      <div className="divider"></div>
      <div className="order-payment-wrap">
        <table className="table table-bordered table-st-1">
          <thead>
            <tr>
              <th ><Trans >{t('literatureName')}</Trans></th>
              <th ><Trans >{t('traslationScope')}</Trans></th>
              <th ><Trans >{t('translationType')}</Trans></th>
              <th ><Trans >{t('characterCount')}</Trans></th>
              <th ><Trans >{t('translationStyle')}</Trans></th>
              <th ><Trans >{t('cash')}</Trans></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-align-center">11.docx</td>
              <td className="text-align-center"><Trans >{t('all')}</Trans></td>
              <td className="text-align-center"><Trans >{t('korEngTranslation')}</Trans></td>
              <td className="text-align-center"><Trans >{t('korcharacters154')}</Trans></td>
              <td className="text-align-center"><Trans >{t('aiTransHumancorr')}</Trans></td>
              <td className="text-align-center"><Trans >{t('cash1253')}</Trans></td>
            </tr>
          </tbody>
        </table>
        <div className="order-payment-box">
          <div className="order-head">
            <p className="order-title"><Trans >{t('odererInfo')}</Trans></p>
            <p className="order-desc">{t('notifyProgress')}<span className="point-txt"> {t('email1')}</span> {t('or')} <span className="point-txt">{t('character')}</span>{t('notifyProgress1')}</p>
          </div>
          <div className="order-cont">
            <table className="table table-bordered table-st-1">
              <colgroup>
                <col style={{width: '14%'}} />
                <col />
                <col style={{ width: '14%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th><Trans >{t('phoneNum')}</Trans></th>
                  <td>
                    <input className="form-control" type="tel" name="tel" placeholder= {t('enterPhoneNum')} />
                  </td>
                  <th><Trans >{t('email1')}</Trans></th>
                  <td>
                    <input className="form-control" type="email" name="email" placeholder={t('enterEmail')} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="order-payment-box">
          <div className="order-head">
            <p className="order-title"><Trans >{t('subscriptionplan')}</Trans></p>
          </div>
          <div className="order-cont">
            <table className="table table-bordered table-st-1">
              <colgroup>
                <col style={{ width: '14%' }} />
                <col />
                <col style={{ width: '14%' }} />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th ><Trans >{t('ratePaln1')}</Trans></th>
                  <td >Basic</td>
                  <th ><Trans >{t('availableamount')}</Trans></th>
                  <td >27,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="order-payment-box">
          <div className="order-head">
            <p className="order-title"><Trans >{t('paymentAmount')}</Trans></p>
          </div>
          <div className="order-cont amount-wrap">
            <div className="amounmt-inner">
              <div className="amount-box">
                <dl>
                  <dt><Trans >{t('orderAmount')}</Trans></dt>
                  <dd>1,262 {t('cash')}</dd>
                </dl>
                <dl>
                  <dt><Trans >{t('howusepay')}</Trans></dt>
                  <dd>1,262 </dd>
                </dl>
                <dl>
                  <dt><Trans >{t('holdkkcash')}</Trans></dt>
                  <dd>0</dd>
                </dl>
                <div className="amount-line">
                  <input className="form-control amount-cash" type="text" name="text" value="0" />{t('cash')} <button type="button" className="btn">{t('fulluse')}</button> {t('minimun1unit')}
                </div>
              </div>
              <div className="amount-box">
                <p className="a-title"><Trans >{t('paymentInfo')}</Trans></p>
                <dl>
                  <dt><Trans >{t('orderAmount')}</Trans></dt>
                  <dd>1,262  {t('cash')}</dd>
                </dl>
                <dl>
                  <dt><Trans >{t('useageDeduction')}</Trans> </dt>
                  <dd>1,262 </dd>
                </dl>
                <dl>
                  <dt><Trans >{t('cacheUsage')}</Trans></dt>
                  <dd>(-) 0</dd>
                </dl>
                <dl className="ao-t">
                  <dt><Trans >{t('paymentAmount')}</Trans></dt>
                  <dd>1,265{t('won')}</dd>
                </dl>
                <p className="ao-st">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><path id="Icon_fa-solid-circle-exclamation" data-name="Icon fa-solid-circle-exclamation" d="M6,12A6,6,0,1,0,0,6a6,6,0,0,0,6,6ZM6,3a.561.561,0,0,1,.562.563V6.187a.562.562,0,1,1-1.125,0V3.563A.561.561,0,0,1,6,3ZM5.25,8.25A.75.75,0,1,1,6,9,.75.75,0,0,1,5.25,8.25Z" /></svg>
                  1{t('cash')} = 1{t('won')}
                </p>
              </div>
            </div>
            <div className="amounmt-inner">
              <div className="amounmt-total">
                <div className="amount-to-item">
                  <span><Trans >{t('paymentAmount')}</Trans></span>
                  <p>1,263{t('won')}</p>
                </div>
                <div className="amount-to-item">
                  <p>+</p>
                </div>
                <div className="amount-to-item">
                  <span><Trans >{t('vat10')}</Trans></span>
                  <p>1,26{t('won')}</p>
                </div>
                <div className="amount-to-item">
                  <p>=</p>
                </div>
                <div className="amount-to-item ">
                  <p className="t-tx">1,388{t('won')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="order-payment-box">
          <div className="order-head">
            <p className="order-title"><Trans >{t('paymentMethod1')}</Trans></p>
          </div>
          <div className="order-cont">
            <div className="pay-method">
              <label for="radio1"> <input type="radio" id="radio1" /> {t('ccard')} </label>
                <div className="method-txt">
                  <p className="t"> <Trans >{t('securePayClick')}</Trans></p>
                  <p><Trans >{t('securePayClickDetail')}</Trans></p>
                </div>
            </div>
          </div>
        </div>
        <div className="charge-info-wrap">
          <div className="charge-info">
            <div><img src="https://www.ipkingkong.com/images/main/cash-image.png" alt="cash-image" /></div>
            <div className="ml-10 mt-10"><p><Trans >{t('contantInfomation')}</Trans></p>
              <p><Trans >{t('rechargeBonus')}</Trans></p>
              <button className="charge-info__btn">{t('details')}</button>
            </div>
          </div>
          <div className="align-items-end">
            <button type="button" className="btn btn-lg btn-pay">
              <svg xmlns="http://www.w3.org/2000/svg" width="18.828" height="13.414" viewBox="0 0 18.828 13.414">
                <path id="Icon_feather-check" data-name="Icon feather-check" d="M20,6,9,17,4,12" transform="translate(-2.586 -4.586)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
              </svg>
              {t('makePayment')}</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanOrderPayment;
