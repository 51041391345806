import React from "react";
import "./OrderPayment.scss";
import ChargeInfoContainer from "@/commonModule/ChargeInfo/ChargeInfoContainer";
import { commafy, TRANS_CODE_DATA } from "@/util/common";
import PayPalButtonWrapper from "@/commonModule/Paypal/PaypalButtonWrapper";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용



const OrderPayment = ({
  previewDocs,
  preTotalPoint,
  hphone,
  email,
  holdingPoint,
  usePoint,
  netTotalPoint,
  totalWithVAT,
  onChangeFieldValue,
  onChangeUsePoint,
  onFocusUsePoint,
  onBlurUsePoint,
  onUseAllPoint,
  isLoading,
  errors,
  onPayment,
  planUsageUse
}) => {
  const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
  const lang = localStorage.getItem("lang") || "en"; 
  const { t } = useTranslation();

  return (
    <div className="page-container order-payment">
      <div className={"page__header"}>
        <div className="page__title"><Trans >{t('oderPayment')}</Trans> </div>
      </div>
      <div className={"page-contents"}>
        <div className={"d-flex w-full mb-20"}>
          <table className="table table-bordered w-full">
            <thead>
              <tr>
                <th className="text-center">{t('literatureName')}</th>
                <th style={{ width: 120 }} className="text-align-center">
                {t('traslationScope')}
                </th>
                <th style={{ width: 120 }} className="text-align-center">
                {t('translationType')}
                </th>
                <th style={{ width: 180 }} className="text-align-center">
                {t('characterCount')}
                </th>
                <th style={{ width: 200 }} className="text-align-center">
                {t('translationStyle')}
                </th>
                <th style={{ width: 150 }} className="text-align-center">
                {t('cash')}
                </th>
              </tr>
            </thead>
            <tbody>
              {previewDocs &&
                previewDocs.length > 0 &&
                previewDocs.map((doc, i) => (
                  <tr key={`orderPayment-${i}`}>
                    <td className="text-align-center">{doc.documentName}</td>
                    <td className="text-align-center">
                      {lang === "ko" ? (doc.transCoverage === "F" ? "전체" : "명칭+요약+청구항") : (doc.transCoverage === "F" ? "entire" : "Title + Summary + Claims")}
                    </td>
                    <td className="text-align-center">
                      {TRANS_CODE_DATA[doc.orgLang + doc.transLang][lang === "ko" ? "typeName" : "typeNameEn"]}
                    </td>
                    <td className="text-align-center">
                      {
                        TRANS_CODE_DATA[doc.orgLang + doc.transLang][
                        lang === "ko" ? "orgLangName" : "orgLangNameEn"
                        ]
                      }{" "}
                      {commafy(doc.charCount)}
                      {lang === "ko" ? (doc.orgLang === "EN" ? "단어" : "자") : (doc.orgLang === "EN" ? " word" : " char")}
                    </td>
                    <td className="text-align-center">
                      {
                        lang === "ko"
                          ? doc.transType === "AA"
                            ? "AI번역+휴먼보정"
                            : doc.transType === "AB"
                              ? "번역가 검수"
                              : "변리사 검수"
                          : doc.transType === "AA"
                            ? "AI translation + human editing"
                            : doc.transType === "AB"
                              ? "Translator Review"
                              : "Attorney Review"
                      }
                    </td>
                    <td className="text-align-center">
                      {commafy(doc.chargePoint)} {t('cash')}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="card b pd-0">
          <div className="card__header mb-0 bg-light">
            <label className={"key-info-font"}>{t('odererInfo')}</label>
            <div className={"mt-10 text-normal"}>
            {t('InfoProgressTrans')}{" "}
              <span className={"text-bold text-danger"}>{t('email2')}</span> {t('or')}{" "}
              <span className={"text-bold text-danger"}>{t('cellPhonText')}</span>{t('pleaseKnowAccurately')}
            </div>
          </div>
          <div className="card__main flex-direction-row justify-content-space-evenly">
            <div>
              <div
                className={
                  "d-flex align-items-center justify-content-space-around"
                }
              >
                <label className={"mr-15 text-bold"}>{t('phoneNum')}</label>
                <div>
                  <input
                    className={"form-control"}
                    style={{ width: 250 }}
                    name="hphone"
                    value={hphone}
                    onChange={onChangeFieldValue}
                  />
                </div>
              </div>
              {errors && (
                <p className="validation-message mt-5">{errors.hphone}</p>
              )}
            </div>
            <div>
              {" "}
              <div
                className={
                  "d-flex align-items-center justify-content-space-around"
                }
              >
                <label className={"mr-15 text-bold"}>{t('email2')}</label>
                <div>
                  <input
                    className={"form-control"}
                    style={{ width: 400 }}
                    name="email"
                    value={email}
                    onChange={onChangeFieldValue}
                  />
                </div>
              </div>
              {errors && (
                <p className="validation-message mt-5">{errors.email}</p>
              )}
            </div>
          </div>
        </div>

        <div className="card b pd-0 payment-amount">
          <div className="card__header mb-0 bg-light">
            <label className={"key-info-font"}>{t('paymentAmount')}</label>
          </div>
          <div className="card__main flex-direction-row">
            <div className={"payment-amount-left"}>
              <div className={"key-info-font payment-amount-left__order"}>
                <label>{t('orderAmount')}</label>
                <label>{commafy(preTotalPoint)} {t('cash')}</label>
              </div>
              <div className={"info-font d-flex justify-content-start mb-30"}>
                <label className={"d-flex align-items-flex-start"}>
                {t('holdkkcash')} : {commafy(holdingPoint)}{" "}
                  <img src="/images/kcash_ico-16x16.png" />
                </label>
              </div>
              <div className={"info-font d-flex justify-content-end"}>
                <div className={"d-flex align-items-center"}>
                  <span className={"mr-5"}>
                    <input
                      className="form-control text-align-end"
                      type="text"
                      name="usePoint"
                      value={usePoint}
                      onChange={onChangeUsePoint}
                      onFocus={onFocusUsePoint}
                      onBlur={onBlurUsePoint}
                      disabled={(preTotalPoint - planUsageUse === 0) ? true : false}
                      style={{ background: (preTotalPoint - planUsageUse === 0) ? '#ced4da': ''}}
                  />
                  </span>
                  {t('cash')}{" "}
                  <button
                    className={"btn btn-xs d-inline-flex mr-5 ml-5"}
                    onClick={onUseAllPoint}
                    disabled={(preTotalPoint - planUsageUse === 0) ? true : false}
                    style={{ background: (preTotalPoint - planUsageUse === 0) ? '#555555' : '' }}
                >
                    {t('fulluse')}
                  </button>{" "}
                  <small>{t('minimun1unit')}</small>
                </div>
              </div>
            </div>
            <div
              className={
                "payment-amount-right d-flex flex-direction-column w-half"
              }
            >
              <div className={"key-info-font payment-amount-left__payment"}>
                <label>{t('paymentInfo')}</label>
              </div>
              <div
                className={
                  "info-font d-flex justify-content-space-between mb-5"
                }
              >
                <label>{t('orderAmount')}</label>
                <label>{commafy(preTotalPoint)}</label>
              </div>
              {userInfo.subYn === "Y" && (
                <div
                  className={
                    "info-font d-flex justify-content-space-between mb-5"
                  }
                >
                  <label>{t('usePlan')}</label>
                  <label>(-) {commafy(planUsageUse)}</label>
                </div>
              )}
              <div
                className={
                  "info-font d-flex justify-content-space-between pb-30 border-bottom border-dark"
                }
              >
                <label>{t('cacheUsage')}</label>
                <label>(-) {commafy(usePoint)}</label>
              </div>
              <div
                className={
                  "key-info-font d-flex justify-content-space-between mt-15"
                }
              >
                <label>{t('paymentAmount')}</label>
                <label>{commafy(netTotalPoint)}{t('won')}</label>
              </div>
              <div className={"mt-10 text-align-right"}>
                <small>
                  <em className="fa fa-info-circle"></em> {t('onecashonewon')}
                </small>
              </div>
              <div className={"mt-10 text-align-right"}>
                <small>
                  {userInfo?.subYn === "Y" && (
                    <>
                      <em className="fa fa-info-circle"></em> {t('translationStyle')}&nbsp; : {
                        lang === "ko" 
                          ? "AI번역+휴먼보정 - 8.2, 번역가 검수 - 20, 변리사 검수 - 32" 
                          : "AI translation + human editing - 8.2, Translator Review - 20, Attorney Review - 32"
                      }
                    </>
                  )}
                </small>
              </div>
              {userInfo.subYn === "Y" &&  previewDocs &&
                previewDocs.length > 0 &&
                previewDocs.map((doc, i) => (
                <div className={"mt-10 text-align-right"} key={`mt-10 text-align-right-${i}`}>
                  <small>
                    <em className="fa fa-info-circle"></em>{t('usePlan')}&nbsp;(
                      {TRANS_CODE_DATA[doc.orgLang + doc.transLang][lang === "ko" ? "typeName" : "typeNameEn"]} &nbsp;- &nbsp;
                      [{
                        TRANS_CODE_DATA[doc.orgLang + doc.transLang][
                        lang === "ko" ? "orgLangName" : "orgLangNameEn"
                        ]
                      }{" "}
                      {commafy(doc.charCount)}
                      {lang === "ko" ? (doc.orgLang === "EN" ? "단어" : "자") : (doc.orgLang === "EN" ? " word" : " char")}]
                      &nbsp; * &nbsp;
                      
                    [{
                        doc.transType === "AA"
                        ? "8.2"
                        : doc.transType === "AB"
                            ? "20"
                            : "32"
                    }{t('cash')}
                    ({
                        lang === "ko"
                          ? doc.transType === "AA"
                            ? "AI번역+휴먼보정"
                            : doc.transType === "AB"
                              ? "번역가 검수"
                              : "변리사 검수"
                          : doc.transType === "AA"
                            ? "AI translation + human editing"
                            : doc.transType === "AB"
                              ? "Translator Review"
                              : "Attorney Review"
                      })] &nbsp;=&nbsp;{commafy(doc.chargePoint)} {t('cash')}
                    )
                  </small>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="card b pd-0 payment-total">
          <div className="card__main flex-direction-row justify-content-center align-items-end">
            <div className={"text-align-center"}>
              <div>{t('paymentAmount')}</div>
              <div className={"payment-total__money"}>
                {commafy(netTotalPoint)}{t('won')}
              </div>
            </div>
            <div className={"payment-total__operator"}>+</div>
            <div className={"text-align-center"}>
              <div><Trans >{t('vat10')}</Trans></div>
              <div className={"payment-total__money"}>
                {commafy(Math.floor(netTotalPoint * 0.1))}{t('won')}
              </div>
            </div>
            <div className={"payment-total__operator"}>=</div>
            <div className={"text-align-center payment-total__money text-kk"}>
              {commafy(totalWithVAT)}{t('won')}
            </div>
          </div>
        </div>
{/*         
        {((userInfo.memberType == 2 && userInfo.adminYn == 'Y') || userInfo.memberType == 1)  && (
          <div className="card b pd-0 payment-type" 
            style={{
              display:(preTotalPoint - planUsageUse === 0) ? "none" : "block"
            }}>
            <div className="card__header mb-0 bg-light">
              <label className={"key-info-font"}>{t('paymentMethod1')}</label>
            </div>
            <div className="card__main pl-30 pr-30">
              <div className="c-radio mb-20 info-font">
                <label className={"d-flex align-items-center"}>
                  <input
                    type="radio"
                    className="check"
                    name="creditCard"
                    defaultChecked={true}
                  />
                  <span className={"fa fa-check"} />
                  <span className={""}>{t('ccard')}넌가?</span>
                </label>
              </div>
              <div className={"info-font mb-20"}>{t('securePayClick')}</div>
              <div className={"info-font"}>
              <Trans >{t('securePayClickDetail')}</Trans>
              </div>
            </div>
          </div>
        )} */}

        <br />
        {(userInfo.memberType == 2 && userInfo.adminYn == 'N') && (
          <div className="order-payment-box warring-t">
            <Trans >{t('GeneralUserCash')}</Trans>
          </div>

        )}

        <div className={"d-flex justify-content-space-between"}>
          <ChargeInfoContainer className={"mt-20"} />
          <div className={"mt-30"}>
            {/* */}
            <div className={"orderPayBtn"} style={{ zIndex: '0 !important' }}>  
              {totalWithVAT == 0 && (
                <button
                  className={`btn btn-xxl ${isLoading ? "disabled" : ""}`}
                  onClick={onPayment}
                >
                  <em className="fa fa-check mr-5"></em>
                  <strong> {t('makePayment')}</strong>
                </button>
              )}
              {!(userInfo.memberType == 2 && userInfo.adminYn == 'N') && totalWithVAT != 0 && (
                <PayPalButtonWrapper
                  key={totalWithVAT}
                  amount={"USD " + totalWithVAT}
                  planType={null}
                  onPayment={onPayment}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderPayment;
