import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./SingleMonthCalendar.scss";

const SingleMonthCalendar = ({ selDate, setSelDate, onFetchPage }) => {
  const selDateRef = useRef();

  return (
    <div className="date-picker-container">
      <div className="date-picker-chaining">
        <DatePicker
          ref={selDateRef}
          className="date-picker__control"
          selected={selDate}
          onChange={(date) => {
            setSelDate(date);
            onFetchPage(date);
          }}
          dateFormat={`yyyy-MM`}
          showMonthYearPicker
        />
        <div
          className="date-picker__icon"
          onClick={() => {
            selDateRef.current.setFocus();
          }}
        >
          <i className="fas fa-calendar-alt"></i>
        </div>
      </div>
    </div>
  );
};

export default SingleMonthCalendar;
