import React from "react";
import { Link } from "react-router-dom";
import ChargeInfoContainer from "@/commonModule/ChargeInfo/ChargeInfoContainer";
import { commafy, handleResetPaginationActive } from "@/util/common";
import ReactPaginate from "react-paginate";
import MonthCalendar from "@/commonModule/Calendar/MonthCalendar";
import "./CashHistory.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용



const CashHistory = ({
  pointType,
  targetUser,
  users,
  memberInfo,
  fromDate,
  endDate,
  onChangeFieldValue,
  setFromDate,
  setEndDate,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('nCashUgeHistory')}</Trans></div>
      <small className="page__subtitle">
        <Trans >{t('cashUsageHistoryDetail')}</Trans>
      </small>
      {memberInfo && memberInfo.adminYn === "Y" && memberInfo.memberType === 2 && (
        <div className="form-group mb-10">
          <div className="divider"></div>
          <table>
            <colgroup>
              <col width="150px" />
              <col />
            </colgroup>
            <tr>
              <th scope="row"><Trans >{t('user')}</Trans></th>
              <td>
                <select
                  className="select-box"
                  id="targetUser"
                  name="targetUser"
                  value={targetUser}
                  onChange={onChangeFieldValue}
                >
                  <option value="" selected>{t('all')}</option>
                  {users.map((v, i) => (
                    <option key={`users-${i}`} value={v.idx}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          </table>
        </div>
      )}
      <div className="divider"></div>
      <div className="d-flex justify-content-space-evenly w-two-three">
        <select
          className="select-box"
          id="pointType"
          name="pointType"
          value={pointType}
          onChange={onChangeFieldValue}
        >
          <option value="">{t('all')}</option>
          <option value="0">{t('recharge')}</option>
          <option value="1">{t('usage')}</option>
        </select>
        <MonthCalendar
          fromDate={fromDate}
          setFromDate={setFromDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <button
          className="btn btn-s"
          onClick={() => {
            onFetchPage(1);
            handleResetPaginationActive();
          }}
        >
          <Trans >{t('search')}</Trans>
        </button>
      </div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th><Trans >{t('date')}</Trans></th>
                <th><Trans >{t('type')}</Trans></th>
                <th><Trans >{t('cash')}</Trans></th>
                <th><Trans >{t('balance')}</Trans></th>
                <th><Trans >{t('orderNmuber')}</Trans></th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={5}><Trans >{t('noSearchResults')}</Trans></td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <tr key={`tbody-${i}`}>
                    <td>{post.regDttm}</td>
                    <td
                      className={`text-bold ${
                        post.typeName === "사용"
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {
                        post.typeName === "사용" 
                          ? t('use') 
                          : post.typeName === "환불"
                          ? t('refund')
                          : t('charge') 
                      }
                    </td>
                    <td
                      className={`text-bold ${
                        post.typeName === "사용"
                          ? "text-danger"
                          : "text-primary"
                      }`}
                    >
                      {commafy(post.point)}{t('cash')}
                    </td>
                    <td className="text-bold">
                      {commafy(post.totalPoint)}{t('cash')}
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: "/my/transHistory",
                          query: { orderNo: post.orderNo },
                        }}
                      >
                        {post.orderNo}
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
      <ChargeInfoContainer className={"mt-20"} />
    </div>
  );
};

export default CashHistory;
