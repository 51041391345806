import React from "react";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const JPGuide = () => {
  const { t } = useTranslation();
  return (
    <div className="card pd-0">
      <div className="card__main pd-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"border-top-0"} scope="col" colSpan="1">
                <Trans >{t('numberType')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('numberFormat')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('example')}</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="8"><Trans >{t('apaNo')}</Trans>(JP)</td>
              <td colSpan="2" className="text-bold">
                <Trans >{t('year')}</Trans>
              </td>
            </tr>
            <tr>
              <td><Trans >{t('y4ss6')}</Trans></td>
              <td>2015-193855</td>
            </tr>
            <tr>
              <td><Trans >{t('jppy4ps6pdc')}</Trans></td>
              <td>JP2015193855A</td>
            </tr>
            <tr>
              <td><Trans >{t('y4ps6')}</Trans></td>
              <td>2015193855</td>
            </tr>
            <tr>
              <td colSpan="2" className="text-bold">
              <Trans >{t('era')}</Trans>
              </td>
            </tr>
            <tr>
              <td><Trans >{t('erass6')}</Trans></td>
              <td>02-418732</td>
            </tr>
            <tr>
              <td><Trans >{t('jppera2ps6pdc')}</Trans></td>
              <td>JP02418732A</td>
            </tr>
            <tr>
              <td><Trans >{t('era2ps6')}</Trans></td>
              <td>02418732</td>
            </tr>
            <tr>
              <td rowSpan="7"><Trans >{t('republishNumber')}</Trans></td>
              <td><Trans >{t('y4ds6')}</Trans></td>
              <td>2014/073020</td>
            </tr>
            <tr>
              <td><Trans >{t('woy4ds6')}</Trans></td>
              <td>WO2014/073020</td>
            </tr>
            <tr>
              <td><Trans >{t('woy4ss6')}</Trans></td>
              <td>WO2014-073020</td>
            </tr>
            <tr>
              <td><Trans >{t('jpwoy4ds6')}</Trans></td>
              <td>JP WO2014/073020</td>
            </tr>
            <tr>
              <td><Trans >{t('jpwoy4ps6sdc')}</Trans></td>
              <td>JPWO2014073020A1</td>
            </tr>
            <tr>
              <td><Trans >{t('jpwoy4ds6')}</Trans></td>
              <td>JPWO2014/073020</td>
            </tr>
            <tr>
              <td><Trans >{t('woy2ds6sdc')}</Trans></td>
              <td>WO12/105693A1</td>
            </tr>
            <tr>
              <td rowSpan="3"><Trans >{t('regNumber')}</Trans></td>
              <td><Trans >{t('s7')}</Trans></td>
              <td>4242250</td>
            </tr>
            <tr>
              <td><Trans >{t('s8')}</Trans></td>
              <td>04242250</td>
            </tr>
            <tr>
              <td><Trans >{t('jpps7pdc')}</Trans></td>
              <td>JP4242250B2</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JPGuide;
