import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import Join from "./Join";

class JoinContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //비밀번호 보기
      showPassword:false,
      showConfirmPassword:false,

      // 모달창
      showPrivacy: false,
      showPolicy: false,
      // 에러메세지
      errors: {
        email: "",
        password: "",
        company:"",
        retypePassword: "",
        name: "",
        hphone: "",
        phoneNo: "",
        agree14over: "",
        agreeServiceUse: "",
        agreePersonalInfo: "",
        adminRequest: "",
      },

      joinInfo: {
        // 가입정보
        email: "",
        password: "",
        company:"",
        companyDupCheck:false,
        retypePassword: "",
        name: "",
        hphone: "",
        phoneNo: "",
        memberType: "2" /* 1번: 개인, 2번: 기업 */,
        duty: null,
        joinType: "0",
        agreeAd: false,
        agreeServiceUse: false,
        agreePersonalInfo: false,
        agree14over: false,
        agreeCom: null,
        agreeUniqueInfo: null,
        // 중복확인여부
        dupCheck: false,
        // 봇확인여부
        isHuman: false,
        adminRequest: false,
        inviteEmail:"",
        inviteEmailYn:false
      },
    };
  }
  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const company = queryParams.get('company');
    const memberType = queryParams.get('memberType');
    const inviteEmail = queryParams.get('inviteEmail');
    
    this.setState(prevState => ({
      joinInfo: {
        ...prevState.joinInfo,
        company: company || "",
        memberType: memberType || "2", 
        inviteEmailYn: (company && memberType) ?true : false ,
        inviteEmail: inviteEmail || "",
      }
    }));
}

  render() {
    const { joinInfo, errors, showPrivacy, showPolicy,showPassword,showConfirmPassword } = this.state;
    return (
      <Join
        joinInfo={joinInfo}
        onChangeFieldValue={this.handleChangeFieldValue}
        onDupCheck={this.handleDupCheck}
        onSubmit={this.handleSubmit}
        onRadio={this.handleRadio}
        onCheckbox={this.handleCheckbox}
        onCheckboxAll={this.handleCheckAll}
        errors={errors}
        showPrivacy={showPrivacy}
        showPolicy={showPolicy}
        togglePrivacy={this.togglePrivacy}
        togglePolicy={this.togglePolicy}
        onRecaptcha={this.handleRecaptcha}
        showPassword={showPassword} 
        showConfirmPassword={showConfirmPassword} 
        togglePasswordVisibility={this.togglePasswordVisibility} 
        handleCompanyDupCheck={this.handleCompanyDupCheck}
      />
    );
  }
  /**
   * 비밀번호 보기 버튼
   */
  togglePasswordVisibility = (field) => {
    this.setState((prevState) => ({
      [field]: !prevState[field], 
    }));
  };

  /**
   * 중복확인 전 이메일 유효성검사
   * @returns {boolean}
   */
  validateBeforeDupCheck = () => {
    let validated = true;
    const { email } = this.state.joinInfo;
    const errors = {};
    const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    
    const lang = localStorage.getItem("lang") || "ko";
  
    const messages = {
      emailFormat: lang === "ko" ? "이메일 형식에 맞지 않습니다." : "Invalid email format.",
      emailEmpty: lang === "ko" ? "이메일을 입력해주세요" : "Please enter your email.",
    };
  
    // 이메일 형식 유효성검사
    if (!emailRegex.test(email)) {
      errors.email = messages.emailFormat;
      validated = false;
    }
  
    if (!email) {
      errors.email = messages.emailEmpty;
      validated = false;
    }
  
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 가입정보제출 전 유효성검사
   * @returns {boolean}
   */
  validate = () => {
    let validated = true;
    const {
      dupCheck,
      email,
      password,
      retypePassword,
      company,
      name,
      memberType,
      hphone,
      phoneNo,
      agree14over,
      agreeServiceUse,
      agreePersonalInfo,
      adminRequest,
      companyDupCheck,
      inviteEmailYn,
    } = this.state.joinInfo;
    
    const errors = {};
    const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,32}$/i;
    const hphoneRegex = /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
    const phoneRegex = /^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/;
    
    const lang = localStorage.getItem("lang") || "ko";
  
    const messages = {
      emailCheck: lang === "ko" ? "이메일 중복여부를 확인해주세요" : "Please check for email duplication.",
      companyCheck: lang === "ko" ? "회사명 중복여부를 확인해주세요" : "Please check for company duplication.",
      emailFormat: lang === "ko" ? "이메일 형식에 맞지 않습니다." : "Invalid email format.",
      emailEmpty: lang === "ko" ? "이메일을 입력해주세요" : "Please enter your email.",
      passwordFormat: lang === "ko" ? "비밀번호는 8 ~ 32 자의 영문, 숫자, 특수문자를 조합하여 설정해주세요." : "Password must be 8-32 characters with letters, numbers, and special characters.",
      passwordEmpty: lang === "ko" ? "비밀번호를 입력해주세요" : "Please enter your password.",
      passwordMismatch: lang === "ko" ? "비밀번호가 일치하지 않습니다." : "Passwords do not match.",
      retypePassword: lang === "ko" ? "확인을 위해 다시 한번 비밀번호를 입력해주세요." : "Please re-enter your password.",
      nameEmpty: lang === "ko" ? "이름을 입력해주세요." : "Please enter your name.",
      companyEmpty: lang === "ko" ? "회사명을 입력해주세요." : "Please enter your company name.",
      hphoneFormat: lang === "ko" ? "휴대전화번호 ( - 없이 숫자만 입력)." : "Please enter a valid mobile number (without dashes).",
      phoneFormat: lang === "ko" ? "유선번호 ( - 없이 숫자만 입력)." : "Please enter a valid phone number (without dashes).",
      agree14over: lang === "ko" ? "만 14세 이상인 경우만 가입이 가능합니다." : "Only those aged 14 and above can register.",
      agreeServiceUse: lang === "ko" ? "서비스 약관동의는 필수입니다." : "Service terms agreement is required.",
      agreePersonalInfo: lang === "ko" ? "개인정보 수집 • 이용에 대한 동의는 필수입니다." : "Agreement to personal information collection and usage is required.",
    };
  
    /* 이메일 유효성검사 */
    if (!dupCheck) {
      errors.email = messages.emailCheck;
      validated = false;
    }
    if (!emailRegex.test(email)) {
      errors.email = messages.emailFormat;
      validated = false;
    }
    if (!email) {
      errors.email = messages.emailEmpty;
      validated = false;
    }
  
    /* 비밀번호 유효성검사 */
    if (!passwordRegex.test(password)) {
      errors.password = messages.passwordFormat;
      validated = false;
    }
    if (!password) {
      errors.password = messages.passwordEmpty;
      validated = false;
    }
    if (password !== retypePassword) {
      errors.retypePassword = messages.passwordMismatch;
      validated = false;
    }
    if (!retypePassword) {
      errors.retypePassword = messages.retypePassword;
      validated = false;
    }
  
    /* 이름 입력여부 유효성검사 */
    if (!name) {
      errors.name = messages.nameEmpty;
      validated = false;
    }
  
    /* 기업명 유효성 검사 */
    if (!company && memberType === "2") {
      errors.company = messages.companyEmpty;
      validated = false;
    }
    /* 기업명 중복검사 */
    if (memberType === "2" && inviteEmailYn === false && companyDupCheck === false) {
      errors.company = messages.companyCheck;
      validated = false;
    }
  
    /* 휴대전화번호 유효성검사 */
    if (!hphone || !hphoneRegex.test(hphone)) {
      errors.hphone = messages.hphoneFormat;
      validated = false;
    }
  
    /* 전화번호 유효성검사 */
    if (phoneNo && !phoneRegex.test(phoneNo)) {
      errors.phoneNo = messages.phoneFormat;
      validated = false;
    }
  
    /* 체크박스 유효성검사 */
    if (!agree14over) {
      errors.agree14over = messages.agree14over;
      validated = false;
    }
    if (!agreeServiceUse) {
      errors.agreeServiceUse = messages.agreeServiceUse;
      validated = false;
    }
    if (!agreePersonalInfo) {
      errors.agreePersonalInfo = messages.agreePersonalInfo;
      validated = false;
    }
  
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 아이디 중복체크 요청
   * @param e
   */
  handleDupCheck = async (e) => {
    e.preventDefault();
    const { email } = this.state.joinInfo;
    const lang = localStorage.getItem("lang") || "ko";
  
    const messages = {
      emailExist: lang === "ko" ? "이미 존재하는 이메일입니다." : "This email already exists.",
      errorOccurred: lang === "ko" ? "중복확인 중 에러가 발생하였습니다." : "An error occurred during duplicate check.",
    };
  
    if (this.validateBeforeDupCheck()) {
      try {
        const { data } = await httpRequest.post(`access/check`, {
          key: "duplicate",
          value: email,
        });
  
        if (!data.exist) {
          this.setState({
            ...this.state,
            joinInfo: { ...this.state.joinInfo, dupCheck: true },
            errors: { ...this.state.errors, email: "" },
          });
        } else {
          this.setState({
            ...this.state,
            joinInfo: { ...this.state.joinInfo, dupCheck: false },
            errors: {
              ...this.state.errors,
              email: messages.emailExist,
            },
          });
        }
      } catch (err) {
        this.setState({
          ...this.state,
          joinInfo: { ...this.state.joinInfo, dupCheck: false },
          errors: {
            ...this.state.errors,
            email: messages.errorOccurred,
          },
        });
      }
    }
  };

  /**
   * 회사명 중복체크
   */
  handleCompanyDupCheck = async (e) => {
    e.preventDefault();
    const { company } = this.state.joinInfo;
    const lang = localStorage.getItem("lang") || "ko";
  
    const messages = {
      companyExist: lang === "ko" ? "이미 존재하는 회사명입니다." : "This company already exists.",
      errorOccurred: lang === "ko" ? "중복확인 중 에러가 발생하였습니다." : "An error occurred during duplicate check.",
    };
  
    try {
      const { data } = await httpRequest.post(`access/companyCheck`, {
        key: "duplicateCompany",
        value: company,
      });
      if (!data.exist) {
        this.setState({
          ...this.state,
          joinInfo: { ...this.state.joinInfo, companyDupCheck: true },
          errors: { ...this.state.errors, company: "" },
        });
      } else {
        this.setState({
          ...this.state,
          joinInfo: { ...this.state.joinInfo, companyDupCheck: false },
          errors: {
            ...this.state.errors,
            company: messages.companyExist,
          },
        });
      }
    } catch (err) {
      console.log(JSON.stringify(err))
      this.setState({
        ...this.state,
        joinInfo: { ...this.state.joinInfo, companyDupCheck: false },
        errors: {
          ...this.state.errors,
          company: messages.errorOccurred,
        },
      });
    }
  };

  /**
   * 가입정보 제출하기
   * @param {*} e
   */
  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      email,
      password,
      name,
      hphone,
      phoneNo,
      memberType,
      company,
      duty,
      joinType,
      agreeAd,
      agreeServiceUse,
      agreePersonalInfo,
      agree14over,
      agreeCom,
      agreeUniqueInfo,
      isHuman,
      adminRequest,
      inviteEmail,
    } = this.state.joinInfo;

    const submitInfo = {
      mail: email,
      password,
      name,
      hphone,
      phoneNo,
      memberType: Number(memberType),
      company,
      duty,
      joinType,
      agreeAd: agreeAd ? "Y" : null,
      agreeServiceUse: agreeServiceUse ? "Y" : null,
      agreePersonalInfo: agreePersonalInfo ? "Y" : null,
      agree14over: agree14over ? "Y" : null,
      agreeCom,
      agreeUniqueInfo,
      adminRequest : memberType === "2" ? (adminRequest ? "Y" : null) : null,
      inviteEmail,
    };

    if (this.validate() && isHuman) {
      const { history } = this.props;
      try {
        const { status } = await httpRequest.post(
          `access/register`,
          submitInfo
        );
        if (status !== 200)
          throw new Error("가입정보 제출 중 에러가 발생하였습니다.");

        window.location.href = `/join/regist?email=${email}`;
      } catch (err) {
        history.push(`/error?code=${err.response.status}`);
      }
    }
  };

  /**
   * 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({
      ...this.state,
      joinInfo: { ...this.state.joinInfo, [e.target.name]: e.target.value },
    });
  };

  /**
   * 개인정보,이용약관 모달창 열고닫기
   */
  togglePrivacy = () => {
    const { showPrivacy } = this.state;

    if (!showPrivacy) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    this.setState({ ...this.state, showPrivacy: !this.state.showPrivacy });
  };

  togglePolicy = () => {
    const { showPolicy } = this.state;

    if (!showPolicy) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    this.setState({ ...this.state, showPolicy: !this.state.showPolicy });
  };

  /**
   * 개인/법인 라디오버튼 선택하기
   * @param {*} e
   */
  handleRadio = (e) => {

    this.setState({
      ...this.state,
      joinInfo: { ...this.state.joinInfo, memberType: e.target.value },
    });
  };

  /**
   * 동의 체크박스 선택하기
   * @param {*} e
   */
  handleCheckbox = (e) => {
    this.setState({
      ...this.state,
      joinInfo: {
        ...this.state.joinInfo,
        [e.target.name]: !this.state.joinInfo[e.target.name],
      },
    });
  };

  /**
   * 체크박스 전체선택하기
   * @param {*} e
   * @returns
   */
  handleCheckAll = (e) => {
    const flag = e.target.checked;
    if (flag) {
      this.setState({
        ...this.state,
        joinInfo: {
          ...this.state.joinInfo,
          agree14over: true,
          agreeServiceUse: true,
          agreePersonalInfo: true,
          agreeAd: true,
        },
      });
      return;
    }
    this.setState({
      ...this.state,
      joinInfo: {
        ...this.state.joinInfo,
        agree14over: false,
        agreeServiceUse: false,
        agreePersonalInfo: false,
        agreeAd: false,
      },
    });
  };

  /**
   * 봇 체크 클릭 시 상태변경하기
   * @param {*} value
   */

  handleRecaptcha = (value) => {
    if (value) {
      this.setState({
        ...this.state,
        joinInfo: {
          ...this.state.joinInfo,
          isHuman: true,
        },
      });
    } else {
      this.setState({
        ...this.state,
        joinInfo: {
          ...this.state.joinInfo,
          isHuman: false,
        },
      });
    }
  };
}

export default JoinContainer;
