import React, { Fragment } from "react";
import "./TransHistory.scss";
import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Calendar from "@/commonModule/Calendar/Calendar";
import { checkIfExpired, checkIsTranslatorInspection, handleResetPaginationActive } from "@/util/common";
import { Trans, useTranslation } from 'react-i18next'; // 


const TransHistory = ({
  monthTermList,
  onChangeMonthTerm,
  fromDate,
  endDate,
  setFromDate,
  setEndDate,
  orderNo,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  onFileDownload,
  onOrderPaymentForReview,
  onMouseOver,
  onMouseLeave,
  planUsage,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('myTranslation')}</Trans></div>
      <small className="page__subtitle">
        <Trans >{t('requestProcess')}</Trans>
      </small>
      <div className="divider"></div>
      <div className="trans-history__notification">
        <p><Trans >{t('notice1')}</Trans></p>
        <p>
          <Trans >{t('notice2')}</Trans>
          <Trans >{t('notice3')}</Trans>
        </p>
      </div>
      <div className="d-flex">
        <RadioBtn
          name={`monthTerm`}
          btnList={monthTermList}
          className={`mr-20 month-term-radio`}
          callback={onChangeMonthTerm}
        />
        <Calendar
          fromDate={fromDate}
          setFromDate={setFromDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />

        <form
          className="ml-20"
          onSubmit={(e) => {
            e.preventDefault();
            onFetchPage(1);
            handleResetPaginationActive();
          }}
        >
          {" "}
          <div className="input-chaining">
            <input
              className={"form-control"}
              type="text"
              name="orderNo"
              placeholder={t('orderNumber')}
              value={orderNo}
              onChange={onChangeFieldValue}
            />
            <div
              className="append"
              onClick={() => {
                onFetchPage(1);
                handleResetPaginationActive();
              }}
            >
              <button
                id="search"
                className={"btn btn-s h-full border-0 bg-kk"}
                type="button"
              >
                {t('search')}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th><Trans >{t('orderNumber')}</Trans></th>
                <th><Trans >{t('content')}</Trans></th>
                <th><Trans >{t('downloadPeriod')}</Trans></th>
                <th><Trans >{t('download')}</Trans></th>
                <th><Trans >{t('requestReview')}</Trans></th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={5}><Trans >{t('noSearchResults')}</Trans></td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <tr key={`tbody-${i}`}>
                    <td className="line-height-6">
                      <div>{post.orderNo}</div>
                      <div className="mt-5">
                        <small>{`(${post.reqDttm})`}</small>
                      </div>
                    </td>

                    <td className="d-flex justify-content-space-between">
                      <div className="text-align-start line-height-two">
                        <div>{t(post.transCoverageName)}</div>
                        <div className="text-bold">{post.documentNo}</div>
                      </div>
                      <div className="text-align-end line-height-two">
                        <span
                          className={`badge ${pickBadgeStyle(post.transState)}`}
                        >
                          {t(post.transStateName)}
                        </span>
                        <div>
                          <span className="text-bold mr-5">
                            {t(post.transTypeName)}
                          </span>
                          <span>
                            {" "}
                            {post.orgLang}{" "}
                            <em className="fas fa-arrow-right"></em>{" "}
                            {post.transLang}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>{post.downEndDate && `~ ${post.downEndDate}`}</td>
                    <td className="text-md">
                      {!checkIfExpired(post.downEndDate) &&
                        post.transState === "E" &&
                        post.files &&
                        post.files.length > 0 &&
                        post.files.map((file) => {
                          if (file.ext.toUpperCase() === "PDF")
                            return (
                              <Link
                                key={post.documentId}
                                to="#"
                                onClick={() =>
                                  onFileDownload(
                                    post.downEndDate,
                                    post.transReqIdx,
                                    post.documentId,
                                    file.name,
                                    "PDF"
                                  )
                                }
                              >
                                <em className="far fa-file-pdf text-danger ml-5"></em>
                              </Link>
                            );
                          if (file.ext.toUpperCase() === "DOCX")
                            return (
                              <Link
                                key={post.documentId}
                                to="#"
                                onClick={() =>
                                  onFileDownload(
                                    post.downEndDate,
                                    post.transReqIdx,
                                    post.documentId,
                                    file.name,
                                    "DOCX"
                                  )
                                }
                              >
                                <em className="far fa-file-word text-primary ml-5"></em>
                              </Link>
                            );
                        })}
                    </td>
                    <td>
                      { post.transState === "E" && !checkIsTranslatorInspection(post.transType) ? (
                        <div className={"d-flex justify-content-center mt-5"}>
                          <button
                            className="btn btn-xs btn-outline__green"
                            onClick={() =>
                              onOrderPaymentForReview(post, "AB", "TRANSLATOR")
                            }
                          >
                            <strong>{t('translator')}</strong>  {t('inspection')}
                          </button>
                        </div>
                      ) : null}
                      {  post.transState === "E" && post.transType !== "AC" ? (
                        <div className={"d-flex justify-content-center mt-5"}>
                          <button
                            onMouseOver={() => {
                              onMouseOver(
                                `patent-attorney-${post.transReqIdx}`
                              );
                            }}
                            onMouseLeave={() => {
                              onMouseLeave(
                                `patent-attorney-${post.transReqIdx}`
                              );
                            }}
                            className="btn btn-xs disabled-2 p-relative"

                            /* onClick={() =>
                              onOrderPaymentForReview(
                                post,
                                "AC",
                                "PATENT_ATTORNEY"
                              )
                            } */
                          >
                            <strong>{t('patentAttorney')}</strong>  {t('inspection')}
                            <span
                              id={`patent-attorney-${post.transReqIdx}`}
                              className="tooltip-title tooltip-title-bottom"
                              style={{ width: "120px", padding: "3px" }}
                            >
                              {t('beingPrepared')}
                            </span>
                          </button>
                        </div>
                      ) : null}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

function pickBadgeStyle(transState) {
  switch (transState) {
    case "H":
      return "badge-secondary";
    case "A":
      return "badge-info";
    case "W":
      return "badge-warning";
    case "P":
      return "badge-primary";
    case "E":
      return "badge-success";
    default:
      break;
  }
}

export default TransHistory;
