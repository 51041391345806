import React, { useCallback } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { httpRequest, commafy } from "@/util/common";
import PlanInfo from "@/serviceModule/PlanInfo/PlanInfo";
import { toast } from "react-toastify";

const PayPalButtonWrapper = ({
  amount,
  planType = null,
  type,
  planInfo ,
  paypalId,
  onPayment = () => {},
}) => {
  var paypalIdReal  = paypalId ;
  if (amount.includes("USD")) {
    amount = amount.replace("USD", "KRW");
  }
  if (!amount || typeof amount !== "string") {
    console.error("Invalid amount structure", amount);
    return null;
  }

  function createSubscription(data, actions) {
    return actions.subscription.create({
      plan_id: planInfo.paypalTransactionId,
    });
  }
  const exchangeRate = 1300;

    // 주문 생성 함수 (백엔드 없이)
  const createOrder = useCallback(
    (data, actions) => {
      const krwAmount = parseFloat(amount.split(" ")[1]);
      const usdAmount = (krwAmount / exchangeRate).toFixed(2);
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: usdAmount,
            },
          },
        ],
      });
    },
    [amount] // `amount` 값이 변경될 때마다 `createOrder`가 업데이트됨
  );

  //일반결제 콜백함수
  function onApprove(data, actions) {
    return actions.order.capture().then(async (details) => {
      const name = details.payer.name.given_name;
      let orderNo = null;

      console.log("결제로그" + details);

      if (details.status === "COMPLETED") {
        if (onPayment !== (() => {})) {
          orderNo = await onPayment();
          console.log("orderNo====",orderNo)
        }

        details.payMethod = planType != null ? 1 : 2;
        paymentInsert(details, orderNo);

        if (planType !== null) subscriptionInsert();
      }
    });
  }

  //구독결제 콜백함수
  function onApproveSubscription(data, actions) {
    const subscriptionId = data.subscriptionID;

    return actions.subscription.get().then(async (details) => {
        let orderNo = null;

        console.log("결제로그", details.id);

        // 상태가 COMPLETED인지 확인
        if (details.status === "ACTIVE") {
            // 결제 성공 처리
            if (onPayment !== (() => {})) {
                orderNo = await onPayment();
                console.log("orderNo====", orderNo);
            }

            details.payMethod = 1;
            paymentInsert(details, orderNo);

            if (planType !== null) subscriptionInsert(details.id);
        }else{
          toast.error("구독 결제 관련 에러발생 관리자에게 문의하세요");
          console.log(JSON.stringify(details))
        }
    }).catch(error => {
        console.error("구독 상태 가져오기 오류:", error);
    });
  }

  const subscriptionInsert = async (data) => {
    console.log("들어옴?");
    //구독정보있으면 페이팔 구독취소 
    if(paypalId){
      cancelSubscription(paypalId);
    }
    var isType = type? type : null;
    const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
    const submitInfo = {
      userIdx: userInfo.idx,
      planId: planType,
      type: type != null ? type : 3  ,
      paypalId:data,
      paypalTransactionId:planInfo.paypalTransactionId,
    };

    try {
      const { status } = await httpRequest.post(`plan/subscription`, submitInfo);
      if (status !== 200) {
        throw new Error("구독정보 제출 중 에러가 발생하였습니다.");
      } else {
        userInfo.subYn = "Y";
        sessionStorage.setItem("ipkk_user", JSON.stringify(userInfo));
        toast.info("구독이 완료되었습니다. 메인페이지로 이동합니다.");
        window.location.href = `/`;
      }
    } catch (err) {
      console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }

      window.location.href= `/error?code=${err.response.status}`;
    }
  };

  /**
   * 구독시 subscriptionId가 이미 존재할경우 해당구독 데이터 취소 요청 
   * @param {*} subscriptionId  paypal subscriptionId
   */
  const cancelSubscription = async (subscriptionId) => {
    try {
      const response = await httpRequest.get(`plan/cancleSubscription/${subscriptionId}`);

      if(response.data === "success"){
        return;
      }else{
        console.log(JSON.stringify(response));
        return false;
      }
    } catch (error) {
      console.error("Error cancelling subscription:", error);
    }
  };

    /**
   * 결제정보 insert
   */
  const paymentInsert = async (data, orderNo) => {
    const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
    try {
      const response = await httpRequest.post(`payment/insertPayment`, {
        userIdx: userInfo.idx,
        payType: 1,
        payMethod: data.payMethod,
        status: "completed",
        amount: amount.split(" ")[1],
        currency: amount.split(" ")[0],
        useYn: "Y",
        paypalId: data.id,
        paypalResult: JSON.stringify(data),
        orderNo: orderNo,
      });
    } catch (err) {
      console.log(err);
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  return (
    <>
        

        {planInfo === undefined ? (
          <PayPalScriptProvider 
              options={{ 
                clientId: "AZD0rm_STkJfVroWUOL6vvPD_gu22IQWWALy7oBh0L_G1GvVaqguAXts4gm0DYyheS4I3CYmir9DgAc1"}}>
              <PayPalButtons
                createOrder={createOrder}
                onApprove={onApprove}
            />
          </PayPalScriptProvider>
          ) : (
            <PayPalScriptProvider 
              options={{ 
                clientId: "AZD0rm_STkJfVroWUOL6vvPD_gu22IQWWALy7oBh0L_G1GvVaqguAXts4gm0DYyheS4I3CYmir9DgAc1",
                vault:true ,
                intent: "subscription"}}>
              <PayPalButtons
                createSubscription={createSubscription}
                onApprove={onApproveSubscription}
            />
            </PayPalScriptProvider>
          )}
      
    </>
  );
};

export default PayPalButtonWrapper;