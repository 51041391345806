import React from "react";
import { Link } from "react-router-dom";
import { commafy, handleResetPaginationActive } from "@/util/common";
import ReactPaginate from "react-paginate";
import RadioBtn from "@/commonModule/RadioBtn/RadioBtn";
import Calendar from "@/commonModule/Calendar/Calendar";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const PaymentHistory = ({
  monthTermList,
  fromDate,
  endDate,
  onChangeMonthTerm,
  setFromDate,
  setEndDate,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  const { t } = useTranslation();
  const lang = localStorage.getItem('lang') || 'en'; 

  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('paymentStatus1')}</Trans></div>
      <small className="page__subtitle">
        <Trans >{t('paymentStatusView')}</Trans>
      </small>
      <div className="divider"></div>
      <div className="d-flex justify-content-space-evenly w-two-three">
        <RadioBtn
          name={`monthTerm`}
          btnList={monthTermList}
          className={`mr-20 month-term-radio`}
          callback={onChangeMonthTerm}
        />
        <Calendar
          fromDate={fromDate}
          setFromDate={setFromDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
        <button
          className="btn btn-s"
          onClick={() => {
            onFetchPage(1);
            handleResetPaginationActive();
          }}
        >
          {t('search')}
        </button>
      </div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th><Trans >{t('paymentClassification')}</Trans></th>
                <th><Trans >{t('paymentDateTime')}</Trans></th>
                <th><Trans >{t('memberid')}</Trans></th>
                <th><Trans >{t('paymentMethod')}</Trans></th>
                <th><Trans >{t('paymentAmount')}</Trans></th>
              </tr>
            </thead>
            <tbody>
            {currentPosts && currentPosts.length === 0 && (
              <tr>
                <td colSpan={5}><Trans>{t('noSearchResults')}</Trans></td>
              </tr>
            )}
            {currentPosts && currentPosts.length > 0 && currentPosts.map((post, i) => {
              let payTypeNm = '';
              let payMethodNm = '';

              // payTypeNm 번역 처리
              if (post?.payType === 1) {
                payTypeNm = lang === 'ko' ? '페이팔결제' : 'PayPal Payment';
              } else if (post?.payType === 2) {
                payTypeNm = lang === 'ko' ? '카드결제' : 'Card Payment';
              } else if (post?.payType === 3) {
                payTypeNm = lang === 'ko' ? '계좌이체' : 'Bank Transfer';
              } else {
                payTypeNm = lang === 'ko' ? '기타' : 'Other';
              }

              // payMethodNm 번역 처리
              if (post?.payMethod === "1") {
                payMethodNm = lang === 'ko' ? '구독결제' : 'Subscription Payment';
              } else if (post?.payMethod === "2") {
                payMethodNm = lang === 'ko' ? '단건결제' : 'Single Payment';
              } else if (post?.payMethod === "3") {
                payMethodNm = lang === 'ko' ? '추가결제' : 'Additional Payment';
              } else {
                payMethodNm = lang === 'ko' ? '기타' : 'Other';
              }

              return (
                <tr key={`tbody-${i}`}>
                  <td>{payTypeNm}</td>
                  <td>{post.paymentDate}</td>
                  <td>{post.email}</td>
                  <td>{payMethodNm}</td>
                  <td>{post.currency} {commafy(post.amount)}</td>
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
