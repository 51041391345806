import React, { useState } from "react"; 
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import ReactPaginate from "react-paginate";

const BoardInquiry = ({ 
  searchTitle,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  const { t } = useTranslation();
  
  const getTranslatedFaqTypeName = (type) => {
    const typeMapping = {
      '회원정보': 'userInfo',
      '결제/환불': 'payRefune',
      '시스템/에러': 'systemError',
      '사용법': 'howToUse',
      '기타': 'others',
    };
    return t(typeMapping[type] || type);
  };
  
  return (
    <div className="page-container">
      {/* 1:1 문의내역 */}
      <div className="page__title">{t('inquiryList')}</div>
      <div className="divider"></div>
      <div className="wish-list-control">
        <div>
          <span></span>
        </div>
        <div className="d-inline-flex">
          <Link to="/board/view/createInquiryContents">
            {/* 1:1 문의 등록 */}
            <button className="btn btn-s">{t('inquiryRegist')}</button>
          </Link>
        </div>
      </div>
      <div className="table-pagination-container">
        <div className="table-container ">
          <table className="table table-bordered w-full">
            <thead>
              <tr>
                {/* 구분 */}
                <th>{t('division')}</th>
                {/* 제목 */}
                <th>{t('title1')}</th>
                {/* 작성자 */}
                <th>{t('author')}</th>
                {/* 작성일시 */}
                <th>{t('cdat')}</th>
                {/* 상태 */}
                <th>{t('situation')}</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={6}>{t('noSearchResults')}</td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <tr key={`tbody-${i}`}>
                    <td style={{ width: 150 }}>{post.faqTypeName == "" ? post.faqTypeName : getTranslatedFaqTypeName(post.faqTypeName)}</td>
                    <td className="text-align-start">
                      <Link
                        to={`/board/inquiry/${post.boardContentsIdx}`}
                        className="text-primary text-bold"
                      >
                        {post.title}
                      </Link>
                    </td>
                    <td style={{ width: 150 }}>{post.userName}</td>
                    <td style={{ width: 150 }}>{post.regDttmTime}</td>
                    <td style={{ color: Number(post.commentCnt) > 0 ? '#5d9cec' : '#121212'}}>
                        {
                          // 답변여부
                          (() => {
                            const commentCnt = Number(post.commentCnt);
                            let statusMessage = "";
                            if (commentCnt > 0) {
                              // 답변완료
                              statusMessage = t('answerC');
                            } else if (commentCnt === 0){
                              // 답변대기기
                              statusMessage = t('answerW');
                            }else{
                              statusMessage = "-";
                            }
                            return statusMessage;
                          })()
                        }
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};
export default BoardInquiry;
