import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import Privacy from "../Privacy/Privacy";
import Policy from "../Policy/Policy";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Footer = ({
  showPrivacy,
  showPolicy,
  togglePrivacy,
  togglePolicy,
  onClickIntroLink,
  onClickPriceLink
}) => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-contents">
        <ul className="footer-contents__link">
          <li>
            <Link to="#" onClick={onClickIntroLink}>
              <span>{t('ipkkIntro')}</span>
            </Link>
          </li>
          {(() => 
            {
              const userInfo = JSON.parse(sessionStorage.getItem('ipkk_user'));
              return userInfo && !userInfo.roles.includes("ROLE_MEMBER_PATENT") ? (
                <li>
                  <Link to="/board/news">
                    <span>{t('help')}</span>
                  </Link>
                </li>
              ) : null; // roles에 "ROLE_MEMBER_PATENT"가 포함될 경우 null 반환
            })()}
          <li>
            <Link to="#" onClick={onClickPriceLink}>
              <span>{t('quote')}</span>
            </Link>
          </li>
          <li>
            <Link to="/help/guides">
              <span>{t('instructionsUse')}</span>
            </Link>
          </li>
        </ul>
        <ul className="footer-contents__popup">
          <li>
            <Link to="#" onClick={togglePrivacy}>
              <span>{t('privacyPolicy')}</span>
            </Link>
            <Privacy showPrivacy={showPrivacy} togglePrivacy={togglePrivacy} />
          </li>
          <li>
            <Link to="#" onClick={togglePolicy}>
              <span>{t('tremsOfService')}</span>
            </Link>
            <Policy showPolicy={showPolicy} togglePolicy={togglePolicy} />
          </li>
        </ul>
        <div className="footer-contents__company">
          <p className="name">
          <Trans >{t('locatedWert')}</Trans>
          </p>
          <p className="addr">
          <Trans >{t('locatedWert_1')}</Trans>
          </p>
          <p><Trans >{t('locatedWert_2')}</Trans></p>
          <p>
            Copyright © 2024 IP KINGKONG (WERT Intelligence x SYSTRAN).
            <span>All rights reserved.</span>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
