import React, { useState } from "react";
import { Link } from "react-router-dom";
import { commafy } from "@/util/common";
import "./PlanInfo.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const PlanInfo = ({
  getSubscription,
  planId,
  planInfo,
  paypalId,
}) => {
  const paypalIdReal = paypalId
  const Basic = planInfo.find(plan => plan.name === "Basic");
  const Standard = planInfo.find(plan => plan.name === "Standard");
  const Pro = planInfo.find(plan => plan.name === "Pro");
  const Enterprise = planInfo.find(plan => plan.name === "Enterprise");

  const { t } = useTranslation();
  const [isPlanInfoVisible, setIsPlanInfoVisible] = useState(false);
  const [isPaymentModule, setIsPaymentModule] = useState(false);
  const [isCashChargeModule, setIsCashChargeModule] = useState(false);
  const [isIntroductionModule, setIsIntroductionModule] = useState(false);

  const handlePlanInfoToggle = () => {
    setIsPlanInfoVisible(prevState => !prevState);
    //getSubscription()
  };

  const handlePaymentModueToggle = () => {
    setIsPaymentModule(prevState => !prevState);
  };

  const handleCashChargeModuleToggle = () => {
    setIsCashChargeModule(prevState => !prevState);
  };

  const loginRed = () => {
    window.location.href = `/login`;
  };

  const handleIntroductionModuleToggle = () => {
    setIsIntroductionModule(prevState => !prevState);
  };


  const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));

  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('rateplaninformation')}</Trans></div>
      <div className="divider"></div>

      <div className="y-info-wrap">
        <p className="txt-de"><Trans >{t('ipkkpaymentmethod')}</Trans> </p>
        <div className="y-info">
          <h4 className="h2"><Trans >{t('paymentmethod')}</Trans></h4>
          <div className="d-flex y-info-inner">
            <div className="y-info-box">
              <p className="tit body-md"><Trans >{t('subscriptionplan')}</Trans></p>
              <span className="txt"><Trans >{t('monthlyfee')}</Trans></span>
              <button type="button" className="btn mt-15" onClick={handlePlanInfoToggle}>{t('explorePlans')}</button>
            </div>
            <div className="y-info-box">
              <p className="tit"><Trans >{t('payimmediately')}</Trans></p>
              <span className="txt"><Trans >{t('translationIsRequested')}</Trans></span>
              <button type="button" className="btn mt-15" onClick={handlePaymentModueToggle}>{t('learnMore')}</button>
            </div>
            <div className="y-info-box">
              <p className="tit"><Trans >{t('cashRecharge')}</Trans></p>
              <span className="txt"><Trans >{t('DeductedAfterCharging')}</Trans></span>
              <button type="button" className="btn mt-15" onClick={handleCashChargeModuleToggle}>{t('learnMore')}</button>
            </div>
          </div>
        </div>

        {isPlanInfoVisible && (
          <div className="y-info">
            <h4 className="h2"><Trans >{t('SubscriptionPlansPricing')}</Trans></h4>
            <div className="table-container">
              <div className="table-wrapper">
                <div className="table-data">
                  <div className="data-header">{Basic?.name}</div>
                  <div className="data-header">
                  {isNaN(parseInt(Basic?.price)) ? (
                      '-' 
                    ) : (
                      <>
                        {commafy(parseInt(Basic?.price))} {t('won')}/{t('mon')}
                      </>
                    )}
                  </div>
                  <div className="item-wrapper">
                  {Basic?.joinYn === "1" ? (
                      <>
                        {userInfo !== null && planId == null && (
                          <Link
                            to={{
                              pathname: "/info/planPay",
                              query: {
                                planInfo: Basic,
                                planType: 1,
                                price: parseInt(Basic?.price),
                                planName: Basic?.name,
                                paypalId: paypalIdReal,
                              },
                            }}
                          >
                            <span type="button" className="btn mt-15">
                              <Trans>{t("signUpForPlan")}</Trans>
                            </span>
                          </Link>
                        )}
                        {userInfo == null && (
                          <button type="button" className="btn mt-15" onClick={loginRed}>
                            {t("signUpForPlan")}
                          </button>
                        )}
                        {userInfo !== null && planId != null && (
                          <>
                            {planId == 1 ? (
                              <button type="button" className="btn mt-15 disabled" disabled>
                                <Trans>{t("inUse")}</Trans>
                              </button>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/info/planPay",
                                  query: {
                                    planInfo: Basic,
                                    planType: 1,
                                    price: parseInt(Basic?.price),
                                    planName: Basic?.name,
                                    paypalId: paypalIdReal,
                                  },
                                }}
                              >
                                <span type="button" className="btn mt-15">
                                  <Trans>{t("change")}</Trans>
                                </span>
                              </Link>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                       (
                        <button type="button" className="btn mt-15 disabled" disabled>
                          <Trans>{t("notYet")}</Trans>
                        </button>
                      )
                    )}
                    <div className="item-title body-md"><Trans >{t('max')}{commafy(parseInt(Basic?.charCount))} {t('possibleCount')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('planInfo1')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('additionalUsage')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('translationstorageperiod_1')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('PatentpublicatioFileTranslation')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('highestLevelSecurity')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('editingAndReviewServices')}</Trans></div>
                    
                  </div>
                </div>
                <div className="table-data">
                  <div className="data-header">{Standard?.name}</div>
                  <div className="data-header">
                  {isNaN(parseInt(Standard?.price)) ? (
                      '-' 
                    ) : (
                      <>
                        {commafy(parseInt(Standard?.price))} {t('won')}/{t('mon')}
                      </>
                    )}
                  </div>
                  <div className="item-wrapper">
                  {Standard?.joinYn === "1" ? (
                    <>
                      {userInfo !== null && planId == null && (
                        <Link
                          to={{
                            pathname: "/info/planPay",
                            query: {
                              planInfo: Standard,
                              planType: 2,
                              price: parseInt(Standard?.price),
                              planName: Standard?.name,
                              paypalId: paypalIdReal,
                            },
                          }}
                        >
                          <span type="button" className="btn mt-15">
                            <Trans>{t("signUpForPlan")}</Trans>
                          </span>
                        </Link>
                      )}
                      {userInfo !== null && planId != null && (
                        <>
                          {planId == 2 ? (
                            <button type="button" className="btn mt-15 disabled" disabled>
                              <Trans>{t("inUse")}</Trans>
                            </button>
                          ) : (
                            <Link
                              to={{
                                pathname: "/info/planPay",
                                query: {
                                  planInfo: Standard,
                                  planType: 2,
                                  price: parseInt(Standard?.price),
                                  planName: Standard?.name,
                                  paypalId: paypalIdReal,
                                },
                              }}
                            >
                              <span type="button" className="btn mt-15">
                                <Trans>{t("change")}</Trans>
                              </span>
                            </Link>
                          )}
                        </>
                      )}
                      {userInfo == null && (
                        <button type="button" className="btn mt-15" onClick={loginRed}>
                          {t("signUpForPlan")}
                        </button>
                      )}
                    </>
                  ) : (
                    (
                      <button type="button" className="btn mt-15 disabled" disabled>
                        <Trans>{t("notYet")}</Trans>
                      </button>
                    )
                  )}
                    <div className="item-title body-md"><Trans >{t('max')}{commafy(parseInt(Standard?.charCount))} {t('possibleCount')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('planInfo2')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('additionalUsage')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('translationstorageperiod_30')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('PatentpublicatioFileTranslation')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('highestLevelSecurity')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('editingAndReviewServices')}</Trans></div>
                  </div>
                </div>
                <div className="table-data">
                  <div className="data-header">{Pro?.name}</div>
                  <div className="data-header">
                  {isNaN(parseInt(Pro?.price)) ? (
                      '-' 
                    ) : (
                      <>
                        {commafy(parseInt(Pro?.price))} {t('won')}/{t('mon')}
                      </>
                    )}
                  </div>
                  <div className="item-wrapper">
                  {Pro?.joinYn === "1" ? (
                      <>
                        {userInfo !== null && planId == null && (
                          <Link
                            to={{
                              pathname: "/info/planPay",
                              query: {
                                planInfo: Pro,
                                planType: 3,
                                price: parseInt(Pro?.price),
                                planName: Pro?.name,
                                paypalId: paypalIdReal,
                              },
                            }}
                          >
                            <span type="button" className="btn mt-15">
                              <Trans>{t("signUpForPlan")}</Trans>
                            </span>
                          </Link>
                        )}
                        {userInfo !== null && planId != null && (
                          <>
                            {planId == 3 ? (
                              <button type="button" className="btn mt-15 disabled" disabled>
                                <Trans>{t("inUse")}</Trans>
                              </button>
                            ) : (
                              <Link
                                to={{
                                  pathname: "/info/planPay",
                                  query: {
                                    planInfo: Pro,
                                    planType: 3,
                                    price: parseInt(Pro?.price),
                                    planName: Pro?.name,
                                    paypalId: paypalIdReal,
                                  },
                                }}
                              >
                                <span type="button" className="btn mt-15">
                                  <Trans>{t("change")}</Trans>
                                </span>
                              </Link>
                            )}
                          </>
                        )}
                        {userInfo == null && (
                          <button type="button" className="btn mt-15" onClick={loginRed}>
                            {t("signUpForPlan")}
                          </button>
                        )}
                      </>
                    ) : (
                       (
                        <button type="button" className="btn mt-15 disabled" disabled>  
                          <Trans>{t("notYet")}</Trans>
                        </button>
                      )
                    )}
                    <div className="item-title body-md"><Trans >{t('max')}{commafy(parseInt(Pro?.charCount))} {t('possibleCount')}</Trans> </div>
                    <div className="item-title body-md"><Trans >{t('planInfo3')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('translationstorageperiod_30')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('PatentpublicatioFileTranslation')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('highestLevelSecurity')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('editingAndReviewServices')}</Trans></div>
                    
                  </div>
                </div>
                <div className="table-data">
                  <div className="data-header">{Enterprise?.name}</div>
                  <div className="data-header">
                  {isNaN(parseFloat(Enterprise?.price)) || parseFloat(Enterprise?.price) === 0 ? (
                      '-' 
                    ) : (
                      <>
                        {commafy(parseFloat(Enterprise?.price))} {t('won')}/{t('mon')}
                      </>
                    )}
                     </div>
                  <div className="item-wrapper">
                  {Enterprise?.joinYn === "1" && (
                      <>
                        {userInfo !== null && planId == null && (
                          <button type="button" className="btn mt-15" onClick={handleIntroductionModuleToggle}>{t('introductionInquiry')}</button>
                        )}
                        {userInfo == null && (
                          <button type="button" className="btn mt-15" onClick={loginRed}>{t('introductionInquiry')}</button>
                        )}
                        {userInfo !== null && planId != null && (
                          <button type="button" className="btn mt-15" onClick={handleIntroductionModuleToggle}>{t('change')}</button>
                        )}
                      </>
                    )}
                    {Enterprise?.joinYn === "0" && (
                      <button type="button" className="btn mt-15 disabled" disabled><Trans>{t('notYet')}</Trans></button>
                    )}
                    <div className="item-title body-md"><Trans >{t('PatentpublicatioFileTranslation')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('planInfo5')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('highestLevelSecurity')}</Trans></div>
                    <div className="item-title body-md"><Trans >{t('editingAndReviewServices')}</Trans></div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {
        isPaymentModule && (
          <div className="modal-wrap">
            <section className="modal-contents">
              <div className="modal-contents__header">
                <span><Trans >{t('aboutInstantPayment')}</Trans></span>
              </div>
              <div className="modal-contents__main">
                <p><Trans >{t('learnAboutRatePlan')}</Trans>
                </p>
              </div>
              <div className="modal-contents__footer">
                <button type="button" className="btn btn-close" onClick={handlePaymentModueToggle}>{t('close')}</button>
              </div>
            </section>
          </div>
        )
      }

      {
        isCashChargeModule && (
          <div class="price-modal">
              <section className="modal-contents">
                <div className="modal-contents__header">
                  <span><Trans >{t('cashRecharge')}</Trans></span>
                  <div className="modal-contents__close-btn" onClick={handleCashChargeModuleToggle}>
                    <i className="fas fa-times"></i>
                  </div>
                </div>
                <div className="modal-contents__main">
                  <table>
                    <thead>
                      <tr>
                        <th><Trans >{t('needTranslation')}</Trans></th>
                        <th className="text-danger"><Trans >{t('addPayment')}</Trans></th>
                        <th><Trans >{t('totalPayment')}</Trans></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>50,000 {t('cash')}</td>
                        <td className="text-danger">5,000 {t('cash')}</td>
                        <td>55,000 {t('won')}</td>
                      </tr>
                      <tr>
                        <td>100,000 {t('cash')}</td>
                        <td className="text-danger">10,000 {t('cash')}</td>
                        <td>110,000 {t('won')}</td>
                      </tr>
                      <tr>
                        <td>300,000 {t('cash')}</td>
                        <td className="text-danger">30,000 {t('cash')}</td>
                        <td>330,000 {t('won')}</td>
                      </tr>
                      <tr>
                        <td>500,000 {t('cash')}</td>
                        <td className="text-danger">50,000 {t('cash')}</td>
                        <td>550,000 {t('won')}</td>
                      </tr>
                      <tr>
                        <td>1,000,000 {t('cash')}</td>
                        <td className="text-danger">150,000 {t('cash')}</td>
                        <td>1,100,000 {t('won')}</td>
                      </tr>
                      <tr>
                        <td>2,000,000 {t('cash')}</td>
                        <td className="text-danger">300,000 {t('cash')}</td>
                        <td>2,200,000 {t('won')}</td>
                      </tr>
                      <tr>
                        <td>5,000,000 {t('cash')}</td>
                        <td className="text-danger">800,000 {t('cash')}</td>
                        <td>5,500,000 {t('won')}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="card modal-contents__card mt-20 mb-20">
                    <div className="card__header">
                      <h4>{t('rechargeMethod')}</h4>
                    </div>
                    <div className="card__main">
                      <ul>
                        <li>
                          <Trans >{t('depositAmonut')}</Trans>
                        </li>
                        <li>
                          <Trans >{t('depositAmonut_1')}</Trans>
                        </li>
                        <li>
                          <Trans >{t('taxInformation')}</Trans>
                          <ul>
                            <li><Trans >{t('cashReceipt')}</Trans></li>
                            <li>
                              <Trans >{t('taxInvoice')}</Trans>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
            </section>
          </div>  
          // <div className="modal-wrap">
          //   <section className="modal-contents">
          //     <div className="modal-contents__header">
          //       <span><Trans >{t('enterpriseIntroductionInquiry')}</Trans></span>
          //     </div>
          //     <div className="modal-contents__main">
          //       <p><Trans >{t('introductionInquiries')}</Trans></p>
          //     </div>
          //     <div className="modal-contents__footer">
          //       <button type="button" className="btn btn-close" onClick={handleCashChargeModuleToggle}>{t('close')}</button>
          //     </div>
          //   </section>
          // </div>
        )
      }

      {
        isIntroductionModule && (
          <div className="modal-wrap">
            <section className="modal-contents">
              <div className="modal-contents__header">
                <span><Trans >{t('enterpriseIntroductionInquiry')}</Trans></span>
              </div>
              <div className="modal-contents__main">
                <p><Trans >{t('introductionInquiries')}</Trans></p>
              </div>
              <div className="modal-contents__footer">
                <button type="button" className="btn btn-close" onClick={handleIntroductionModuleToggle}>{t('close')}</button>
              </div>
            </section>
          </div>
        )
      }



    </div >
  );
};

export default PlanInfo;
