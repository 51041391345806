import React, { Component } from "react";
import { connect } from "react-redux";
import Navbar from "./Navbar";
import { autoWindowScroll } from "@/util/common";
import {clearError, logout, refreshPoint, updatePoint} from "@/commonSlices/authSlice";

class NavbarContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hamburgerClicked: false,
      showModal: false,
      showDropdown: false,
      showLangDropDown: false,
    };
  }

  /**
   * 네브바 cash point 웹소켓 업데이트
   */
  componentDidMount() {
    const { socketClient } = this.props.socket;
    const { auth, updatePoint, refreshPoint } = this.props;
    socketClient && socketClient.onSync(`totalPoint`, updatePoint);
    auth.user && refreshPoint();
  }

  /*   componentWillUnmount() {
    const { socketClient } = this.props.socket;
    socketClient && socketClient.io.off(`totalPoint`);
  } */

  render() {
    const { user, isAuthenticated, point } = this.props.auth;
    const { hamburgerClicked, showModal } = this.state;
    return (
      <Navbar
        hamburgerClicked={hamburgerClicked}
        onHamburgerClick={this.handleHamburgerClick}
        showModal={showModal}
        onShowModal={this.toggleShowModal}
        showDropdown={this.state.showDropdown}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        langMouseEnter={this.langMouseEnter}
        langMouseLeave={this.langMouseLeave}
        showLangDropDown={this.state.showLangDropDown}
        onClickPriceLink={this.handleClickPriceSectionLink}
        onClickIntroLink={this.handleClickIntroSectionLink}
        onClickMainPage={this.handleClickMainPage}
        user={user}
        isAuthenticated={isAuthenticated}
        point={point}
        logout={this.props.logout}
      />
    );
  }

  /**
   * 반응형 헤더 햄버거버튼 열고닫기
   */
  handleHamburgerClick = () => {
    this.setState({ hamburgerClicked: !this.state.hamburgerClicked });
  };

  /**
   * 로그인 모달 열고닫기
   */
  toggleShowModal = () => {
    const { showModal } = this.state;
    if (!showModal) {
      document.body.style.overflow = "hidden";
      this.props.clearError();
    } else {
      document.body.style.overflow = "unset";
    }
    this.setState({ showModal: !this.state.showModal });
  };

  /**
   * 드롭다운 열고닫기
   */
  onMouseEnter = () => {
    this.setState({ showDropdown: true });
  };

  onMouseLeave = () => {
    this.setState({ showDropdown: false });
  };

  /**
   * 언어선택 드롭다운운 열고닫기
   */
  langMouseEnter = () => {
    this.setState({ showLangDropDown: true });
  };

  langMouseLeave = () => {
    this.setState({ showLangDropDown: false });
  };
  
  /**
   * 소개페이지로 이동하고 번역 단가 섹션으로 이동
   */
  handleClickPriceSectionLink = () => {
    const {
      location: { pathname },
      history,
    } = this.props;
    if (pathname === "/info/service") {
      autoWindowScroll("section-cost");
    } else {
      history.push("/info/service");
      
      const timer = setTimeout(() => {
        autoWindowScroll("section-cost");
        clearTimeout(timer);
      }, 100);
    }
  };
  
  /**
   * 소개페이지로 이동하고 스크롤 위로 옮기기
   */
  handleClickIntroSectionLink = () => {
    const {
      location: { pathname },
      history,
    } = this.props;
    if (pathname === "/info/service") {
      autoWindowScroll("section-hero");
    } else {
      history.push("/info/service");
      
      const timer = setTimeout(() => {
        autoWindowScroll("section-hero");
        clearTimeout(timer);
      }, 100);
    }
  };

  /**
   * 메인페이지 소개글로 이동하기
   */
  handleClickMainPage = () => {
    const {
      location: { pathname },
      history,
    } = this.props;
    if (pathname === "/") {
      autoWindowScroll("contentsWrapper");
    } else {
      history.push("/");

      const timer = setTimeout(() => {
        autoWindowScroll("contentsWrapper");
        clearTimeout(timer);
      }, 100);
    }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    socket: state.socket,
  };
};

export default connect(mapStateToProps, { logout, updatePoint, refreshPoint, clearError })(
  NavbarContainer
);
