import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { httpRequest } from "./util/common";

import "./styles/font.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/editor.scss";
import "./styles/contents.scss";
import "./styles/index.scss";


/* 레이아웃  컴포넌트 */
import MainLayout from "./layout/MainLayout/MainLayout";
import SubMenuLayout from "@/layout/SubMenuLayout/SubMenuLayout";

/* 커스텀 리액트 라우터*/
import AppRoute from "./util/AppRoute";
import PublicRoute from "./util/PublicRoute";
import PrivateRoute from "./util/PrivateRoute";

/* 웹소켓 오브젝트 생성*/
import { createSocket } from "./commonSlices/socketSlice";

/* route 폴더 */
import Home from "./routes/Home/Home";
import Service from '@/routes/Service/Service';
import PricePolicy from "./routes/PricePolicy/PricePolicy";
import RegistConfirm from "./routes/RegistConfirm/RegistConfirm";
import Error from "./routes/Error/Error";
import OrderSuccess from "@/routes/paymentResult/OrderSuccess";
import OrderFail from "./routes/paymentResult/OrderFail";
import OrderCancel from "./routes/paymentResult/OrderCancel";
import QuitSuccess from "./routes/QuitSuccess/QuitSuccess";
import RecoverSuccess from "./routes/RecoverSuccess/RecoverSuccess";
import HelpGuides from "./routes/Help/Help";

/* serviceModule 폴더 */
import ChangePasswordContainer from "./serviceModule/ChangePassword/ChangePasswordContainer";
import BoardNewsContainer from "./serviceModule/BoardNews/BoardNewsContainer";
import LoginContainer from "./serviceModule/Login/LoginContainer";
import JoinContainer from "./serviceModule/Join/JoinContainer";
import ChangeContainer from "./serviceModule/Change/ChangeContainer";
import UserInfoContainer from "./serviceModule/UserInfo/UserInfoContainer";
import SendAuthMailContainer from "./serviceModule/SendAuthMail/SendAuthMailContainer";
import SendContainer from "./serviceModule/Send/SendContainer";
import PointHistoryContainer from "./serviceModule/PointHistory/PointHistoryContainer";
import PatentPublicationContainer from "./serviceModule/PatentPublication/PatentPublicationContainer";
import TransHistoryContainer from "./serviceModule/TransHistory/TransHistoryContainer";
import QuitContainer from "./serviceModule/Quit/QuitContainer";
import WishListContainer from "./serviceModule/WishList/WishListContainer";
import MyDocumentContainer from "./serviceModule/MyDocument/MyDocumentContainer";
import RegistResultContainer from "./serviceModule/RegistResult/RegistResultContainer";
import BoardFaqContainer from "./serviceModule/BoardFaq/BoardFaqContainer";
import BoardNewsDtlContainer from "./serviceModule/BoardNewsDtl/BoardNewsDtlContainer";
import OrderPaymentContainer from "./serviceModule/OrderPayment/OrderPaymentContainer";
import LandingLayout from '@/layout/LandingLayout/LandingLayout';


import MyInfoContainer from "./serviceModule/MyInfo/MyInfoContainer";
import ChargeInfoContainer from "./serviceModule/ChargeInfo/ChargeInfoContainer";
import OrderHistoryContainer from "./serviceModule/OrderHistory/OrderHistoryContainer";
import CashHistoryContainer from "./serviceModule/CashHistory/CashHistoryContainer";
import UsageHistoryContainer from "./serviceModule/UsageHistory/UsageHistoryContainer";
import PlanOrderPaymentContainer from "./serviceModule/OrderPayment/PlanOrderPaymentContainer";
import PaymentHistoryContainer from "./serviceModule/PaymentHistory/PaymentHistoryContainer";

import PlanInfoContainer from "./serviceModule/PlanInfo/PlanInfoContainer";
import PlanPayContainer from "./serviceModule/PlanPay/PlanPayContainer";
import CorpManageContainer from "./serviceModule/CorpMange/CorpManageContainer";

import TotalUsageHistoryContainer from "./serviceModule/UsageHistory/TotalUsageHistoryContainer";

import BoardInquiryContainer from "./serviceModule/BoardInquiry/BoardInquiryContainer";
import BoardInquiryDtlContainer from "./serviceModule/BoardInquiryDtl/BoardInquiryDtlContainer";
import CreateInquiryContentsContainer from "./serviceModule/CreateInquiryContents/CreateInquiryContentsContainer"
import EditInquiryContentsContainer from "./serviceModule/EditInquiryContents/EditInquiryContentsContainer"

class App extends Component {
  /**
   * 웹소켓 연결
   */
  componentDidMount() {
    const { user } = this.props.auth;
    const { createSocket } = this.props;
    if (user) {
      /*httpRequest.get("access/token").then((res) => {
        createSocket({ userIdx: user.idx, token: res.data });
      });*/
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.user !== prevProps.auth.user && this.props.auth.user) {
      const { user } = this.props.auth;
      const { createSocket } = this.props;
      /*
      httpRequest.get("access/token").then((res) => {
        createSocket({ userIdx: user.idx, token: res.data });
      });
      */
    }
  }

  /**
   * 라우팅
   * @returns
   */
  render() {
    return (
      <Switch>
        {/* 로그인 전 접근가능화면 */}
        <AppRoute exact path="/" layout={MainLayout} component={Home} />

        <AppRoute
          path="/info/planInfo"
          layout={MainLayout}
          component={PlanInfoContainer}
        />
        <AppRoute
          path="/info/planPay"
          layout={MainLayout}
          component={PlanPayContainer}
        />

        <AppRoute
          path="/info/service"
          layout={LandingLayout}
          component={Service}
        />
        <AppRoute
          path="/info/pricePolicy"
          layout={MainLayout}
          component={PricePolicy}
        />
        <AppRoute
          exact
          path="/board/news"
          layout={SubMenuLayout}
          component={BoardNewsContainer}
        />
        <AppRoute
          path="/board/news/:id"
          layout={SubMenuLayout}
          component={BoardNewsDtlContainer}
        />
        <AppRoute
          path="/board/faq"
          layout={SubMenuLayout}
          component={BoardFaqContainer}
        />
        <AppRoute
          path="/help/guides"
          layout={MainLayout}
          component={HelpGuides}
        />
        <PublicRoute
          path="/login"
          layout={MainLayout}
          component={LoginContainer}
        />
        <PublicRoute
          path="/join/form"
          layout={MainLayout}
          component={JoinContainer}
        />
        {/* 가입이메일 인증안내 */}
        <PublicRoute
          exact
          path="/join/regist"
          layout={MainLayout}
          component={RegistConfirm}
        />
        {/* 사용자 이메일 링크 클릭 시 진입 > 가입인증 성공/실패 페이지 */}
        <PublicRoute
          path="/join/regist/auth"
          layout={MainLayout}
          component={RegistResultContainer}
        />
        {/* 인증메일 재발송 */}
        <PublicRoute
          path="/sendAuthMail"
          layout={MainLayout}
          component={SendAuthMailContainer}
        />
        {/* 비밀번호 찾기 */}
        <PublicRoute
          path="/recover/send/form"
          layout={MainLayout}
          component={SendContainer}
        />
        {/* 사용자 이메일 링크 클릭 시 진입 > 비밀번호 찾기 > 비밀번호 변경 */}
        <PublicRoute
          path="/recover/regist/auth"
          layout={MainLayout}
          component={ChangeContainer}
        />
        <PublicRoute
          path="/recover/success"
          layout={MainLayout}
          component={RecoverSuccess}
        />
        {/* 에러발생 시 화면 */}
        <AppRoute path="/error" layout={MainLayout} component={Error} />

        {/* 로그인 후 접근가능화면 */}
 
        <PrivateRoute
          path="/info/corpMange"
          layout={MainLayout}
          component={CorpManageContainer}
        />

        <PrivateRoute
          path="/my/pointHistory"
          layout={MainLayout}
          component={PointHistoryContainer}
        />
        <PrivateRoute
          path="/search/patentPublication"
          layout={MainLayout}
          component={PatentPublicationContainer}
        />
        <PrivateRoute
          path="/translation/myDoc/uploader"
          layout={MainLayout}
          component={MyDocumentContainer}
        />
        <PrivateRoute
          path="/my/transHistory"
          layout={MainLayout}
          component={TransHistoryContainer}
        />
        <PrivateRoute
          path="/payment/orderPayment"
          layout={MainLayout}
          component={OrderPaymentContainer}
        />
        <PrivateRoute
          path="/payment/orderSuccess"
          layout={MainLayout}
          component={OrderSuccess}
        />
        <PrivateRoute
          path="/payment/orderFail"
          layout={MainLayout}
          component={OrderFail}
        />
        <PrivateRoute
          path="/payment/orderCancel"
          layout={MainLayout}
          component={OrderCancel}
        />
        {/* 내정보 > 회원정보/비밀번호변경/회원탈퇴 */}
        <PrivateRoute
          path="/my/userInfo"
          layout={SubMenuLayout}
          component={UserInfoContainer}
        />
        <PrivateRoute
          path="/my/changePassword"
          layout={SubMenuLayout}
          component={ChangePasswordContainer}
        />
        <PrivateRoute
          path="/my/quit"
          layout={SubMenuLayout}
          component={QuitContainer}
        />
        <PublicRoute
          path="/quit/success"
          layout={MainLayout}
          component={QuitSuccess}
        />
        <PrivateRoute
          path="/my/wishList"
          layout={MainLayout}
          component={WishListContainer}
        />
        {/* 개인정보 > 개인정보/청구현황/결제내역/캐시이용내역 */}
        <PrivateRoute
          path="/my/myInfo"
          layout={MainLayout}
          component={MyInfoContainer}
        />
        <PrivateRoute
          path="/my/orderHistory"
          layout={MainLayout}
          component={OrderHistoryContainer}
        />
        <PrivateRoute
          path="/my/cashHistory"
          layout={MainLayout}
          component={CashHistoryContainer}
        />
        <PrivateRoute
          path="/my/chargeInfo"
          layout={MainLayout}
          component={ChargeInfoContainer}
        />

        <PrivateRoute
          path="/my/usageHistory"
          layout={MainLayout}
          component={UsageHistoryContainer}
        />
        <PrivateRoute
          path="/my/planOrderPayment"
          layout={MainLayout}
          component={PlanOrderPaymentContainer}
        />
        <PrivateRoute
          path="/my/paymentHistory"
          layout={MainLayout}
          component={PaymentHistoryContainer}
        />
        <PrivateRoute
          path="/my/totalUsageHistory"
          layout={MainLayout}
          component={TotalUsageHistoryContainer}
        />

        {/* 1:1 문의 목록 */}
        <PrivateRoute
          exact
          path="/board/inquiry"
          layout={MainLayout}
          component={BoardInquiryContainer}
        />
        {/* 1:1 문의 상세 */}
        <PrivateRoute
          exact
          path="/board/inquiry/:id"
          layout={MainLayout}
          component={BoardInquiryDtlContainer}
        />
        {/* 1:1 문의 등록 */}
        <PrivateRoute
          exact
          path="/board/view/createInquiryContents"
          layout={MainLayout}
          component={CreateInquiryContentsContainer}
        />
        {/* 1:1 문의 수정 */}
        <PrivateRoute
          exact
          path="/board/boardContents/:id"
          layout={MainLayout}
          component={EditInquiryContentsContainer}
        />

        <Redirect from="*" to="/" />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};
export default connect(mapStateToProps, { createSocket })(App);
