import { commafy } from "@/util/common";
import React from "react";
import { Link } from "react-router-dom";
import "./WishList.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const WishList = ({
  wishLists,
  chosen,
  onCheckbox,
  onCheckboxAll,
  onDeleteByIds,
  onOrder,
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('storage')}</Trans></div>
      <div className="divider"></div>
      <div className="table-pagination-container">
        <div className="wish-list-control">
          <div>
            <span><Trans >{t('storedLiterature')}</Trans></span>
          </div>
          <div>
            <button
              className={`btn btn-s ${
                Object.keys(chosen).length ? "" : "disabled"
              }`}
              onClick={onDeleteByIds}
            >
              {t('delete')}
            </button>
          </div>
        </div>
        <div className="table-container pt-0">
          {" "}
          <table>
            <thead>
              <tr>
                <th></th>
                <th><Trans >{t('literatureName')}</Trans></th>
                <th><Trans >{t('traslationScope')}</Trans></th>
                <th><Trans >{t('translationType')}</Trans></th>
                <th><Trans >{t('translationStyle')}</Trans></th>
                <th><Trans >{t('characterCount')}</Trans></th>
                <th><Trans >{t('amount')}</Trans></th>
              </tr>
            </thead>
            <tbody>
              {wishLists && wishLists.length === 0 && (
                <tr>
                  <td colSpan={7}><Trans >{t('noStoredLiterature')}</Trans></td>
                </tr>
              )}
              {wishLists &&
                wishLists.length > 0 &&
                wishLists.map((post, i) => (
                  <tr key={`wishList-${i}`}>
                    <td>
                      {" "}
                      <div className="c-checkbox">
                        <label className="c-radio">
                          <input
                            type="radio"
                            name="wishResult"
                            className={"wishResult"}
                            defaultChecked={false}
                            onChange={() => onCheckbox(post, i)}
                          />
                          <span className="fa fa-check" />
                        </label>
                      </div>
                    </td>
                    <td>{post.documentName}</td>
                    <td>{t(post.transCoverageName)}</td>
                    <td>{t(post.orgLang) }   {t(post.transLang)}</td>
                    <td>{t(post.transTypeName)} </td>
                    <td>
                      {commafy(post.charCount)}{" "}
                      {post.orgLang === "EN" ? <Trans>{t('word')}</Trans> : <Trans>{t('character')}</Trans>}
                    </td>
                    <td>{commafy(post.chargePoint)} <Trans >{t('cash')}</Trans></td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="d-flex justify-content-end text-bold font-size-20 pd-20">
        <span>
        {t('estmatedZeroCash_1')}{" "}
          {commafy(
            Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
          )}{" "}
         {t('cash')}
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <button
          onClick={onOrder}
          className={`btn wish-list__btn-order ${
            Object.keys(chosen).length ? "" : "disabled"
          }`}
        >
         {t('order')}
        </button>

        <Link to="/search/patentPublication" style={{ textDecoration: "none" }}>
          <button className="btn wish-list__btn-cancel">{t('cancel')}</button>
        </Link>
      </div>
    </div>
  );
};

export default WishList;
