import React from "react";
import "./SendAuthMail.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const SendAuthMail = ({ email, password, onChangeFieldValue, onSubmit }) => {
  const { t } = useTranslation();
  return (
    <section className="d-flex flex-direction-column justify-content-center align-items-center w-two-three mg-center mt-30">
      <div className="text-bold text-md">인증메일 재발송</div>
      <div className="divider"></div>
      <form className="w-full" onSubmit={onSubmit}>
        <div className="send-auth-mail__notification">
          가입정보에 입력하셨던 <br />
          이메일과 비밀번호를 입력해 주세요
        </div>
        <div className="input-chaining mb-10">
          <input
            className="form-control"
            name="email"
            type="email"
            required
            placeholder={t('enterEmail')}
            value={email}
            onChange={onChangeFieldValue}
          />
          <div className="append">
            <span className="fas fa-envelope dummy-btn bg-w h-full" />
          </div>
        </div>
        <div className="input-chaining mb-15">
          <input
            className="form-control"
            name="password"
            type="password"
            required
            placeholder="Password"
            value={password}
            onChange={onChangeFieldValue}
          />
          <div className="append">
            <span className="fas fa-lock dummy-btn bg-w h-full" />
          </div>
        </div>

        <button className="btn w-full">
          <em className="fas fa-envelope mr-5"></em> 재발송
        </button>
      </form>
    </section>
  );
};

export default SendAuthMail;
