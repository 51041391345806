import { httpRequest } from "@/util/common";
import React, { Component } from "react";
import CreateInquiryContents from "./CreateInquiryContents";

class CreateInquiryContentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardIdx: 3,
      faqType: "",
      publishYn: "Y",
      title: "",
      contents: "",
      files: [],
      errors: {
        faqType: "",
        title: "",
        contents: "",
      },
    };
  }
  render() {
    const { boardIdx, faqType, publishYn, title, contents, files, errors } = this.state;
    return (
      <CreateInquiryContents
        boardIdx={boardIdx}
        faqType={faqType}
        publishYn={publishYn}
        title={title}
        contents={contents}
        files={files}
        errors={errors}
        onChangeFieldValue={this.handleChangeFieldValue}
        onChangeContents={this.handleChangeContents}
        onChangeFiles={this.handleChangeFiles}
        onRemoveFiles={this.handleRemoveFiles}
        onCreateInquiry={this.handleCreateInquiry}
      />
    );
  }

  /**
   * 유효성검사하기
   * @returns
   */
  validate = () => {
    const lang = localStorage.getItem("lang") || "en"; 
    const messages = {
      ko: {
        faqType: "질문유형을 선택해주세요",
        title: "제목을 입력해주세요",
        contents: "내용을 입력해주세요",
      },
      en: {
        faqType: "Please select a question type",
        title: "Please enter a title",
        contents: "Please enter the content",
      },
    };

    let validated = true;
    const errors = {};
    const { faqType, title, contents } = this.state;

    if (!faqType) {
      errors.faqType = messages[lang].faqType;;
      validated = false;
    }
    if (!title) {
      errors.title = messages[lang].title;
      validated = false;
    }
    if (!contents) {
      errors.contents = messages[lang].contents;
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * faqType,게시여부,title 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
     * 첨부파일 핸들러
     * @param {*} e
     */
  handleChangeFiles = (e) => {
    const files = [...this.state.files, e.target.files[0]];
    this.setState({ ...this.state, files });
  };

  /**
   * 첨부파일 뱃지 x버튼 핸들러
   * @param {*} file
   */
  handleRemoveFiles = (file) => {
    const files = this.state.files.filter(
      (v) => v.lastModified !== file.lastModified
    );
    this.setState({ ...this.state, files });
  };

  /**
   * 1:1문의 등록 요청하기
   */
  handleCreateInquiry = async () => {
    const lang = localStorage.getItem("lang") || "en"; 
    const messages = {
      ko: {
        charLimit: "20,000자 이하로 입력해주세요.",
        success: "정상적으로 등록되었습니다.",
        fail: "게시물 등록에 실패하였습니다.",
        noFiles: "첨부파일이 없습니다.",
      },
      en: {
        charLimit: "Please enter less than 20,000 characters.",
        success: "Successfully registered.",
        fail: "Failed to register the post.",
        noFiles: "No files attached.",
      },
    };

    const { boardIdx, faqType, publishYn, title, contents, files } = this.state;
    if (contents.length > 20000) {
      alert("20,000자 이하로 입력해주세요.");
      return;
    }
    if (this.validate()) {
      try {
        const formData = new FormData();
        formData.append("faqType", faqType);
        formData.append("boardIdx", boardIdx);
        formData.append("publishYn", publishYn);
        formData.append("title", title);
        formData.append("contents", contents);

        //게시물 등록
        const response = await httpRequest.post("board/createInquiry", formData, {
          headers: {
            "Content-Type": "application/json",
          }
        });
        console.log(response);

        if (response.status === 200){
          //첨부파일 업로드
          const data = new FormData();
          if (files.length > 0) {
            files.forEach((file) => {
              data.append("files", file);
            });
            const { data: { fileIdx, fileName } } = await httpRequest.post(`file/insertBoard`, data, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        }
       
        alert(messages[lang].success);
        this.props.history.push("/board/inquiry");
      } catch (err) {
        alert(messages[lang].fail); 
      }
    }
  };
}

export default CreateInquiryContentsContainer;
