import { httpRequest } from "@/util/common";
import { clientLogout } from "@/commonSlices/authSlice";
import { connect } from "react-redux";
import React, { Component } from "react";
import EditInquiryContents from "./EditInquiryContents";
import { withTranslation } from 'react-i18next'; 

class EditInquiryContentsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boardIdx: 3,
      faqType: "",
      publishYn: "Y",
      noticeYn: "",
      title: "",
      contents: "",
      attachFile: [],
      detachFileIdx: [],
      files: [],
      errors: {
        title: "",
        contents: "",
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    this.fetchEditInquiryContents();
  }

  render() {
    const { boardIdx, faqType, publishYn, noticeYn, title, contents, errors, attachFile, files, isLoading } = this.state;
    return (
      <EditInquiryContents
        boardIdx={boardIdx}
        faqType={faqType}
        publishYn={publishYn}
        noticeYn={noticeYn}
        title={title}
        contents={contents}
        attachFile={attachFile}
        files={files}
        errors={errors}
        isLoading={isLoading}
        onChangeFieldValue={this.handleChangeFieldValue}
        onChangeFiles={this.handleChangeFiles}
        onChangeContents={this.handleChangeContents}
        onFileDownload={this.handleFileDownload}
        onRemoveAttachFile={this.handleRemoveAttachFile}
        onEditInquiry={this.handleEditInquiry}
      />
    );
  }

  /**
   * InquiryContent 받아오기
   */
  fetchEditInquiryContents = async () => {
    const {
      match: {
        params: { id },
      },
      history,
    } = this.props;
    try {
      const {
        data: { content, attachFile },
      } = await httpRequest.get(`board/inquiry/${id}`);
      this.setState({
        ...this.state,
        boardIdx: content.boardIdx,
        faqType: content.faqType,
        publishYn: content.publishYn,
        title: content.title,
        contents: content.contents,
        attachFile,
      });
    } catch (err) {
      this.props.history("/error");
    } finally{
      this.setState({ ...this.state, isLoading: false });
    }
  };

  /**
   * 게시여부,질문제목 인풋 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  /**
   * 첨부파일 핸들러
   * @param {*} e
   */
  handleChangeFiles = (e) => {
    const files = [...this.state.files, e.target.files[0]];
    this.setState({ ...this.state, files });
  };

  /**
   * 첨부파일 뱃지 x버튼 핸들러
   * @param {*} file
   */
  handleRemoveFiles = (file) => {
    const files = this.state.files.filter(
      (v) => v.lastModified !== file.lastModified
    );
    this.setState({ ...this.state, files });
  };

  /**
   * 첨부파일 다운로드 받기
   * @param {*} fileIdx
   * @returns
   */
  handleFileDownload = async (fileIdx, orgFileName, fileName) => {
    const url = `${ip_config.baseUrl}/file/downloadBoard?requestId=${fileIdx}&fileName=${fileName}`;
    const { t } = this.props;
    try {
      const { data } = await httpRequest.get(url, { responseType: "blob" });
      const href = URL.createObjectURL(data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", orgFileName);
      document.body.append(link);
      link.click();
    } catch (err) {
      // 다운로드 실패하였습니다.
      toast.error(t('inquiryAlert1'));
    }
  };

  /**
   * 기존 첨부파일 삭제하기
   * @param {*} file
   */
  handleRemoveAttachFile = (file) => {
    const attachFile = this.state.attachFile.filter(
      (v) => v.fileIdx !== file.fileIdx
    );
    const detachFileIdx = [...this.state.detachFileIdx, file.fileIdx];
    this.setState({ ...this.state, attachFile, detachFileIdx });
  };

  /**
   * 유효성검사하기
   * @returns
   */
  validate = () => {
    let validated = true;
    const errors = {};
    const { t } = this.props;
    const { faqType, title, contents } = this.state;
    if (!faqType) {
      //질문유형을 선택해주세요.
      errors.faqType = t('inquiryAlert2');
      validated = false;
    }
    if (!title) {
      //제목을 입력해주세요
      errors.title = t('inquiryAlert3');
      validated = false;
    }
    if (!contents) {
      //내용을 입력해주세요
      errors.contents = t('inquiryAlert4');
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 게시글 수정 요청하기
   */
  handleEditInquiry = async () => {
    const { publishYn, faqType, title, contents, files, detachFileIdx } =
      this.state;
    const {
      t,
      history,
      match: {
        params: { id },
      },
    } = this.props;
 
    if (contents.length > 20000) {
      //20,000자 이하로 입력해주세요.
      alert(t('inquiryAlert5'));
      return;
    }
    if (this.validate()) {
      try {
        const formData = new FormData();
        formData.append("faqType", faqType);
        formData.append("publishYn", publishYn);
        formData.append("title", title);
        formData.append("contents", contents);
        if (detachFileIdx.length > 0){
          detachFileIdx.forEach((v) => {
            formData.append("removeFiles", v);
          });
        }else{
          formData.append("removeFiles", "");
        }
        const response = await httpRequest.put(`board/inquiry/${id}`, formData, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.data === "success") {
          //신규 파일 등록
          const fileData = new FormData();
          if (files.length > 0) {
            files.forEach((file) => {
              fileData.append("files", file);
              fileData.append("boardContentsIdx", id);
            });
            const { data: { fileIdx, fileName } } = await httpRequest.post(`file/uploadBoard`, fileData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        }
        //정상적으로 수정되었습니다.
        alert(t('inquiryAlert6'));
        history.push("/board/inquiry");
      } catch (err) {
        //게시물 수정에 실패하였습니다.
        alert(t('inquiryAlert7'));
      }
    }
  };

  /**
   * 게시글 삭제 요청하기
   */
  handleDeleteBoard = async () => {
    const { t, history } = this.props;
    const {
      params: { id },
    } = this.props.match;
    try {
      //삭제하시겠습니까?
      if (confirm(t('inquiryAlert8'))) {
        await httpRequest.delete(`board/inquiry/${id}`, { data: {} });
        //정상적으로 삭제되었습니다.
        alert(t('inquiryAlert9'));
        history.push('/board/inquiry');
      }
    } catch (err) {
      //삭제 실패하였습니다.
      alert(t('inquiryAlert10'));
    }
  };
}

export default connect(null, { clientLogout })(withTranslation()(EditInquiryContentsContainer));
