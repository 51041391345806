import { clientLogout } from "@/commonSlices/authSlice";
import { convertMonthFormat, httpRequest } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import CashHistory from "./CashHistory";

class CashHistoryContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.state = {
      pointType: "",
      targetUser: "",
      users: [],
      fromDate: d,
      endDate: new Date(),
      histories: [],
      postsPerPage: 6,
      total: 0,
      memberInfo: JSON.parse(sessionStorage.getItem('ipkk_user')),
    };
  }

  componentDidMount() {
    this.fetchCashHistory(1);
  }

  render() {
    const { pointType, fromDate, endDate, histories, postsPerPage, total, targetUser, users, memberInfo } =
      this.state;
    return (
      <CashHistory
        memberInfo={memberInfo}
        pointType={pointType}
        targetUser={targetUser}
        users={users}
        fromDate={fromDate}
        endDate={endDate}
        onChangeFieldValue={this.handleChangeFieldValue}
        setFromDate={this.setFromDate}
        setEndDate={this.setEndDate}
        currentPosts={histories}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchCashHistory}
      />
    );
  }

  /**
   * 서버에서 캐시이용내역 정보 받아오기
   */
  fetchCashHistory = async (number) => {
    const { 
      pointType, 
      fromDate, 
      endDate, 
      postsPerPage, 
      targetUser,
    } = this.state;
    const { 
      auth: {
        user: { 
          idx,
          company
        },
      }, 
      history
    } = this.props;
    try {
      const {
        data: { total, items, users },
      } = await httpRequest.get(
        `cash/history?page=${number}&size=${postsPerPage}&from=${convertMonthFormat(
          fromDate, "")}&to=${convertMonthFormat(endDate, "")}&type=${pointType}&userIdx=${idx}&targetUser=${targetUser}&company=${company}`
      );
      
      this.setState({ histories: items, total, users: users });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 포인트타입 셀렉트박스 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ endDate: date });
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(CashHistoryContainer);
