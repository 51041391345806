import React from "react";
import "./ChargeInfo.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용


const ChargeInfo = ({ togglePrice }) => {
  const { t } = useTranslation();
  return (
    <div className="charge-info">
      <div>
        <img src="/images/main/cash-image.png" alt="cash-image" />
      </div>
      <div className="ml-10 mt-10">
        <p><Trans >{t('contantInfomation')}</Trans></p>
        <p><Trans >{t('rechargeBonus')}</Trans></p>
        <button className="charge-info__btn" onClick={togglePrice}>
        {t('details')}
        </button>
      </div>
    </div>
  );
};

export default ChargeInfo;
