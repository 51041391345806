import { clientLogout } from "@/commonSlices/authSlice";
import { commafy, httpRequest } from "@/util/common";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PlanOrderPayment from "./PlanOrderPayment";

class PlanOrderPaymentContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errors: {
        hphone: "",
        email: "",
      }
    };
  }

  componentDidMount() {
    this.fetchUserInfo();
  }

  render() {
    const {
      isLoading,
      errors
    } = this.state;
    return (
        <PlanOrderPayment />
    );
  }

  /**
   * 주문페이지 진입 시 사용자정보 받아오기(이메일, 휴대전화번호, 보유포인트)
   */
  fetchUserInfo = async () => {
    const {
      auth: {
        user: { idx },
      },
      history,
    } = this.props;
    try {
      const {
        data: { mail, hphone, point },
      } = await httpRequest.get(`user/${idx}`);

      this.setState({
        hphone,
        email: mail,
        holdingPoint: point,
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 인풋 변경하기(휴대폰번호, 이메일)
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  /**
   * 인풋 변경하기(사용포인트) => 1.숫자외 입력 못함. 2.보유포인트보다 크면 보유포인트로 입력
   * @param {*} e
   * @returns
   */
  handleChangeUsePoint = (e) => {
    const { value } = e.target;
    const { holdingPoint } = this.state;
    if (isNaN(value)) {
      return;
    }
    if (value > holdingPoint) {
      this.setState({ usePoint: holdingPoint });
      return;
    }
    this.setState({ usePoint: value * 1 });
  };

  /**
   * 사용포인트 인풋 포커스 시 콤마 없애고 숫자로 바꿔주기
   */
  handleFocusUsePoint = () => {
    const { usePoint } = this.state;
    if (usePoint === 0) {
      this.setState({ usePoint: "" });
      return;
    }
    this.setState({
      usePoint: parseInt(usePoint.replace(/,/gi, "")),
    });
  };

  /**
   * 사용포인트 인풋 블러 시 결제금액(netTotalPoint, totalWithVAT) 계산하기
   * 사용포인트 <= 보유포인트
   * @param {*} e
   */
  handleBlurUsePoint = (e) => {
    const { preTotalPoint, usePoint } = this.state;
    if (usePoint === "") {
      this.setState({ usePoint: 0 });
      return;
    }
    if (usePoint > preTotalPoint) {
      this.setState({
        usePoint: commafy(preTotalPoint),
        netTotalPoint: 0,
        totalWithVAT: 0,
      });
      return;
    }
    this.setState({
      usePoint: commafy(usePoint),
      netTotalPoint: preTotalPoint - usePoint,
      totalWithVAT: Math.floor((preTotalPoint - usePoint) * 1.1),
    });
  };

  /**
   * 포인트 전액사용하기
   */
  handleUseAllPoint = () => {
    const { holdingPoint, preTotalPoint } = this.state;
    this.setState({
      usePoint: commafy(Math.min(holdingPoint, preTotalPoint)),
      netTotalPoint: preTotalPoint - Math.min(holdingPoint, preTotalPoint),
      totalWithVAT: Math.floor(
        (preTotalPoint - Math.min(holdingPoint, preTotalPoint)) * 1.1
      ),
    });
  };

  /**
   * 결제하기 요청 전 유효성검사(휴대전화번호, 이메일)
   */

  validate = () => {
    let validated = true;
    const { hphone, email } = this.state;
    const errors = {};
    const hphoneRegex =
      /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
    const emailRegex =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    /* 휴대전화번호 유효성검사 */
    if (!hphone || !hphoneRegex.test(hphone)) {
      errors.hphone = "휴대전화번호 ( - 없이 숫자만 입력).";
      validated = false;
    }

    /* 이메일 유효성검사 */
    if (!emailRegex.test(email)) {
      errors.email = "이메일 형식에 맞지 않습니다.";
      validated = false;
    }

    if (!email) {
      errors.email = "이메일을 입력해주세요";
      validated = false;
    }
    this.setState({ errors });
    return validated;
  };

  /**
   * 결제하기 버튼 클릭 시 결제요청하기
   */
  handlePayment = async () => {
    const { totalWithVAT, email, hphone, previewDocs, usePoint, transReqType } =
      this.state;
    const { history } = this.props;
    if (this.validate()) {
      this.setState({ isLoading: true });
      const orderItems = previewDocs.map((doc) => ({
        ...doc,
        transReqType, // 번역 요청 유형 (P: 특허공보, N: 사용자문서, O: 기타)
        transState: "A", // 번역 상태 (A: 번역요청, P: 진행중, E:진행완료)
      }));

      const orderData = {
        point: parseInt(usePoint && usePoint.replace(/,/gi, "")),
        price: totalWithVAT,
        orderMail: email,
        orderPhone: hphone,
        orderItems,
      };

      try {
        if (totalWithVAT === 0) {
          /** 포인트로만 결제하는 경우 */
          let orderNo;
          // my번역의 내문서번역 번역가검수
          if( orderItems[0].hasOwnProperty('transReqIdx') ) {
            const { data } = await httpRequest.post(
              "order/translate/additional/point",
              orderData
            );
            orderNo = data;
          } else {
            const { data } = await httpRequest.post(
              "order/translate/point",
              orderData
            );
            orderNo = data;
          }
          
          history.push({
            pathname: "/payment/orderSuccess",
            query: { orderNo: orderNo },
          });
          return;
        }

        /** 카드결제액이 존재하는 경우, 모듈 팝업. */
        /* 요청전문 생성 */
        const { data } = await httpRequest.post(
          "payment/translate/inicis",
          orderData
        );

        /* 이니시스 모듈 호출 */
        this.setState({ reqField: data }, () =>
          INIStdPay.pay(`SendPayForm_id`)
        );
      } catch (err) {
        history.push("/payment/orderFail");
      } finally {
        this.setState({ isLoading: false });
      }
    }
  };

  /**
   * 주문정보 서버로부터 받아오기
   * 이니시스 모듈을 그냥 닫을 경우 주문페이지로 리다이렉팅 되면서 orderNo를 쿼리스트링으로 주고, http요청하여 주문정보를 받아와서 화면에 다시 표시하기.
   */
  fetchDocsInfo = async () => {
    let orderNo = '';
    let transReqIdx = '';
    this.props.location.search && this.props.location.search.split('?')[1].split('&').forEach((data, idx) => {
      if( data.indexOf('orderNo') >= 0 ) {
        orderNo = data.split('orderNo=')[1];
      }
      if( data.indexOf('transReqIdx') >= 0 ) {
        transReqIdx = data.split('transReqIdx=')[1];
      }
    })
    if (!orderNo) return;
    try {
      const {
        data: { previewDocs, transReqType },
      } = await httpRequest(`/payment/translate/${orderNo}`);
      
      // previewDocs에 transReqIdx 넣어주기
      if( transReqIdx ) {
        previewDocs[0].transReqIdx = transReqIdx;
      }

      const preTotalPoint =
        previewDocs &&
        previewDocs.length > 0 &&
        previewDocs.reduce((a, c) => a + Number(c.chargePoint), 0);

      this.setState({
        previewDocs,
        preTotalPoint,
        usePoint: 0,
        netTotalPoint: preTotalPoint,
        totalWithVAT: Math.floor(preTotalPoint * 1.1),
        isLoading: false,
        errors: {
          hphone: "",
          email: "",
        },
        reqField: {},
        transReqType,
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      this.props.history.push(`/error?code=${err.response.status}`);
    }
  };
}
const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps, { clientLogout })(
  PlanOrderPaymentContainer
);
