import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const CreateInquiryContents = ({
  faqType,
  title,
  contents,
  files,
  errors,
  onChangeFieldValue,
  onChangeContents,
  onChangeFiles,
  onRemoveFiles,
  onCreateInquiry,
}) => {
  const fileRef = useRef();
  const { t } = useTranslation();
  return (
    <div className="card card-border-top w-full mt-20 mb-20">
      <div className="editor__header">
        {/* 1:1 문의 등록 */}
        <span>{t('inquiryRegist')}</span>
      </div>
      <div className="divider"></div>
      <div className="editor__main">
        <div className="mb-20">
          <div className="form-group board-flex-direction-row align-items-center">
            {/* 구분 */}
            <label>{t('division')}<span style={{color: 'red'}}> *</span></label>
            <select
              className="select-box"
              id="faqType"
              name="faqType"
              value={faqType}
              onChange={onChangeFieldValue}
            >
              {/* 선택해주세요 */}
              <option value="">{t('plzCheck')}</option>
              {/* 회원정보 */}
              <option value="1">{t('userInfo')}</option>
              {/* 결제/환불 */}
              <option value="2">{t('payRefune')}</option>
              {/* 시스템/에러 */}
              <option value="3">{t('systemError')}</option>
              {/* 사용법 */}
              <option value="4">{t('howToUse')}</option>
              {/* 기타 */}
              <option value="5">{t('etc')}</option>
            </select>
          </div>
          <p className="validation-message ml-90">{errors.faqType}</p>
        </div>
        <div>
          <div className="form-group board-flex-direction-row align-items-center">
            {/* 제목 */}
            <label>{t('title1')}<span style={{ color: 'red' }}> *</span></label>
            <input
              className={"form-control"}
              type="text"
              name="title"
              value={title}
              onChange={onChangeFieldValue}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.title}</p>
        </div>
        <div>
          <div className="form-group board-flex-direction-row align-items-center">
            {/* 내용 */}
            <label>{t('content')}<span style={{ color: 'red' }}> *</span></label>
            <textarea
              className={"board-form-control"}
              rows="10"
              id="contents"
              name="contents"
              value={contents}
              onChange={onChangeFieldValue}
            />
          </div>
          <p className="validation-message ml-90 mb-10">{errors.contents}</p>
        </div>
        <div className="board-attach-file">
          {" "}
          {files &&
            files.length > 0 &&
            files.map((file, i) => (
              <div
                key={`create-file-${i}`}
                className="board-attach-file__item border-warning"
              >
                {file.name}
                <em
                  className="fa fa-times ml-5"
                  onClick={() => onRemoveFiles(file)}
                ></em>
              </div>
            ))}
        </div>

        <div className="form-group board-flex-direction-row align-items-center">
          {/* 첨부파일 */}
          <label>{t('attachment')}</label>
          <input
            ref={fileRef}
            className={"form-control w-one-three"}
            type="file"
            name="file"
            style={{ display: 'none' }} 
            onChange={(e) => {
              onChangeFiles(e);
              fileRef.current.value = "";
            }}
          />
          <button
            type="button"
            onClick={() => fileRef.current.click()}
            className="btn btn-primary"
          >
            {t('selectFile')}
          </button>
        </div>
      </div>

      <div className="divider"></div>
      <div className="editor__footer">
        <div>
          <Link to="/board/inquiry">
            <button className="list-btn">
              <em className="fas fa-list-ul mr-5"></em>
              {/* 목록 */}
              <strong>{t('inventory')}</strong>
            </button>
          </Link>
        </div>

        <div>
          <button className={`btn btn-s`} onClick={onCreateInquiry}>
            <em className="fa fa-check mr-5"></em>
            {/* 등록 */}
            <strong>{t('inquiryRegist')}</strong>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateInquiryContents;
