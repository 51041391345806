import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next'; // 

const OrderSuccess = ({ history, location }) => {
  const orderNo = location.query && location.query.orderNo;
  let orderNoInicis = '';
  location.search && location.search.split('?')[1].split('&').forEach((data, idx) => {
    if( data.indexOf('orderNo') >= 0 ) {
      orderNoInicis = data.split('orderNo=')[1];
    }
  })

  useEffect(() => {
    if (!orderNo && !orderNoInicis) {
      history.push("/");
    }
  }, []);
  const { t } = useTranslation();
  return (
    <section className="result-page">
      <div className="result-page__contents">
        <div className="result-page__contents-title">
          <div className="">
            <em className="fa-5x fa fa-check" />
          </div>
          <div className="round-text-bold text-lg" style={{textAlign:"center"}}>
          <Trans >{t('AipatentTranComplet')}</Trans>
          </div>
        </div>
        <div className="result-page__contents-sub">
          <p className="text-md">{t('orderNmuber')}: {orderNo || orderNoInicis} </p>
          <p className="">
          {t('trans24complete')}
          </p>
        </div>
        <div className="result-page__contents-direction">
          <Link to={"/my/transHistory"} className="btn btn-primary btn-xxl">
          {t('myTranslation')}
          </Link>
          <Link to={"/"} className="btn btn-primary btn-xxl">
          {t('home')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OrderSuccess;
