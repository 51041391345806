import React, { Fragment } from "react";
import { commafy } from "@/util/common";
import "./MyDocument.scss";
import TransPreview from "@/commonModule/TransPreview/TransPreview";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';

const MyDocument = ({
  currentStep,
  onMoveStep,
  file,
  onDrop,
  isSpecification,
  onRemove,
  chosen,
  onClickTransType,
  selOrgLang,
  selTransLang,
  isLoading,
  errors,
  onChangeFieldValue,
  planName,
  planId,
  planUsage,
  planUsageUse,
  planCapacity
}) => {
  const { t } = useTranslation();
  return (
    <div className="page-container">
      <div className={"page__header"}>
        <div className="page__title"><Trans >{t('myDocTranslation')}</Trans></div>
        <small className="page__subtitle">
          <Trans >{t('uploadWordDoc')}</Trans>
        </small>
      </div>
      <div className={"page-contents"}>
        <div className="card">
          <div className="card__main my-document">
            <div className={"step-info-section"}>
              <div
                className={`step-info step-info-1 ${currentStep === 1 ? "active" : ""
                  }`}
                aria-invalid={false}
              >
                <div className={"step-title"}><Trans >{t('translationProcess1')}</Trans></div>
                <small><Trans >{t('translationProcessDetail1')}</Trans></small>
              </div>
              <div
                className={`step-info step-info-2 ${currentStep === 2 ? "active" : ""
                  }`}
                aria-invalid={false}
              >
                <div className={"step-title"}><Trans >{t('translationProcess2')}</Trans></div>
                <small><Trans >{t('translationProcessDetail2')}</Trans></small>
              </div>
              <div
                className={`step-info step-info-3 ${currentStep === 3 ? "active" : ""
                  }`}
                aria-invalid={false}
              >
                <div className={"step-title"}><Trans >{t('translationProcess3')}</Trans></div>
                <small><Trans >{t('translationProcessDetail3')}</Trans></small>
              </div>
            </div>
            <div className={"step-contents-wrapper"}>
              <div className={"step-contents step-1"}>
                <div
                  className={
                    "d-flex w-full justify-content-space-around align-items-center"
                  }
                >
                  <label className={"subtitle mr-30"}><Trans >{t('sourceText')}</Trans></label>
                  <label className={"subtitle"}><Trans >{t('translation')}</Trans></label>
                </div>
                <div
                  className={
                    "d-flex w-full justify-content-space-around align-items-center"
                  }
                >
                  <div className={"w-one-three"}>
                    <select
                      className="form-control"
                      id="selOrgLang"
                      name="selOrgLang"
                      aria-invalid={false}
                      aria-errormessage={"selOrgLangErr"}
                      required
                      value={selOrgLang}
                      onChange={onChangeFieldValue}
                    >
                      <option value="">{t('select')}</option>
                      <option value="KO">{t('ko')}</option>
                      <option value="EN">{t('en')}</option>
                      <option value="ZH">{t('zh')}</option>
                      <option value="JA">{t('ja')}</option>
                      {/* 일본어, 중국어 옵션추가예정 */}
                    </select>
                    <span id={"selOrgLangErr"} className={"error-message"}>
                      <Trans >{t('SelcetOriginLang')}</Trans>
                    </span>
                  </div>
                  <span className={"fa fa-arrow-circle-right direction-icon"} />
                  <div className={"w-one-three"}>
                    <select
                      className="form-control"
                      id="selTransLang"
                      name="selTransLang"
                      aria-invalid={false}
                      aria-errormessage={"selTransLangErr"}
                      required
                      value={selTransLang}
                      onChange={onChangeFieldValue}
                    >
                      <option value="">{t('select')}</option>
                      {selOrgLang === "" ? (
                        <Fragment>
                          <option id="optTransLang_KO" value="KO">
                            {t('ko')}
                          </option>
                          <option id="optTransLang_EN" value="EN">
                            {t('en')}
                          </option>
                          <option value="ZH">{t('zh')}</option>
                          <option value="JA">{t('ja')}</option>
                        </Fragment>
                      ) : selOrgLang === "KO" ? (
                        <Fragment>
                          <option id="optTransLang_EN" value="EN">
                            {t('en')}
                          </option>
                          <option id="optTransLang_ZH" value="ZH">
                            {t('zh')}
                          </option>
                          <option id="optTransLang_JA" value="JA">
                            {t('ja')}
                          </option>
                          {/* 일본어, 중국어 옵션추가예정 */}
                        </Fragment>
                      ) : (
                        <option id="optTransLang_KO" value="KO">
                          {t('ko')}
                        </option>
                      )}
                    </select>
                    <span id={"selTransLangErr"} className={"error-message"}>
                      {t('PleaseSelectTransLanguage')}
                    </span>
                  </div>
                </div>
              </div>
              <div className={"step-contents step-2"}>
                <div className="alert alert-info" role="alert">
                  <h5 className="mb-0">
                    <em className="fa fa-info-circle" />
                    <strong>
                      {" "}
                      {t('Step2OnlyTransSupport')}
                    </strong>
                  </h5>
                </div>
                {isLoading ? (
                  <div className="drop-zone">
                    <div className="loading"></div>
                  </div>
                ) : !isSpecification ? (
                  <Dropzone onDrop={onDrop} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps({
                          className: "drop-zone",
                          id: "fileDropzone",
                        })}
                      >
                        <input {...getInputProps()} required />
                        <div>
                          <em
                            id="icoCloudUpload"
                            className="fas fa-cloud-upload-alt"
                          />
                        </div>
                        <div className={"upload-info-text"}>
                          <h4>
                            <span className="text-primary"> {t('UPloadWord')}</span>{t('UploadInfoDragDrop')}
                          </h4>
                        </div>
                        <div>
                          <p>
                            <b>*.docx</b> {t('Uploaddocx')}
                          </p>
                        </div>
                        <span className={"error-message mt-5"}>
                          <Trans >{t('UploadFileSelcet')}</Trans>
                        </span>
                        {errors && (
                          <p className="validation-message mt-5">
                            {errors.step2}
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>
                ) : (
                  <div className="drop-zone">
                    <div className="drop-zone__uploaded-container">
                      <div className="drop-zone__uploaded mb-10">
                        <span className="far fa-file-word"></span>
                        <div>
                          <strong>{(file.size / 1000).toFixed(1)}</strong> KB
                        </div>
                        <div>{file.name}</div>
                      </div>
                      <span>
                        <Link to="#" onClick={onRemove}>
                          Remove File
                        </Link>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className={"step-contents step-3"}>
                <TransPreview
                  onClickTransType={onClickTransType}
                  chosen={chosen}
                  savePct={"80"}
                  planName={planName}
                  planId={planId}
                  planUsage={planUsage}
                  planCapacity={planCapacity}

                />
              </div>
            </div>
            <div className={"step-footer justify-content-end"}>
              <div className={"d-inline-flex"}>
                <button
                  id="prevStep"
                  className={`btn btn-s step-btn ${currentStep === 1 ? "disabled" : "active"
                    }`}
                  type="button"
                  onClick={onMoveStep}
                >
                  {t('prev')}
                </button>
                {currentStep !== 3 ? (
                  <button
                    id="nextStep"
                    className={`btn btn-s step-btn active ${isLoading || (currentStep === 2 && errors.step2)
                        ? "disabled"
                        : ""
                      }`}
                    type="button"
                    onClick={onMoveStep}
                  >
                    {t('next')}
                  </button>
                ) : (
                  <button
                    id="order"
                    className={"btn btn-s step-btn active"}
                    type="button"
                    onClick={onMoveStep}
                  >
                    <Link
                      style={{ textDecoration: "none" }}
                      to={{
                        pathname: "/payment/orderPayment",
                        query: {
                          chosen,
                          transReqType: "N",
                          planUsageUse:
                            (planUsage < Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
                              ? planUsage
                              : Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
                            )
                        },
                      }}
                    >
                      {t('order')}
                    </Link>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyDocument;
