import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./RegistConfirm.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const RegistConfirm = ({ location, history }) => {
  const email = location.search.split("?email=")[1];
  useEffect(() => {
    if (!email) {
      history.push("/");
    }
  }, []);
  const { t } = useTranslation();
  return (
    <section className="regist-confirm">
      <div className="regist-confirm__logo">
        <i className="fas fa-envelope"></i>
      </div>
      <div className="regist-confirm__title">{t('emailRegister')}</div>
      <div className="regist-confirm__subtitle">
        <span>{t('emailRegister1')}</span>
        <span style={{textAlign:"center"}}> {t('emailRegister2')} {email}</span>{" "}
      </div>
      <div>
        <span>
        {t('emailRegister3')}
        </span>
        <span>
          <Link
            className="text-kk text-bold"
            to={{ pathname: "/sendAuthMail", query: { email } }}
          >
            {t('emailRegister4')}
          </Link>
        </span>
      </div>
      <Link to="/" className="btn btn-xl">
        <span>{t('home')}</span>
      </Link>
    </section>
  );
};

export default RegistConfirm;
