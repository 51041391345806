import { clientLogout } from "@/commonSlices/authSlice";
import React, { Component } from "react";
import { connect } from "react-redux";
import OrderHistory from "./OrderHistory";

class OrderHistoryContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <OrderHistory
        />
    );
  }
}

export default connect(null, { clientLogout })(OrderHistoryContainer);
