import TransPreview from "@/commonModule/TransPreview/TransPreview";
import React from "react";
import { Link } from "react-router-dom";
import "./PreviewWrapper.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
const PreviewWrapper = ({
  chosen,
  onClickClosePreview,
  onClickTransType,
  onAddWishList,
  planId,
  planName,
  planUsage,
  planUsageUse,
  planCapacity,

}) => {
  const { t } = useTranslation();
  return (
    <div className={"preview-wrapper"}>
      <TransPreview
        chosen={chosen}
        savePct={"80"}
        onClickTransType={onClickTransType}
        planUsage={planUsage}
        planCapacity={planCapacity}
      />
      <div className={"preview-wrapper__append"}>
        <div className={"d-flex justify-content-end"}>
          <button className={"btn btn-s"} onClick={onAddWishList}>
            <em className="fa fa-cart-plus mr-5" />
            {t('translate98')}
          </button>
          <button className={"btn btn-s"}>
            <Link
              style={{ textDecoration: "none" }}
              to={{
                pathname: "/payment/orderPayment",
                query: {
                  chosen,
                  transReqType: "P",
                  planUsageUse:
                    (planUsage < Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
                      ? planUsage
                      : Object.values(chosen).reduce((a, c) => a + Number(c.chargePoint), 0)
                    )
                },
              }}
            >
              <em className="fa fa-check mr-5" />
              {t('translate97')}
            </Link>
          </button>
          <button
            className={"btn btn-s bg-danger"}
            onClick={onClickClosePreview}
          >
            {t('translate96')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewWrapper;
