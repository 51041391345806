import { clientLogout } from "@/commonSlices/authSlice";
import { convertDateFormat, httpRequest } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import UsageHistory from "./UsageHistory";

class UsageHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      histories: [],
      postsPerPage: 6,
      total: 0,
      userUage: [],
      userHist: [],
      userSub: [],
      userPlan: [],
      memberInfo: JSON.parse(sessionStorage.getItem('ipkk_user')),
    };
  }

  componentDidMount() {
    this.fetchUsageHistory(1);
  }

  render() {
    const { histories, postsPerPage, total, userHist, userSub, userUsage, userPlan, memberInfo } =
      this.state;
    return (
      <UsageHistory
        memberInfo={memberInfo}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={histories}
        currentHist={userHist}
        currentSub={userSub}
        currentPlan={userPlan}
        currentUsage={userUsage}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchUsageHistory}
      />
    );
  }

  /**
   * 서버에서 캐시이용내역 정보 받아오기
   */
  fetchUsageHistory = async (number) => {
    const { postsPerPage } = this.state;
    const {
      auth: {
        user: { idx, company },
      },
      history,
    } = this.props;
    try {
      const {
        data: { total, items, hist, subs, plan, usage },
      } = await httpRequest.get(
        `usage/history?page=${number}&size=${postsPerPage}&idx=${idx}&company=${company}`
      );

      this.setState({ histories: items, total, userHist: hist, userSub: subs, userPlan: plan, userUsage: usage });
      console.log(this.state);

    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 포인트타입 셀렉트박스 변경하기
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  /**
   * 조회 시작날짜 변경하기
   * @param {*} date
   */
  setFromDate = (date) => {
    this.setState({ fromDate: date });
  };

  /**
   * 조회 종료날짜 변경하기
   * @param {*} date
   */
  setEndDate = (date) => {
    this.setState({ endDate: date });
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(UsageHistoryContainer);
