import React from "react";
import { Link } from "react-router-dom";
import "./RegistSuccess.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const RegistSuccess = () => {
  const { t } = useTranslation();
  return (
    <section className="regist-success">
      <div className="regist-success__logo">
        <em className="fas fa-check"></em>
      </div>
      <div className="regist-success__title">{t('registerSuccess1')} </div>
      <div className="regist-success__subtitle">
        {t('registerSuccess2')} 
      </div>
      <Link to="/login" className="btn btn-xl">
        <span><Trans >{t('login')} </Trans></span>
      </Link>
    </section>
  );
};

export default RegistSuccess;
