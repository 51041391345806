import { clientLogout } from "@/commonSlices/authSlice";
import {  httpRequest } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import CorpMange from "./CorpMange";
import { withTranslation  } from 'react-i18next'; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class CorpManageContainer extends Component {
  constructor(props) {
    super(props);
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    this.state = {
      planType: "",
      fromDate: d,
      email: "", // 이메일 상태 추가
      emailInfo: null, // emailInfo 상태 초기화
      inviteEmail:"",
      userInfo:{},
      userMngList:[],
      userTotal:0,
      postsPerPage: 6,
      messageResult:"",
      selectCheckedIdx:"",
      selectCheckedActive:"",
    };
  }

  

  componentDidMount() {
    this.fetchUserInfo();
  }

  handleChange = (e) => {
    this.setState({ email: e.target.value }); // 이메일 입력 값 업데이트
  };

  //유저선택시 idx값 저장
  handleCheckBox = (e, idx, authName) => {
    this.setState({
      selectCheckedIdx: idx, 
      selectCheckedActive: authName,
    });
  };

  //활성화
  handleActivate = async () => {
    const {t}= this.props;

    const { selectCheckedIdx, selectCheckedActive } = this.state;
    if (!selectCheckedIdx) {
      toast(localStorage.getItem('lang') === 'ko' ? "유저를 선택해주세요." : "please choose user");
      return;
    }
    if(selectCheckedActive == "Y"){
      toast(localStorage.getItem('lang') === 'ko' ? "이미 비활성화 된 유저입니다." : "already activate user");
    }
    try{
      const response = await httpRequest.put(`/user/updateActive/${selectCheckedIdx}`,{
        idx:selectCheckedIdx,
        active:"Y",
      });
      console.log(response);
      this.fetchUserInfo();
    }catch(err){
      console.log(err);
    }
    
    // 서버로 선택된 idx를 전송
    console.log(`활성화 요청 - 선택된 유저 idx: ${selectCheckedIdx}`);
  };

  //비활성화
  handleDeactivate = async () => {
    const { selectCheckedIdx , selectCheckedActive} = this.state;
    if (!selectCheckedIdx) {
      toast(localStorage.getItem('lang') === 'ko' ? "유저를 선택해주세요." : "please choose user");
      return;
    }
    if(selectCheckedActive == "N"){
      toast(localStorage.getItem('lang') === 'ko' ? "이미 비활성화 된 유저입니다." : "already deactivate user");
    }

    try{
      const response = await httpRequest.put(`/user/updateActive/${selectCheckedIdx}`,{
        idx:selectCheckedIdx,
        active:"N",
      });
      toast(localStorage.getItem('lang') === 'ko' ? "비활성화 되었습니다." : "It has been disabled.");
      console.log(response);
      this.fetchUserInfo();
    }catch(err){
      console.log(err);
    }
    // 서버로 선택된 idx를 전송
    console.log(`비활성화 요청 - 선택된 유저 idx: ${selectCheckedIdx}`);
  };

  /**
   * 사용자정보 받아오기(이메일, 회원타입, 이름, 휴대전화번호)
   */
  fetchUserInfo = async (number) => {
    const { postsPerPage } = this.state;
    const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
    try {
      const {
        data: { items, total,},
      } = await httpRequest.post(`/user/getBusinessUser`,{
        idx:userInfo.idx,
        company:userInfo.company,
        page:number,
        size:postsPerPage
      });

      this.setState({...this.state, userMngList:items, userTotal:total})
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  handleSubmit = async (e) => {
    try {
      if(this.validateBeforeDupCheck()){
        e.preventDefault();

        const { email } = this.state;
        const {messageResult } = this.props;
        const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
        const idx = userInfo.idx;
    
        console.log("email??", { email, idx });
    
        
          const {status , data } = await httpRequest.get(`user/inviteEmail?idx=${idx}&inviteEmail=${email}&company=${userInfo.company}`);
    
          if (status !== 200) {
            toast(localStorage.getItem('lang') === 'ko' ? "이메일 전송 중 에러가 발생하였습니다." : "An error occurred while sending email.");
            throw new Error("이메일 전송 중 에러가 발생하였습니다.");
          }
          if (data === "success") {
            toast(localStorage.getItem('lang') === 'ko' ? "메일이 전송되었습니다." : "The email has been sent.");
          }
        }
      }
    catch (err) {
      console.log(err)
    }
  };

  /**
   * 
   * 이메일 유효성검사
   */
  validateBeforeDupCheck = () => {
    try{
      let validated = true;
      const errors = {};
      const { email,messageResult } = this.state;
      const emailRegex =
        /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
      // 이메일 형식 유효성검사
  
      if (!emailRegex.test(email)) {
        errors.email = "The email format does not fit.";
        this.setState({ messageResult: "The email format does not fit." });
        validated = false;
      }
  
      if (!email) {
        errors.email = "please insert Email";
        this.setState({ messageResult: "please insert Email" });
        validated = false;
      }
      this.setState({ errors }); 
      return validated;
    }catch(error){
      console.log(error);
      return false;
    }
  };

  //authName 변경시 호출
  handleSelectAuthChange = (e, i) => {
    const updatedUserMngList = [...this.state.userMngList];
    updatedUserMngList[i] = {
      ...updatedUserMngList[i],
      authName: e.target.value,
    };
    this.setState({
      userMngList: updatedUserMngList,
      selectedAuthName: e.target.value, // 선택된 authName 저장
    });
  };

  //데이터변경
  handleChangeAuth = async () => {
    const { selectCheckedIdx, selectedAuthName } = this.state;
  
    if (!selectCheckedIdx) {
      toast(localStorage.getItem('lang') === 'ko' ? "유저를 선택해주세요." : "please choose user");
      return;
    }
  
    try {
      const response = await httpRequest.put(`/user/updateAuth`, {
        idx: selectCheckedIdx,
        authName: selectedAuthName,
      });
      console.log(response);
      toast(localStorage.getItem('lang') === 'ko' ? "권한이 변경되었습니다." : "changed user auth complete");
      this.fetchUserInfo();
    } catch (err) {
      console.log("err? " + err);
    }
  
    console.log(`권한 변경 요청 - 선택된 유저 idx: ${selectCheckedIdx}`);
  };

  //유저정보 변경하기
  handleChangeUserAuth = async (e,i) => {
    try{
      const updatedUserMngList = [...this.state.userMngList];
      updatedUserMngList[i] = {
        ...updatedUserMngList[i],
        [e.target.name]: e.target.value,
      };

      this.setState({ userMngList: updatedUserMngList });

      const response = await httpRequest.put(`/user/updateAuth`,{
        idx:updatedUserMngList[i].idx,
        authName:e.target.value,
      });

      toast(localStorage.getItem('lang') === 'ko' ? "권한이 변경되었습니다." : "changed user auth complet");
      console.log(response);

    }catch(err){
      console.log("err? "  + err);
    }
    //this.fetchUserInfo();
  };



  render() {
    const { email, emailInfo,userMngList,userTotal , messageResult, selectCheckedIdx, postsPerPage } = this.state;

    // sessionStorage에서 사용자 정보 가져오기
    const userInfo = JSON.parse(sessionStorage.getItem("ipkk_user"));
    // adminYn이 "Y"인지 확인
    if (userInfo && userInfo.adminYn === "Y") {
      return (
        <CorpMange
          emailInfo={emailInfo}
          email={email}
          onEmailChange={this.handleChange}
          onSubmit={this.handleSubmit}
          fetchUserInfo={this.fetchUserInfo}
          userMngList={userMngList}
          userTotal={userTotal}
          messageResult={messageResult}
          selectCheckedIdx={selectCheckedIdx}
          handleCheckBox={this.handleCheckBox}
          handleActivate={this.handleActivate}
          handleDeactivate={this.handleDeactivate}
          validateBeforeDupCheck={this.validateBeforeDupCheck}
          handleChangeUserAuth={this.handleChangeUserAuth}
          postsPerPage={postsPerPage}
          handleSelectAuthChange={this.handleSelectAuthChange}
          handleChangeAuth ={this.handleChangeAuth }
        />
      );
    } else {
      return <div>접근 권한이 없습니다.</div>;
    }
  }
}

export default connect(null, { clientLogout })(CorpManageContainer);
