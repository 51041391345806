import React, { Component } from "react";
import MyInfo from "@/serviceModule/MyInfo/MyInfo";
import { httpRequest, convertMonthFormat } from "@/util/common";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clientLogout } from "@/commonSlices/authSlice";
import { withTranslation  } from 'react-i18next'; 

class MyInfoContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myInfo: {
        email: "",
        memberType: "" /* 1번: 개인, 2번: 기업 */,
        userName: "",
        planId: "",
        planCapacity: "",
        planUsage: "",
        planCharge: "",
        subscriptionStatus: "",
        startDate: "",
        endDate: "",
        paymentDate: "",
        payType: "",
        payTypeNm: "",
        payMethod: "",
        payMethodNm: "",
        payStatus: "",
        payStatusNm: "",
        paypalId: "",
        paypalResult: "",
        currency: "",
        planName: "",
        price: "",
        description: "",
      },
      selDate: new Date(),
      errors: { hphone: "" },
    };
  }

  componentDidMount() {
    this.fetchMyInfo(this.state.selDate);
  }

  render() {
    const { myInfo, errors, selDate, onChangeFieldValue, onSubmit } = this.state;

    return (
      <MyInfo
        myInfo={myInfo}
        selDate={selDate}
        setSelDate={this.setSelDate}
        onFetchPage={this.fetchMyInfo}
        onUnsubscribe={this.handleUnsubscribe}
        onChangePlan={this.handleChangePlan}
        onChangePayment = {this.handleChangePayment}
      />
    );
  }

  /**
   * 사용자 요금제 및 결제정보 받아오기
   */
  fetchMyInfo = async (datem) => {
    const {
      auth: {
        user: { idx },
      },
      history,
    } = this.props;
    try {
      const {
        data: { 
          email, 
          member_type, 
          user_name, 
          plan_id,
          plan_capacity,
          plan_usage,
          plan_charge,
          subscription_status,
          start_date,
          end_date,
          payment_date,
          pay_type,
          pay_type_nm,
          pay_method,
          pay_method_nm,
          pay_status,
          pay_status_nm,
          paypal_id,
          paypal_result,
          currency,
          plan_name,
          price,
          description,
        },
        
      } = await httpRequest.get(`charge/plan/${idx}/${convertMonthFormat(datem, "")}`);
      this.setState({
        ...this.state,
        myInfo: { 
          email: email,
          memberType: member_type,
          userName: user_name,
          planId: plan_id,
          planCapacity: (plan_capacity == null ? "0" : plan_capacity),
          planUsage: (plan_usage == null ? "0" : plan_usage),
          planCharge: (plan_charge == null ? "0" : plan_charge),
          subscriptionStatus: subscription_status,
          startDate: (start_date == null ? convertMonthFormat(datem, "dot") : start_date),
          endDate: end_date,
          paymentDate: payment_date,
          payType: pay_type,
          payTypeNm: pay_type_nm,
          payMethod: pay_method,
          payMethodNm: pay_method_nm,
          payStatus: pay_status,
          payStatusNm: pay_status_nm,
          paypalId: paypal_id,
          paypalResult: paypal_result,
          currency: currency,
          planName: plan_name,
          price: price,
          description: description,
        },
      });
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        this.props.clientLogout();
        return;
      }
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 유효성 검사
   * @return {boolean}
   */
  validate = () => {
    const { hphone } = this.state.myInfo;
    const { t } = this.props; 
    let validated = true;
    const errors = {};
    const hphoneRegex =
      /^((01[1|6|7|8|9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/;
    if (!hphone || !hphoneRegex.test(hphone)) {
      errors.hphone = t('EnterMobilePhoneNumber');
      validated = false;
    }
    this.setState({ ...this.state, errors });
    return validated;
  };

  /**
   * 인풋 변경하기
   * @param fieldName
   * @param value
   */
  handleChangeFieldValue = (e) => {
    this.setState({
      ...this.state,
      myInfo: { ...this.state.myInfo, [e.target.name]: e.target.value },
    });
  };

  /**
   * 조회 월 변경하기
   * @param {*} date
   */
  setSelDate = (date) => {
    this.setState({ selDate: date });
  };

  /**
  * 구독 해지
  * @param e
  */
  handleUnsubscribe = async (e) => {
    e.preventDefault();
    const { t } = this.props; 
    toast.success( t('subscriptionsucesscancell'));
    if (this.validate()) {
      const {
        auth: {
          user: { idx },
        },
      } = this.props;
      const { hphone } = this.state.chargeInfo;
      try {
        //await httpRequest.put(`charge/${idx}`, { hphone });
        toast.success(t('subscriptionsucesscancell'));
      } catch (err) {
        toast.error(t('unsubscriptionfail'));
      }
    }
  };

  /**
  * 요금제 변경
  * @param e
  */
  handleChangePlan = async (e) => {
    e.preventDefault();
    const { t } = this.props; 
    window.location.href = `/info/planInfo`;
    //toast.success(t('rateplanchangesucc'));
    if (this.validate()) {
      const {
        auth: {
          user: { idx },
        },
      } = this.props;
      const { hphone } = this.state.chargeInfo;
      try {
        //await httpRequest.put(`charge/${idx}`, { hphone });
        toast.success(t('rateplanchangesucc'));
      } catch (err) {
        toast.error(t('rateplanchangefail'));
      }
    }
  };

  /**
  * 결제정보 수정정
  * @param e
  */
  handleChangePayment = async (e) => {
    const { t } = this.props; 
    e.preventDefault();
    toast.success(t('paymentInfoModified'));
    if (this.validate()) {
      const {
        auth: {
          user: { idx },
        },
      } = this.props;
      const { hphone } = this.state.chargeInfo;
      try {
        //await httpRequest.put(`charge/${idx}`, { hphone });
        toast.success(t('paymentInfoModified'));
      } catch (err) {
        toast.error(t('paymentInfoFaile'));
      }
    }
  };
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { clientLogout })(withTranslation()(MyInfoContainer));
