import React, { Component, Fragment } from 'react';
import './css/common.scss';
import './css/colors.css';
import './css/index.scss';
import './css/typography-ltp.scss';
import './css/typography-mbl.scss';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import { Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import './css/accordion.scss';
import { connect } from 'react-redux';
import { Trans, withTranslation  } from 'react-i18next'; // i18next 사용

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.isAuthenticated
	}
}

@connect(mapStateToProps, null)
class Service extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			mode: null
		}
		
		this.setMode = this.setMode.bind(this);
		
		window.addEventListener('resize', this.setMode);
	}
	
	componentDidMount() {
		this.setMode();
	}
	
	setMode() {
		if( window.innerWidth <= 768 ) {
			this.setState({
				mode: 'sm'
			})
		} else {
			this.setState({
				mode: 'lg'
			})
		}
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.setMode);
	}
	
	render() {
		const { t } = this.props; // withTranslation HOC로 t 함수 접근
		if( !this.state.mode ) {
			return null;
		}
		return (
			<div className="service-info-page">
				<section id='section-hero'>
					<div className='container hero-section'>
						<div className={ `title-container${this.props.isAuthenticated ? ' title-container-auth' : ''}` }>
							<div className='title-box'>
								<h2 className='title display-sm'><span className='lb-mbl'><Trans >{t('aiTranslationService')}</Trans> </span><span className='lb-all'>IP KINGKONG</span></h2>
								<p className='description body-lg'><Trans >{t('differentiatedPrice')}</Trans> <span className='lb-mbl'><Trans >{t('differentiatedPrice_1')}</Trans></span></p>
							</div>
							<ul className='item-container'>
								<li className='item'>
									<h3 className='item-title title-sm'><Trans >{t('upto80')}</Trans></h3>
									<p className='item-description body-xs'><Trans >{t('lowerCost')}</Trans></p>
								</li>
								<li className='item'>
									<h3 className='item-title title-sm'><Trans >{t('twentyFourHour')}</Trans></h3>
									<p className='item-description body-xs'><Trans >{t('translationTime')}</Trans> <Trans >{t('basicProduct')}</Trans></p>
								</li>
								<li className='item'>
									<h3 className='item-title title-sm'><Trans >{t('securityReinforcement')}</Trans></h3>
									<p className='item-description body-xs'><Trans >{t('safeTranslationSecurity')}</Trans></p>
								</li>
							</ul>
							<div className='event-container'>
								{
									(!this.props.isAuthenticated && new Date() < new Date(2024, 3, 1)) && (
										<Fragment>
											<div className='text-box'>
												<p className='title card-title-sm'>가입만해도 5만 캐시를 드립니다.</p>
												<span className='description body-sm'>이벤트 기간 : ~ 2024.03.31까지</span>
											</div>
											<div className='button-box'>
												<div className='button card-title-md'>
													<Link to="/join/form" className="btn-link" target="_blank">
														5만 캐시받고 번역해보기
													</Link>
												</div>
												<span className='caption body-sm'>*5만캐시 : 명세서 기준 약 10page 번역가능 </span>
											</div>
										</Fragment>
									)
								}
								<div className="download-button card-title-sm">
									<a href="https://drive.google.com/file/d/1sixGbRoU0aLx49RGZrACHFk_j8nmCsFv/view?usp=sharing" target="_blank">{t('safeTranslationSecurity_1')}</a>
								</div>
							</div>
						</div>
						<div className='visual-container'>
							<div className='img-box'>
								<img src='/images/service/hero-visual.png' alt='특허전문 AI 번역 서비스 IP KINGKONG'/>
							</div>
						</div>
					</div>
				</section>
				<section id="section-intro">
					<div className="container intro-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><span className="lb-all"><Trans >{t('lookingForLowPrice')}</Trans> </span><Trans >{t('lookingForLowPrice_1')}</Trans></h2>
								<p className="description body-lg"><Trans >{t('differentIpkk')}</Trans></p>
							</div>
						</div>
						<div className="item-container">
							<ul className="item-wrapper">
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/intro-icon-1.png" alt="저렴하면서도 빠른 번역속도"/>
										</div>
										<div className="item-title--mbl card-title-sm"><Trans >{t('affordable')}</Trans></div>
									</div>
									<div className="item-text">
										<h3 className="item-title card-title-sm"><Trans >{t('affordable')}</Trans></h3>
										<p className="item-description body-md"><Trans >{t('receiveTime')}</Trans><Trans >{t('receiveTime_1')}</Trans><Trans >{t('receiveTime_2')}</Trans></p>
									</div>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/intro-icon-2.png" alt="특허만 학습한 고품질 번역"/>
										</div>
										<div className="item-title--mbl card-title-sm"><Trans >{t('learnedOnlyPatent')}</Trans></div>
									</div>
									<div className="item-text">
										<h3 className="item-title card-title-sm"><Trans >{t('learnedOnlyPatent')}</Trans></h3>
										<p className="item-description body-md"><Trans >{t('artificialNeural')}</Trans><Trans >{t('proofreadingNMT')}</Trans><Trans >{t('proofreadingNMT_1')}</Trans></p>
									</div>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/intro-icon-3.png" alt="번역업체 대비 50~80% 저렴"/>
										</div>
										<div className="item-title--mbl card-title-sm"><Trans >{t('cheaperTranslationCompany')}</Trans></div>
									</div>
									<div className="item-text">
										<h3 className="item-title card-title-sm"><Trans >{t('cheaperTranslationCompany')}</Trans></h3>
										<p className="item-description body-md"><Trans >{t('cheaperCommercialCompany')}</Trans><Trans >{t('affordablePrice')}</Trans></p>
									</div>
								</li>
							</ul>
						</div>
						{
							this.state.mode === 'lg' ? (
								<div className="item-card-container">
									<ul className="item-card-wrapper">
										<li className="item">
											<div className="item-header">
												<div className="img-box">
													<img src="/images/service/icons/intro-icon-4.png" alt="인커밍 / 아웃고잉을 위한 빠른 번역"/>
												</div>
											</div>
											<div className="item-text">
												<h3 className="item-title card-title-md"><Trans >{t('inComeoutGo')}</Trans></h3>
												<p className="item-description body-lg"><Trans >{t('applicationProcess')}</Trans></p>
											</div>
										</li>
										<li className="item">
											<div className="item-header">
												<div className="img-box">
													<img src="/images/service/icons/intro-icon-5.png" alt="IDS / 우선권 주장을 위한 번역문 제출시"/>
												</div>
											</div>
											<div className="item-text">
												<h3 className="item-title card-title-md"><Trans >{t('submittingTranslation')}</Trans></h3>
												<p className="item-description body-lg"><Trans >{t('reduceCostAndTime')}</Trans></p>
											</div>
										</li>
										<li className="item">
											<div className="item-header">
												<div className="img-box">
													<img src="/images/service/icons/intro-icon-6.png" alt="빠르고 좋은 번역이 필요할 때"/>
												</div>
											</div>
											<div className="item-text">
												<h3 className="item-title card-title-md"><Trans >{t('needFGTranslation')}</Trans></h3>
												<p className="item-description body-lg"><Trans >{t('unpublishedTranslation')}</Trans></p>
											</div>
										</li>
									</ul>
									<div className="pagination-container"></div>
								</div>
							) : (
								<Swiper
									slidesPerView={1.25}
									spaceBetween={20}
									centeredSlides={!0}
									modules={[Pagination]}
									pagination={{ el: '#section-intro .pagination-container' }}
									className="item-card-container"
									tag="div"
									wrapperTag="ul"
								>
									<SwiperSlide className="item" tag="li">
										<div className="item-header">
											<div className="img-box">
												<img src="/images/service/icons/intro-icon-4.png" alt="인커밍 / 아웃고잉을 위한 빠른 번역"/>
											</div>
										</div>
										<div className="item-text">
											<h3 className="item-title card-title-md"><Trans >{t('inComeoutGo')}</Trans></h3>
											<p className="item-description body-lg"><Trans >{t('applicationProcess')}</Trans></p>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="li">
										<div className="item-header">
											<div className="img-box">
												<img src="/images/service/icons/intro-icon-5.png" alt="IDS / 우선권 주장을 위한 번역문 제출시"/>
											</div>
										</div>
										<div className="item-text">
											<h3 className="item-title card-title-md"><Trans >{t('submittingTranslation')}</Trans></h3>
											<p className="item-description body-lg"><Trans >{t('reduceCostAndTime')}</Trans></p>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="li">
										<div className="item-header">
											<div className="img-box">
												<img src="/images/service/icons/intro-icon-6.png" alt="빠르고 좋은 번역이 필요할 때"/>
											</div>
										</div>
										<div className="item-text">
											<h3 className="item-title card-title-md"><Trans >{t('needFGTranslation')}</Trans></h3>
											<p className="item-description body-lg"><Trans >{t('unpublishedTranslation')}</Trans></p>
										</div>
									</SwiperSlide>
									<div className="pagination-container"></div>
								</Swiper>
							)
						}
					</div>
				</section>
				<section id="section-features">
					<div className="container features-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><Trans >{t('whyUseIpkk')}</Trans> <span className="lb-mbl"><Trans >{t('whyUseIpkk_1')}</Trans></span></h2>
								<p className="description body-lg"><Trans >{t('bleuExplanation1')}</Trans> <br/><br/><Trans >{t('bleuExplanation2')}</Trans> <br/><br/><Trans >{t('bleuExplanation3')}</Trans></p>
							</div>
						</div>
						<ul className="item-container">
							<li className="item">
								<div className="img-box">
									<img src={'/images/service/icons/feat-machine--ltp' + t('IMG_LANG') + '.png' } alt="기계평가(BLEU)"/>
								</div>
							</li>
							<li className="item">
								<div className="img-box">
									<img src={'/images/service/icons/feat-human--ltp' + t('IMG_LANG') + '.png'} alt="휴먼평가"/>
								</div>
							</li>
						</ul>

					</div>
				</section>
				<section id="section-cost">
					<div className="container cost-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><Trans>{t('rateByProduct')}</Trans></h2>
							</div>
						</div>
						{
							this.state.mode === 'lg' ? (
								<div className="table-container">
									<div className="table-header">
										<div className="item-header blank"></div>
										<div className="item-header feature-a body-md"><Trans>{t('patentAi')}</Trans></div>
										<div className="item-header feature-b body-md"><Trans>{t('humanBasicProfreading')}</Trans></div>
										<div className="item-header option-a body-md"><Trans>{t('translationExpertReview')}</Trans></div>
										<div className="item-header option-b body-md"><Trans>{t('attorneyReview')}</Trans></div>
										<div className="item-header ko-to-en body-md"><Trans>{t('krEngTranslation')}</Trans></div>
										<div className="item-header en-to-ko body-md"><Trans>{t('engKrTranslation')}</Trans></div>
										<div className="item-header ko-to-ch body-md"><Trans>{t('krCnTranslation')}</Trans></div>
										<div className="item-header ch-to-ko body-md"><Trans>{t('cnKrTranslation')}</Trans></div>
										<div className="item-header ko-to-jp body-md"><Trans>{t('krJpTranslation')}</Trans></div>
										<div className="item-header jp-to-ko body-md"><Trans>{t('jpKrTranslation')}</Trans></div>
									</div>
									<div className="table-wrapper">
										<div className="table-data">
											<div className="item-data data-header card-title-sm">BASIC</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('patentAi')}</Trans></div>
												<div className="item-data data-feature-a">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('humanBasicProfreading')}</Trans></div>
												<div className="item-data data-feature-b">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('translationExpertReview')}</Trans></div>
												<div className="item-data data-option-a">
													<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="번역 전문가 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('attorneyReview')}</Trans></div>
												<div className="item-data data-option-b">
													<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="변리사 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krEngTranslation')}</Trans></div>
													<div className="item-data data-ko-to-en card-title-md">8.2 <span
														className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('engKrTranslation')}</Trans></div>
													<div className="item-data data-en-to-ko card-title-md">14 <span
														className="sub-text body-md">{t('cashWord')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krCnTranslation')}</Trans></div>
													<div className="item-data data-ko-to-ch card-title-md">10 <span
														className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('cnKrTranslation')}</Trans></div>
													<div className="item-data data-ch-to-ko card-title-md">8 <span className="sub-text body-md">{t('cashCharacter')}</span>
													</div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krJpTranslation')}</Trans></div>
													<div className="item-data data-ko-to-jp card-title-md">6 <span className="sub-text body-md">{t('cashCharacter')}</span>
													</div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('jpKrTranslation')}</Trans></div>
													<div className="item-data data-jp-to-ko card-title-md">5 <span className="sub-text body-md">{t('cashCharacter')}</span>
													</div>
												</div>
											</div>
										</div>
										<div className="table-data">
											<div className="item-data data-header card-title-sm">SPECIAL</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('patentAi')}</Trans></div>
												<div className="item-data data-feature-a">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('humanBasicProfreading')}</Trans></div>
												<div className="item-data data-feature-b">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('translationExpertReview')}</Trans></div>
												<div className="item-data data-option-a">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="번역 전문가 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('attorneyReview')}</Trans></div>
												<div className="item-data data-option-b">
													<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="변리사 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krEngTranslation')}</Trans></div>
													<div className="item-data data-ko-to-en card-title-md">28.2 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('engKrTranslation')}</Trans></div>
													<div className="item-data data-en-to-ko card-title-md">56 <span className="sub-text body-md">{t('cashWord')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krCnTranslation')}</Trans></div>
													<div className="item-data data-ko-to-ch card-title-md">35 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('cnKrTranslation')}</Trans></div>
													<div className="item-data data-ch-to-ko card-title-md">29 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krJpTranslation')}</Trans></div>
													<div className="item-data data-ko-to-jp card-title-md">19 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('jpKrTranslation')}</Trans></div>
													<div className="item-data data-jp-to-ko card-title-md">16 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
										</div>
										<div className="table-data p-relative">
											<div className="item-data data-header card-title-sm">PREMIUM</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('patentAi')}</Trans></div>
												<div className="item-data data-feature-a">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('humanBasicProfreading')}</Trans></div>
												<div className="item-data data-feature-b">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('translationExpertReview')}</Trans></div>
												<div className="item-data data-option-a">
													<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="번역 전문가 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper">
												<div className="item-title body-md"><Trans>{t('attorneyReview')}</Trans></div>
												<div className="item-data data-option-b">
													<div className="img-box"><img src="/images/service/icons/check.png" alt="변리사 리뷰"/></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krEngTranslation')}</Trans></div>
													<div className="item-data data-ko-to-en card-title-md">46 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('engKrTranslation')}</Trans></div>
													<div className="item-data data-en-to-ko card-title-md">91 <span className="sub-text body-md">{t('cashWord')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krCnTranslation')}</Trans></div>
													<div className="item-data data-ko-to-ch card-title-md">63 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('cnKrTranslation')}</Trans></div>
													<div className="item-data data-ch-to-ko card-title-md">49 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('krJpTranslation')}</Trans></div>
													<div className="item-data data-ko-to-jp card-title-md">34 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="item-wrapper wrapper-box">
												<div className="inner-wrapper">
													<div className="item-title body-md"><Trans>{t('jpKrTranslation')}</Trans></div>
													<div className="item-data data-jp-to-ko card-title-md">32 <span className="sub-text body-md">{t('cashCharacter')}</span></div>
												</div>
											</div>
											<div className="alert-message-no-service"><Trans>{t('preparationService')}</Trans></div>
										</div>
									</div>
								</div>
							) : (
								<Swiper
									slidesPerView={1.05}
									spaceBetween={20}
									modules={[Pagination]}
									pagination={{ el: '#section-cost .pagination-container' }}
									className="table-container"
									tag="div"
									wrapperTag="div"
								>
									<SwiperSlide className="table-data" tag="div">
										<div className="item-data data-header card-title-sm">BASIC</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('patentAi')}</Trans></div>
											<div className="item-data data-feature-a">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('humanBasicProfreading')}</Trans></div>
											<div className="item-data data-feature-b">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('translationExpertReview')}</Trans></div>
											<div className="item-data data-option-a">
												<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="번역 전문가 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('attorneyReview')}</Trans></div>
											<div className="item-data data-option-b">
												<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="변리사 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krEngTranslation')}</Trans></div>
												<div className="item-data data-ko-to-en card-title-md">8.2 <span
													className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('engKrTranslation')}</Trans></div>
												<div className="item-data data-en-to-ko card-title-md">14 <span
													className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krCnTranslation')}</Trans></div>
												<div className="item-data data-ko-to-ch card-title-md">10 <span
													className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('cnKrTranslation')}</Trans></div>
												<div className="item-data data-ch-to-ko card-title-md">8 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span>
												</div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krJpTranslation')}</Trans></div>
												<div className="item-data data-ko-to-jp card-title-md">6 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span>
												</div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('jpKrTranslation')}</Trans></div>
												<div className="item-data data-jp-to-ko card-title-md">5 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span>
												</div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide className="table-data" tag="div">
										<div className="item-data data-header card-title-sm">SPECIAL</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('patentAi')}</Trans></div>
											<div className="item-data data-feature-a">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('humanBasicProfreading')}</Trans></div>
											<div className="item-data data-feature-b">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('translationExpertReview')}</Trans></div>
											<div className="item-data data-option-a">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="번역 전문가 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('attorneyReview')}</Trans></div>
											<div className="item-data data-option-b">
												<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="변리사 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krEngTranslation')}</Trans></div>
												<div className="item-data data-ko-to-en card-title-md">28.2 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('engKrTranslation')}</Trans></div>
												<div className="item-data data-en-to-ko card-title-md">56 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krCnTranslation')}</Trans></div>
												<div className="item-data data-ko-to-ch card-title-md">35 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('cnKrTranslation')}</Trans></div>
												<div className="item-data data-ch-to-ko card-title-md">29 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krJpTranslation')}</Trans></div>
												<div className="item-data data-ko-to-jp card-title-md">19 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('jpKrTranslation')}</Trans></div>
												<div className="item-data data-jp-to-ko card-title-md">16 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
									</SwiperSlide>
									<SwiperSlide className="table-data p-relative" tag="div">
										<div className="item-data data-header card-title-sm">PREMIUM</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('patentAi')}</Trans></div>
											<div className="item-data data-feature-a">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="특허전문 AI 번역"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('humanBasicProfreading')}</Trans></div>
											<div className="item-data data-feature-b">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="휴먼 기본보정"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('translationExpertReview')}</Trans></div>
											<div className="item-data data-option-a">
												<div className="img-box"><img src="/images/service/icons/uncheck.png" alt="번역 전문가 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-title body-md"><Trans>{t('attorneyReview')}</Trans></div>
											<div className="item-data data-option-b">
												<div className="img-box"><img src="/images/service/icons/check.png" alt="변리사 리뷰"/></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krEngTranslation')}</Trans></div>
												<div className="item-data data-ko-to-en card-title-md">46 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('engKrTranslation')}</Trans></div>
												<div className="item-data data-en-to-ko card-title-md">91 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krCnTranslation')}</Trans></div>
												<div className="item-data data-ko-to-ch card-title-md">63 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('cnKrTranslation')}</Trans></div>
												<div className="item-data data-ch-to-ko card-title-md">49 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('krJpTranslation')}</Trans></div>
												<div className="item-data data-ko-to-jp card-title-md">34 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="item-wrapper wrapper-box">
											<div className="inner-wrapper">
												<div className="item-title body-md"><Trans>{t('jpKrTranslation')}</Trans></div>
												<div className="item-data data-jp-to-ko card-title-md">32 <span className="sub-text body-md"><Trans>{t('cashCharacter')}</Trans></span></div>
											</div>
										</div>
										<div className="alert-message-no-service"><Trans>{t('preparationService')}</Trans></div>
									</SwiperSlide>
									<div className="pagination-container"></div>
								</Swiper>
							)
						}
					</div>
				</section>
				<section id="section-compare">
					<div className="container compare-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><Trans>{t('comparisonCost')}</Trans></h2>
								<p className="description body-lg"><Trans>{t('applyComparisonCost')}</Trans></p>
							</div>
						</div>
						{
							this.state.mode === 'lg' ? (
								<div className="item-container slide-container">
									<div className="table-header">
										<h3 className="item-header card-title-sm"></h3>
										<h3 className="item-header card-title-sm"><Trans>{t('standardWord')}</Trans></h3>
										<h3 className="item-header card-title-sm"><Trans>{t('CommercialCompany')}</Trans></h3>
										<h3 className="item-header card-title-sm item-active"><Trans>{t('ipkk_1')}</Trans></h3>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md">
												<span className="data-type"><Trans>{t('krEngTranslation')}</Trans></span>
											</p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md"><Trans>{t('baseOnWords')}</Trans></p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">{t('SERV10011')}<span className="body-md">{t('SERV10001')}</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">{t('SERV10002')} {t('SERV10012')}<span className="body-lg">{t('SERV10001')}</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type"><Trans>{t('engKrTranslation')}</Trans></span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md"><Trans>{t('baseOnWords14146')}</Trans></p>
											<div className="data-summary body-sm">(12pt / 1,630 line / 60 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">{t('SERV10021')}<span className="body-md">{t('SERV10001')}</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">{t('SERV10002')} {t('SERV10022')}<span className="body-lg">{t('SERV10001')}</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type"><Trans>{t('krCnTranslation')}</Trans></span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md"><Trans>{t('baseOnWords')}</Trans></p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">{t('SERV10031')}<span className="body-md">{t('SERV10001')}</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">{t('SERV10002')} {t('SERV10032')}<span className="body-lg">{t('SERV10001')}</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type"><Trans>{t('cnKrTranslation')}</Trans></span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md"><Trans>{t('baseOnWords16814')}</Trans></p>
											<div className="data-summary body-sm">(12pt / 600 line / 34 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">{t('SERV10041')}<span className="body-md">{t('SERV10001')}</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">{t('SERV10002')} {t('SERV10042')}<span className="body-lg">{t('SERV10001')}</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type"><Trans>{t('krJpTranslation')}</Trans></span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md"><Trans>{t('baseOnWords')}</Trans></p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">{t('SERV10051')}<span className="body-md">{t('SERV10001')}</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">{t('SERV10002')} {t('SERV10052')}<span className="body-lg">{t('SERV10001')}</span></p>
										</div>
									</div>
									<div className="table-data">
										<div className="data">
											<p className="data-title body-md"><span className="data-type"><Trans>{t('jpKrTranslation')}</Trans></span></p>
										</div>
										<div className="data description-box">
											<p className="data-description body-md"><Trans>{t('baseOnWords8406')}</Trans></p>
											<div className="data-summary body-sm">(12pt / 631 line / 25 page)</div>
										</div>
										<div className="data">
											<p className="data-price-other card-title-sm">{t('SERV10061')}<span className="body-md">{t('SERV10001')}</span></p>
										</div>
										<div className="data">
											<p className="data-price-our card-title-md">{t('SERV10002')} {t('SERV10062')}<span className="body-lg">{t('SERV10001')}</span></p>
										</div>
									</div>
									<p className="item-description-notice body-md"><Trans>{t('costPerPage')}</Trans><Trans>{t('krEngTranslationAbout6000')}</Trans><Trans>{t('krCnTranslationAbout7500')}</Trans><Trans>{t('krJpTranslationAbout4500')}</Trans></p>{/* MOBILE ITEM STRUCTURE */}
								</div>
							) : (
								<Swiper
									slidesPerView={1.05}
									spaceBetween={20}
									modules={[Pagination]}
									pagination={{ el: '#section-compare .pagination-container' }}
									className="item-container slide-container"
									tag="div"
									wrapperTag="div"
								>
									<SwiperSlide className="item" tag="div">
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg"><span className="data-type"><Trans>{t('krEngTranslation')}</Trans></span>
												</h3>
												<div className="item-description-box">
													<p className="item-description"><Trans>{t('baseOnWords')}</Trans></p><span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span><span className="other-price">50~60</span><span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg"><Trans>{t('ipkk_1')}</Trans></span>
													<span className="our-price body-lg">약 15</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg"><span className="data-type"><Trans>{t('engKrTranslation')}</Trans></span></h3>
												<div className="item-description-box">
													<p className="item-description"><Trans>{t('baseOnWords14146')}</Trans></p>
													<span className="item-summary">(12pt / 1,630 line / 60 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">100~140</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg"><Trans>{t('ipkk_1')}</Trans></span>
													<span className="our-price body-lg">약 20</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-text-box">
											<p className="item-description body-md">한→영 시 1페이지당 약 6,000원</p>
											<span className="item-caption body-sm">K-editor 1페이지 기준<br/>바탕체 12pt, 페이지당 20line</span>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="div">
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type"><Trans>{t('krCnTranslation')}</Trans></span>
												</h3>
												<div className="item-description-box">
													<p className="item-description"><Trans>{t('baseOnWords')}</Trans></p>
													<span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">35~45</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg"><Trans>{t('ipkk_1')}</Trans></span>
													<span className="our-price body-lg">약 11</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type"><Trans>{t('cnKrTranslation')}</Trans></span>
												</h3>
												<div className="item-description-box">
													<p className="item-description"><Trans>{t('baseOnWords16814')}</Trans></p>
													<span className="item-summary">(12pt / 600 line / 34 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">40~50</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg"><Trans>{t('ipkk_1')}</Trans></span>
													<span className="our-price body-lg">약 9</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-text-box">
											<p className="item-description body-md">한→중 시 1페이지당 약 7,500원</p>
											<span className="item-caption body-sm">K-editor 1페이지 기준<br/>바탕체 12pt, 페이지당 20line</span>
										</div>
									</SwiperSlide>
									<SwiperSlide className="item" tag="div">
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type"><Trans>{t('krJpTranslation')}</Trans></span>
												</h3>
												<div className="item-description-box">
													<p className="item-description"><Trans>{t('baseOnWords')}</Trans></p>
													<span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">70~80</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg"><Trans>{t('ipkk_1')}</Trans></span>
													<span className="our-price body-lg">약 18</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-wrapper">
											<div className="item-header">
												<h3 className="item-title body-lg">
													<span className="data-type"><Trans>{t('jpKrTranslation')}</Trans></span>
												</h3>
												<div className="item-description-box">
													<p className="item-description"><Trans>{t('baseOnWords8406')}</Trans></p>
													<span className="item-summary">(12pt / 631 line / 25 page)</span>
												</div>
											</div>
											<div className="item-content-box">
												<div className="item-content left-content">
													<span className="label body-sm">시중업체</span>
													<span className="other-price">40~50</span>
													<span className="body-sm">만원</span>
												</div>
												<div className="img-box">
													<img src="/images/service/icons/arrow-lg.png" alt="arrow"/>
												</div>
												<div className="item-content right-content">
													<span className="label body-lg"><Trans>{t('ipkk_1')}</Trans></span>
													<span className="our-price body-lg">약 9</span>
													<span className="tag body-sm">만원</span>
												</div>
											</div>
										</div>
										<div className="item-text-box">
											<p className="item-description body-md">한→일 시 1페이지당 약 4,500원</p>
											<span className="item-caption body-sm">K-editor 1페이지 기준<br/>바탕체 12pt, 페이지당 20line</span>
										</div>
									</SwiperSlide>
									<div className="pagination-container"></div>
								</Swiper>
							)
						}
					</div>
				</section>
				<section id="section-process">
					<div className="container process-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><Trans>{t('costEstimated')}</Trans></h2>
								<p className="description body-lg"><Trans>{t('checkCostEstimated')}</Trans></p>
							</div>
						</div>
						<div className="item-container">
							<div className="arrow-box">
								<div className="img-box">
									<img src="/images/service/icons/arrow-rounded.png" alt="프로세스 화살표"/>
								</div>
								<div className="img-box">
									<img src="/images/service/icons/arrow-rounded.png" alt="프로세스 화살표"/>
								</div>
								<div className="img-box">
									<img src="/images/service/icons/arrow-rounded.png" alt="프로세스 화살표"/>
								</div>
							</div>
							<ul className="item-wrapper">
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-1.png" alt="번역언어 선택"/>
										</div>
									</div>
									<h3 className="item-title card-title-md"><Trans>{t('seelectTrans')}</Trans></h3>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-2.png" alt="문서 업로드 (*.docx)"/>
										</div>
									</div>
									<h3 className="item-title card-title-md"><Trans>{t('uploadDoc')}</Trans></h3>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-3.png" alt="번역상품 선택"/>
										</div>
									</div>
									<h3 className="item-title card-title-md"><Trans>{t('selectTranslationProduct')}</Trans></h3>
								</li>
								<li className="item">
									<div className="item-header">
										<div className="img-box">
											<img src="/images/service/icons/process-icon-4.png" alt="번역비용 확인"/>
										</div>
									</div>
									<h3 className="item-title card-title-md"><Trans>{t('checkTranslationCost')}</Trans></h3>
								</li>
							</ul>
						</div>
						<div className="caption-box">
							<p className="caption body-md"><Trans>{t('chooseLanguage')}</Trans><Trans>{t('replyCost')}</Trans></p>
						</div>
					</div>
				</section>
				<section id="section-faq">
					<div className="container faq-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><Trans>{t('faq')}</Trans></h2>
							</div>
						</div>
						<Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<Trans>{t('howToRechargeCash')}</Trans>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg"><Trans>{t('howToRechargeCash1')}</Trans><span className="caption body-md"><Trans>{t('howToRechargeCash1_1')}</Trans></span></p>
										<p className="description body-lg"><Trans>{t('howToRechargeCash2')}</Trans><span className="caption body-md"><Trans>{t('howToRechargeCash2_1')}</Trans> <Trans>{t('howToRechargeCash2_2')}</Trans> <Trans>{t('howToRechargeCash2_3')}</Trans> <Trans>{t('howToRechargeCash2_4')}</Trans> </span></p>
										<p className="description body-lg"><Trans>{t('howToRechargeCash3')}</Trans></p>
										<div className="img-box">
											<img src={ this.state.mode === 'lg' ? '/images/service/table'+ t('IMG_LANG') + '.webp' : '/images/service/table--mbl.webp' } style={{ width: '100%' }} alt="충전캐시에 따른 총 결제금액 및 추가캐시 안내"/>
										</div>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<Trans>{t('useToPreRecharge')}</Trans>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg"><Trans>{t('cardPay')}</Trans><Trans>{t('cashBonus')}</Trans></p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<Trans>{t('langSupport')}</Trans>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg"><Trans>{t('langList')}</Trans><Trans>{t('multilingualExpansion')}</Trans></p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<Trans>{t('specificationAvailability')}</Trans>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg"><Trans>{t('specializationTranslation')}</Trans><Trans>{t('Expansion')}</Trans></p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										<Trans>{t('Inquiry')}</Trans>
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<div className="item-content-box">
										<p className="description body-lg"><Trans>{t('Contact')}</Trans><Trans>{t('Response')}</Trans></p>
									</div>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
					</div>
				</section>
				<section id="section-evidence">
					<div className="container evidence-section">
						<div className="title-container">
							<div className="title-box">
								<h2 className="title title-md"><Trans>{t('Announcement')}</Trans></h2>
							</div>
						</div>
						<ul className="item-container">
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-1.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans>{t('Upgrade')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('Quality')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.datanet.co.kr/news/articleView.html?idxno=179483" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-2.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('ipkkLaunch')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('ipkkLaunchDetail')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="http://www.it-b.co.kr/news/articleView.html?idxno=31411" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews1')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail1')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/09/20/06/24/36/22172/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-apple-%ec%a0%9c%ec%8a%a4%ec%b2%98%eb%a1%9c-%ec%a1%b0%ec%a0%95%ed%95%98%eb%8a%94-%ec%9e%90%ec%9c%a8%ec%b0%a8-%eb%aa%a9%ec%a0%81%ec%a7%80-%ec%95%88%eb%82%b4%ec%8b%9c/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews2')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail2')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/05/01/08/02/09/20129/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%94%94%ec%a6%88%eb%8b%88-%ed%85%8c%eb%a7%88%ed%8c%8c%ed%81%ac-%ea%b3%b5%ea%b0%84-%eb%82%b4-%ea%b0%80%ec%83%81-%ec%84%b8%ea%b3%84-%ed%8a%b9%ed%97%88/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews3')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail3')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/08/14/11/52/01/21740/ai-%eb%b2%88%ec%97%ad-%ed%98%84%eb%8c%80%ec%b0%a8-%ec%a0%9c%ec%9e%90%eb%a6%ac-%ec%9c%a0%ed%84%b4%ec%9d%b4-%ea%b0%80%eb%8a%a5%ed%95%9c-4%eb%a5%9c-%ec%a1%b0%ed%96%a5%ec%9e%a5%ec%b9%98-%ed%8a%b9/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews4')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail4')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/04/01/15/12/03/19529/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-apple-%ea%b4%91%ed%88%ac%ea%b3%bc%ec%9c%a8-%eb%b3%80%ed%95%98%eb%8a%94-%ea%b0%80%eb%b3%80-%eb%b6%88%ed%88%ac%eb%aa%85-%ec%84%a0%eb%a3%a8%ed%94%84-%ed%8a%b9/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews5')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail5')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/07/30/18/19/22/21599/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%94%94%ec%a6%88%eb%8b%88-%eb%86%80%ec%9d%b4%ea%b8%b0%ea%b5%ac-%eb%aa%a8%ec%96%91%ec%9d%84-%eb%b0%94%ea%be%b8%eb%8a%94-%ec%b0%a8%eb%9f%89-%eb%a6%ac%ec%85%8b/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews6')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail6')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/02/20/10/42/28/19223/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-apple-%eb%a7%90%eb%a6%ac%eb%8a%94-%eb%a1%a4%eb%9f%ac%eb%b8%94-%eb%94%94%ec%8a%a4%ed%94%8c%eb%a0%88%ec%9d%b4-%ed%8a%b9%ed%97%88/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews7')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail7')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/05/07/16/46/21/20279/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%a9%94%ed%83%80meta-%ea%b3%b5%ec%83%81-%ea%b3%bc%ed%95%99-%ec%86%8c%ec%84%a4%ec%9d%98-3d-%eb%8c%80%ed%99%94-%ed%8a%b9%ed%97%88/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews8')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail8')}</Trans></p>
								</div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/02/18/11/01/59/19148/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%ec%9d%b4%eb%b2%a0%ec%9d%b4ebay-%ec%9e%90%eb%8f%99-%ed%8f%ac%ec%8a%a4%ed%8c%85-%ec%8b%9c%ec%8a%a4%ed%85%9c-%ed%8a%b9%ed%97%88%ec%99%84/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
							<li className="item">
								<div className="item-header">
									<div className="img-box">
										<img src="/images/service/icons/logo-3.png" alt="뉴스기사"/>
									</div>
								</div>
								<div className="item-text">
									<h3 className="item-title card-title-md"><Trans >{t('aiTranslationNews9')}</Trans></h3>
									<p className="item-description body-md"><Trans >{t('aiTranslationNewsDetail9')}</Trans></p></div>
								<div className="item-footer">
									<a className="body-sm" href="https://www.ipdaily.co.kr/2022/04/16/21/07/12/19673/ai-%eb%b2%88%ec%97%ad-%e7%be%8e-%eb%a9%94%ed%83%80meta-%ea%b0%80%ec%83%81-%ed%98%84%ec%8b%a4-%ed%98%84%ec%8b%a4-%ec%b6%a9%eb%8f%8c-%ed%9a%8c%ed%94%bc-%ed%8a%b9%ed%97%88/" target="_blank"><Trans >{t('newsDetail')}</Trans></a>
								</div>
							</li>
						</ul>
					</div>
				</section>
				{
					!this.props.isAuthenticated && (
						<section id='section-promotion'>
							<div className='container promotion-section'>
								<div className='title-container'>
									<div className='title-box'>
										<h2 className='title title-md'><Trans >{t('experienceYourself')}</Trans></h2>
									</div>
									<div className='button-box'>
										<p className='description body-lg'><Trans >{t('signUpBonus')}</Trans></p>
										<div className='button card-title-md'>
											<Link to="/join/form" className="btn-link" target="_blank">
											<Trans >{t('signUpNow')}</Trans>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</section>
					)
				}
			</div>
		);
	}
}

export default withTranslation()(Service);