import React, { useEffect } from "react";
import { scrollMove } from "@/util/common";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Help = () => {

    const { t } = useTranslation();
    useEffect(() => {
        window.scrollTo(0, 0); 
    }, []);

    return (
        <div className="page-container">
            <div className="manual-wrap">
                <div className="manual-index-wrap">
                    <ul className="manual-index">
                        <li><p className="tit">{t('HELP00000')}</p></li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p1')}>1. {t('HELP10000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p1-1')}>1.1. {t('HELP11000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p1-2')}>1.2. {t('HELP12000')}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p2')}>2. {t('HELP20000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p2-1')}>2.1. {t('HELP21000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p2-2')}>2.2. {t('HELP22000')}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p3')}>3. {t('HELP30000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p3-1')}>3.1. {t('HELP31000')} </a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p3-2')}>3.2. {t('HELP32000')} </a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p3-3')}>3.3. {t('HELP33000')} </a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p3-4')}>3.4. {t('HELP34000')} </a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p4')}>4. {t('HELP40000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p4-1')}>4.1. {t('HELP41000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p4-2')}>4.2. {t('HELP42000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p4-3')}>4.3. {t('HELP43000')}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p5')}>5. {t('HELP50000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p5-1')}>5.1. {t('HELP51000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p5-2')}>5.2. {t('HELP52000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p5-3')}>5.3. {t('HELP53000')}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p6')}>6. {t('HELP60000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p6-1')}>6.1. {t('HELP61000')}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p7')}>7. {t('HELP71000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p7-1')}>7.1. {t('HELP71000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p7-2')}>7.2. {t('HELP72000')}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p8')}>8. {t('HELP80000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p8-1')}>8.1. {t('HELP81000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p8-2')}>8.2. {t('HELP82000')}</a></li>
                            </ul>
                        </li>
                        <li>
                            <a href="javascript:;" onClick={() =>scrollMove('p9')}>9. {t('HELP90000')}</a>
                            <ul>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p9-1')}>9.1. {t('HELP91000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p9-2')}>9.2. {t('HELP92000')}</a></li>
                                <li><a href="javascript:;" onClick={() =>scrollMove('p9-3')}>9.3. {t('HELP93000')}</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="manual-contents-wrap">
                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p1">1. {t('HELP10000')}</h3>
                            <h4 id="p1-1">1.1. {t('HELP11000')}</h4>
                            <div className="manual-info-box">
                                <table>
                                    <colgroup>
                                        <col style={{width: '12%'}} />
                                        <col style={{width: '12%'}} />
                                        <col style={{width: '76%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th colspan="2">{t('HELP11001')}</th>
                                            <th>{t('HELP11002')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th rowspan="2">{t('HELP11003')}</th>
                                            <th>{t('HELP11100')}</th>
                                            <td>
                                                {t('HELP11110')} <br />
                                                {t('HELP11120')} <br />
                                                {t('HELP11130')}<br />
                                                {t('HELP11140')}<br />
                                                {t('HELP11150')}<br />
                                                {t('HELP11160')}
                                    </td>
                                        </tr>
                                        <tr>
                                            <th>{t('HELP11200')}</th>
                                            <td>
                                                {t('HELP11210')} <br />
                                                {t('HELP11220')} <br />
                                                {t('HELP11230')}<br />
                                                {t('HELP11240')}<br />
                                                {t('HELP11250')}<br />
                                    </td>
                                        </tr>
                                        <tr>
                                            <th colspan="2">{t('HELP11300')}</th>
                                            <td>
                                                {t('HELP11310')} <br />
                                                {t('HELP11320')} <br />
                                                {t('HELP11330')}<br />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p1-2">1.2. {t('HELP12000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u00" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP12100')}</li>
                                        <li>{t('HELP12200')}</li>
                                        <li>{t('HELP12300')}</li>
                                        <li>
                                        {t('HELP12400')}
                                    <ul className="d-list">
                                                <li>{t('HELP12410')}</li>
                                                <li>{t('HELP12420')}</li>
                                            </ul>
                                </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p2">2. {t('HELP20000')}</h3>
                            <h4 id="p2-1">2.1. {t('HELP21000')}</h4>
                            <div className="manual-info-box">
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>
                                        {t('HELP21100')}
                                    <ul className="d-list">
                                                <li>{t('HELP21110')}</li>
                                                <li>{t('HELP21120')}</li>
                                                <li>{t('HELP21130')}</li>
                                            </ul>
                                </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p2-2">2.2. {t('HELP22000')}</h4>
                            <div className="manual-info-box">
                                <table>
                                    <colgroup>
                                        <col style={{width: '12%'}} />
                                        <col style={{width: '88%'}} />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>{t('HELP22001')}</th>
                                            <th>{t('HELP22002')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>{t('HELP22100')}</th>
                                            <td>
                                                <ol className="n-list">
                                                    <li>{t('HELP22110')}</li>
                                                    <li>{t('HELP22120')}</li>
                                                    <li>{t('HELP22130')}</li>
                                                    <li>{t('HELP22140')}</li>
                                                </ol>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('HELP22200')}</th>
                                            <td>
                                                <ol className="n-list">
                                                    <li>{t('HELP22210')}</li>
                                                    <li>{t('HELP22220')}</li>
                                                    <li>{t('HELP22230')}</li>
                                                </ol>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('HELP22300')}</th>
                                            <td>
                                                <ol className="n-list">
                                                    <li>{t('HELP22310')}</li>
                                                    <li>{t('HELP22320')}</li>
                                                </ol>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p3">3. {t('HELP30000')}</h3>
                            <h4 id="p3-1">3.1. {t('HELP31000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u01" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP31100')}</li>
                                        <li>
                                        {t('HELP31200')}
                                    <ul className="d-list">
                                                <li>{t('HELP31210')}</li>
                                                <li>{t('HELP31220')}</li>
                                            </ul>
                                        </li>
                                        <li>{t('HELP31300')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p3-2">3.2. {t('HELP32000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u02" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP32100')}</li>
                                        <li>
                                        {t('HELP32200')}
                                    <ul className="d-list">
                                                <li>{t('HELP32210')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p3-3">3.3. {t('HELP33000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u03" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP34100')}</li>
                                        <li>
                                        {t('HELP34200')}
                                    <ul className="d-list">
                                                <li>{t('HELP34210')}</li>
                                            </ul>
                                        </li>
                                        <li>
                                        {t('HELP34300')}
                                    <ul className="d-list">
                                                <li>{t('HELP34310')}</li>
                                            </ul>
                                        </li>
                                        <li>{t('HELP34400')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p3-4">3.4. {t('HELP34000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u04" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP34100')}</li>
                                        <li>
                                        {t('HELP34200')}
                                    <ul className="d-list">
                                                <li>{t('HELP34210')}</li>
                                            </ul>
                                        </li>
                                        <li>
                                        {t('HELP34300')}
                                    <ul className="d-list">
                                                <li>{t('HELP34310')}</li>
                                            </ul>
                                        </li>
                                        <li>{t('HELP34400')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p4">4. {t('HELP40000')}</h3>
                            <h4 id="p4-1">4.1. {t('HELP41000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u05" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP41100')}</li>
                                        <li>{t('HELP41200')}</li>
                                        <li>{t('HELP41300')}</li>
                                        <li>{t('HELP41400')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p4-2">4.2. {t('HELP42000')}</h4>
                            <div className="manual-info-box">
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP42100')}</li>
                                        <li>
                                        {t('HELP42200')}
                                            <ul className="d-list">
                                                <li>{t('HELP42210')}
                                                    <img src={"/images/help/u06" + t('IMG_LANG') + ".png" } />
                                                    <img src={"/images/help/u07" + t('IMG_LANG') + ".png" } />
                                                    {t('HELP42211')} <br />
                                                    {t('HELP42212')}
                                                </li>
                                                <li className="mgt10">{t('HELP42220')}
                                                    <img src={"/images/help/u08" + t('IMG_LANG') + ".png" } />
                                                    {t('HELP42221')}
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p4-3">4.3. {t('HELP43000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u09" + t('IMG_LANG') + ".png" } />
                                <img src={"/images/help/u10" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP43100')}</li>
                                        <li>{t('HELP43200')}</li>
                                        <li>{t('HELP43300')}</li>
                                        <li>{t('HELP43400')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p5">5. {t('HELP50000')}</h3>
                            <h4 id="p5-1">5.1. {t('HELP51000')}</h4>
                            <div className="manual-info-box">
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP51000')}</li>
                                        <li>{t('HELP52000')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p5-2">5.2. {t('HELP52000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u11" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP52000')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p5-3">5.3. {t('HELP53000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u12" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>
                                        {t('HELP53100')}
                                    <ul className="d-list">
                                                <li>{t('HELP53110')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p6">6. {t('HELP60000')}</h3>
                            <h4 id="p6-1">6.1. {t('HELP61000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u13" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>
                                        {t('HELP61100')}
                                    <ul className="d-list">
                                                <li>{t('HELP61110')}</li>
                                            </ul>
                                        </li>
                                        <li>{t('HELP61200')}</li>
                                        <li>{t('HELP61300')}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p7">7. {t('HELP70000')}</h3>
                            <h4 id="p7-1">7.1. {t('HELP71000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u14" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP71100')}</li>
                                        <li>
                                        {t('HELP71200')}
                                    <ul className="d-list">
                                                <li>{t('HELP71210')}</li>
                                                <li>{t('HELP71220')}</li>
                                                <li>{t('HELP71230')}</li>
                                                <li>{t('HELP71240')}</li>
                                                <li>{t('HELP71250')}</li>
                                                <li>{t('HELP71260')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p7-2">7.2. {t('HELP72000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u15" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP72100')}</li>
                                        <li>
                                        {t('HELP72200')}
                                    <ul className="d-list">
                                                <li>{t('HELP72210')}</li>
                                                <li>{t('HELP72220')}</li>
                                                <li>{t('HELP72230')}</li>
                                                <li>{t('HELP72240')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p8">8. {t('HELP80000')}</h3>
                            <div className="manual-info-box">
                                <img src={"/images/help/u16" + t('IMG_LANG') + ".png" } />
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p8-1">8.1. {t('HELP81000')}</h4>
                            <div className="manual-info-box">
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP81100')}</li>
                                        <li>
                                        {t('HELP81200')}
                                    <ul className="d-list">
                                                <li>{t('HELP81210')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p8-2">8.2. {t('HELP82000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u17" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP82100')}</li>
                                        <li>
                                        {t('HELP82200')}
                                    <ul className="d-list">
                                                <li>{t('HELP82210')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="manual-contents-box">
                        <div className="manual-contents-box">
                            <h3 id="p9">9. {t('HELP90000')}</h3>
                            <h4 id="p9-1">9.1. {t('HELP91000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u18" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP91100')}</li>
                                        <li>
                                        {t('HELP91200')}
                                    <ul className="d-list">
                                                <li>{t('HELP91210')}</li>
                                                <li>{t('HELP91220')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p9-2">9.2. {t('HELP92000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u20" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP92100')}</li>
                                        <li>
                                        {t('HELP92200')}
                                    <ul className="d-list">
                                                <li>{t('HELP92210')}</li>
                                                <li>{t('HELP92220')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="manual-contents-box">
                            <h4 id="p9-3">9.3. {t('HELP93000')}</h4>
                            <div className="manual-info-box">
                                <img src={"/images/help/u20" + t('IMG_LANG') + ".png" } />
                                <div className="manual-desc">
                                    <ul className="h-list">
                                        <li>{t('HELP93100')}</li>
                                        <li>
                                        {t('HELP93200')}
                                    <ul className="d-list">
                                                <li>{t('HELP93210')}</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Help;
