import React from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { handleResetPaginationActive } from "@/util/common";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const BoardNews = ({
  searchTitle,
  onChangeFieldValue,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
}) => {
  const { t } = useTranslation();
  return (
    <div className="w-full">
      <div className="mb-20">
        <div className="input-chaining">
          <input
            className={"form-control"}
            type="text"
            name="searchTitle"
            placeholder= {t('pleaseEnterTilte')}
            value={searchTitle}
            onChange={onChangeFieldValue}
          />
          <div
            className="append"
            onClick={() => {
              onFetchPage(1);
              handleResetPaginationActive();
            }}
          >
            <button className={"btn btn-s h-full border-0"}>{t('search')}</button>
          </div>
        </div>
      </div>
      <div className="table-pagination-container">
        <div className="table-container">
          {" "}
          <table>
            <thead>
              <tr>
                <th>{t('title1')}</th>
                <th>{t('CreatDate')}</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts && currentPosts.length === 0 && (
                <tr>
                  <td colSpan={2}>{t('noSearchResults')}</td>
                </tr>
              )}
              {currentPosts &&
                currentPosts.length > 0 &&
                currentPosts.map((post, i) => (
                  <tr key={`tbody-${i}`}>
                    <td className="text-align-start">
                      <Link
                        to={`/board/news/${post.boardContentsIdx}`}
                        className="text-primary text-bold"
                      >
                        {post.title}
                      </Link>
                    </td>
                    <td style={{ width: 200 }}>{post.regDttm}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default BoardNews;
