import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용
import { commafy, addNumberHypen } from "@/util/common";
import { Link } from "react-router-dom";

const TotalUsageHistory = ({
  memberInfo,
  currentPosts,
  totalPosts,
  postsPerPage,
  onFetchPage,
  currentPlan,
  currentHist,
  cancelSubscription,
  modalYn,
  modalYnChange,
}) => {
  // const [isCashChargeModule, setIsCashChargeModule] = useState(false);
  // const [isPlanExpireModule, setIsPlanExpireModule] = useState(false);
  // const [isPlanExpireOnModule, setIsPlanExpireOnModule] = useState(false);

  // const handleCashChargeModuleToggle = () => {
  //   setIsCashChargeModule(prevState => !prevState);
  // };

  // const handlePlanExpireModuleToggle = () => {
  //   setIsPlanExpireModule(prevState => !prevState);
  // };

  // const handlePlanExpireOnModuleToggle = () => {
  //   setIsPlanExpireOnModule(prevState => !prevState);
  // };

  const { t } = useTranslation();
  console.log("whatiscurrentPlan" + JSON.stringify(currentPlan));
  return (
    <div className="page-container">
      <div className="page__title"><Trans >{t('UsageHistoryMn')}</Trans> </div>
      <small className="page__subtitle"><Trans >{t('UsageHistoryCheck')}</Trans> </small>
      <div className="divider"></div>
        <div className="table-pagination-container">
          <div className="wish-list-control">
            <div>
              <span><Trans >{t('planInformation')}</Trans></span>
            </div>
          </div>
          <div className="table-container ">
            <table>
              <thead>
              <tr>
                <td width="25%">{t('UsagePlan')}</td>
                {currentPlan === null ? (
                  <th width="25%">-</th>
                ) : (
                  <th width="25%">
                    {currentPlan.planName} ({currentPlan.startDttm} ~ {currentPlan.endDttm})
                  </th>
                )}

                <td width="25%">{t('totalUsage')}</td>
                
                {currentPlan ? (
                  currentPlan.overYn === "over" ? (
                    <th width="25%">
                      <span style={{ color: 'red' }}>
                        {commafy(currentPlan.plan_usage)}
                      </span> / {commafy(currentPlan.plan_capacity)}
                      {/* ( {currentPlan.usagePercent}% {t('remain')} ) */}
                    </th>
                  ) : (currentPlan.overYn === "remain" || currentPlan.overYn === "zero") ? (
                    <th width="25%">
                      <span style={{ color: 'blue' }}>
                        {commafy(currentPlan.plan_usage)}
                      </span> / {commafy(currentPlan.plan_capacity)}
                      {/* ( {currentPlan.usagePercent}% {t('remain')} ) */}
                    </th>
                  ) : (
                    <th width="25%">-</th> 
                  )
                ) : (
                  <th width="25%">-</th> 
                )}
              </tr>
              </thead>
            </table>
            <table>
              <thead>
                <tr>
                  <td width="25%">{t('NextPayDate')}</td>
                  {currentPlan === null ? (
                    <th width="25%">-</th> 
                  ) : (
                    <th width="25%">{currentPlan.payment_date}</th>
                  )}
                  <td width="25%">{t('paymentMethod')}</td>
                  {currentPlan === null ? (
                    <th width="25%">-</th> 
                  ) : (
                    <th width="25%">{t(currentPlan.pay_type_nm)}({t(currentPlan.pay_method_nm)})</th>
                  )}
                </tr>
              </thead>
            </table>
          </div>
          {currentPlan && Number(currentPlan.plan_usage) > Number(currentPlan.plan_capacity)? <div>
            <div style={{ paddingLeft: '23px', paddingBottom: '23px' }}>
              <small className="page__subtitle">{t('totalUsage1')} {commafy(currentPlan.totalAmount) }</small>
            </div>
          </div> :null  }
          {/* {currentPlan && (currentPlan.overYn === "zero" || currentPlan.overYn === "over") && (
          <div>
            <div style={{paddingLeft: '23px', paddingBottom: '23px'}}>
              {(currentPlan.planName === "basic" &&
                <small className="page__subtitle"> {t('exceeduseLimit1')} <Link style={{ color: 'blue' }} to="/info/planInfo">{t('change')}  </Link></small>
              )}
              {(currentPlan.planName === "standard" &&
                <small className="page__subtitle"> {t('exceeduseLimit2')}<Link style={{ color: 'blue' }} to="/info/planInfo">{t('change')}   </Link></small>
              )}
              {(currentPlan.planName === "pro" &&
                <small className="page__subtitle"> {t('exceeduseLimit3')} <Link style={{ color: 'blue' }} onClick={handleCashChargeModuleToggle}>{t('introductionInquiry')}  </Link></small>
              )}
            </div>
          </div>
          )} */}
        </div>
        <div className="pt-30"></div>
        <div className="card__footer align-items-end">
          <div className="d-inline-flex" >
            {currentPlan?.paypalId ?  (
              <>
              <button className="btn bg-danger btn-s mb-5" onClick={modalYnChange}>{t('unsubscribe')}</button>
              <button className="btn btn-s mb-5"><Link to="/info/planInfo">{t('changeplan')}</Link></button>
              </>
            ): null}
            
          </div>
        </div>
        <div className="pt-30"></div>
        <div className="table-pagination-container">
          <div className="wish-list-control">
            <div>
              <span><Trans >{t('useHistory')}</Trans></span>
            </div>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th><Trans >{t('requestDateTime')}</Trans></th>
                  <th><Trans >{t('content')}</Trans></th>
                  <th>{t('requester')}</th>
                  <th>{t('NumOfWords')}</th>
                  <th>{t('cacheUsage')}</th>
                  {/* <th>{t('AddPaymentWon')}</th> */}
                  {/* <th>사용 데이터</th> */}
                  <th>{t('AddPaymentWon')}</th>
                </tr>
              </thead>
              <tbody>
                {currentHist && currentHist.length === 0 && (
                  <tr>
                    <td colSpan={7}><Trans >{t('noSearchResults')}</Trans></td>
                  </tr>
                )}
                {currentHist &&
                  currentHist.length > 0 &&
                  currentHist.map((hist, i) => (
                  <tr key={`tbody-${i}`}>
                    <td className="line-height-5">{hist.reqDttm}</td> {/* 요청일시 */}
                    <td className="d-flex justify-content-space-between">
                      <div className="text-align-start line-height-two">
                        <div>{t(hist.transCoverageName)}</div>
                        <div className="text-bold">{hist.documentNo}</div>
                      </div>
                      <div className="text-align-end line-height-two">
                        <span
                          className={`badge ${pickBadgeStyle(hist.transState)}`}
                        >
                          {t(hist.transStateName)}
                        </span>
                        <div>
                          <span className="text-bold mr-5">
                            {t(hist.transTypeName)}
                          </span>
                          <span>
                            {" "}
                            {hist.orgLang}{" "}
                            <em className="fas fa-arrow-right"></em>{" "}
                            {hist.transLang}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="line-height-5">
                      <div>{hist.name}</div>
                      <div className="mt-5">
                        <small>{`(${hist.mail})`}</small>
                      </div>
                      <div>{addNumberHypen(hist.hphone)}</div>
                    </td>{/* 요청자 */}
                    <td className="line-height-5">{commafy(hist.charCount)}</td> {/* 단어수 */}
                    <td className="line-height-5">{commafy(hist.pointUsage)}</td> {/* 캐시 사용 */}
                    {/* <td className="line-height-5">{commafy(hist.changeUsage)}</td> 추가 결제(원) */}
                    <td className="line-height-5">{commafy(hist.payUsage)}</td> {/* 추가 결제(원) */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        <div className="pagination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={(e) => {
              onFetchPage(e.selected + 1);
            }}
            pageRangeDisplayed={5}
            pageCount={Math.ceil(totalPosts / postsPerPage)}
            previousLabel="< prev"
            renderOnZeroPageCount={null}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
      
      {modalYn === true && currentPlan != null && (
        <div className="modal-wrap">
          <section className="modal-contents">
            <div className="modal-contents__header">
              {/* 요금제 해지 */}
              <span><Trans >{t('totalUsageHistory208')}</Trans></span>
            </div>
            <div className="modal-contents__main">
              {/* 환불 예정 금액은[] 입니다*/}
              <p>{t('totalUsageHistory210')}
              {/*
                 [
                  {
                    // plan_usage와 plan_capacity를 계산하여 값 출력
                    (() => {
                      const planUsage = Number(currentPlan.plan_usage);
                      const planCapacity = Number(currentPlan.plan_capacity);
                      
                      let resultUsage = 0;

                      if (planCapacity > planUsage  ) {
                        resultUsage = (planCapacity / planCapacity) * (planCapacity - planUsage);  // 계산식
                      }
                      return commafy(resultUsage);
                    })()
                  }
                ]
              */}{commafy(currentPlan.refund)}
                  {t('totalUsageHistory211')}. </p>
              {/* 정말 해지하시겠습니까? */}
              <p><Trans >{t('totalUsageHistory212')}</Trans></p>
            </div>
            <div className="modal-contents__footer">
              {/* 확인 */}
              <button type="button" className="btn btn-close" onClick={() => { cancelSubscription(currentPlan?.paypalId) }}><Trans >{t('totalUsageHistory215')}</Trans></button>
              {/* 취소 */}
              <button type="button" className="btn btn-close" onClick={modalYnChange}><Trans >{t('totalUsageHistory216')}</Trans></button>
            </div>
          </section>
        </div>
      )}

      
    
    </div>  
  );
};

function pickBadgeStyle(transState) {
  switch (transState) {
    case "H":
      return "badge-secondary";
    case "A":
      return "badge-info";
    case "W":
      return "badge-warning";
    case "P":
      return "badge-primary";
    case "E":
      return "badge-success";
    default:
      break;
  }
}
export default TotalUsageHistory;
