import React from "react";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const KRGuide = () => {
  const { t } = useTranslation();
  return (
    <div className="card pd-0">
      <div className="card__main pd-0">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th className={"border-top-0"} scope="col" colSpan="1">
                <Trans >{t('numberType')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('numberFormat')}</Trans>
              </th>
              <th className={"border-top-0"} scope="col">
                <Trans >{t('example')}</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="5"><Trans >{t('apaNo')}</Trans></td>
              <td><Trans >{t('y4ps7')}</Trans></td>
              <td>2016-0002154</td>
            </tr>
            <tr>
              <td><Trans >{t('krpy4ps7pdc')}</Trans></td>
              <td>KR20160002154A1</td>
            </tr>
            <tr>
              <td><Trans >{t('selpy4ps7')}</Trans></td>
              <td>1020160002154</td>
            </tr>
            <tr>
              <td><Trans >{t('selsy4ss7')}</Trans></td>
              <td>10-2016-0002154</td>
            </tr>
            <tr>
              <td><Trans >{t('selsy4ss7dc')}</Trans></td>
              <td>10-2016-0002154A</td>
            </tr>
            <tr>
              <td rowSpan="4"><Trans >{t('regNumber')}</Trans></td>
              <td><Trans >{t('s7')}</Trans></td>
              <td>1623332</td>
            </tr>
            <tr>
              <td><Trans >{t('krps7')}</Trans></td>
              <td>KR1623332</td>
            </tr>
            <tr>
              <td><Trans >{t('selps7p0000')}</Trans></td>
              <td>1016233320000</td>
            </tr>
            <tr>
              <td><Trans >{t('selss7')}</Trans></td>
              <td>10-1623332</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default KRGuide;
