import React from "react";
import "./Policy.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용


const Policy = ({ showPolicy, togglePolicy }) => {
  const showHideClassName = showPolicy
    ? `policy-modal display-block`
    : `policy-modal display-none`;
  const { t } = useTranslation();
  return (
    <div className={showHideClassName} onClick={togglePolicy}>
      <section
        className="policy-modal-contents"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="policy-modal-contents__header">
          <span><Trans >{t('tremsOfService')}</Trans></span>
          <div
            className="policy-modal-contents__close-btn"
            onClick={togglePolicy}
          >
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="policy-modal-contents__main">
          <h4><Trans >{t('PolicyArtticle1')}</Trans></h4>
          <p>
          <Trans >{t('PolicyArtticle1Detail')}</Trans>
          </p>
          <h4><Trans >{t('PolicyArtticle2')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle2Detail1')}</Trans></p>
          <ul className="mb-15">
            <li>
            <Trans >{t('PolicyArtticle2Detail2')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle2Detail3')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle2Detail4')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle2Detail5')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle2Detail6')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle2Detail7')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle2Detail8')}</Trans>
            </li>
          </ul>
          <h4><Trans >{t('PolicyArtticle3')}</Trans></h4>
          <ul className="mb-15">
            <li>
            <Trans >{t('PolicyArtticle3Detail1')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle3Detail2')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle3Detail3')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle3Detail4')}</Trans>
            </li>
          </ul>
          <h4><Trans >{t('PolicyArtticle4')}</Trans></h4>
          <ul className="mb-15">
            <li>
            <Trans >{t('PolicyArtticle4Detail1')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle4Detail2')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle4Detail3')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle4Detail4')}</Trans>
            </li>
           
          </ul>
          <h4><Trans >{t('PolicyArtticle5')}</Trans></h4>
          <p>
          <Trans >{t('PolicyArtticle5Detail1')}</Trans>
          </p>
          <h4><Trans >{t('PolicyArtticle6')}</Trans></h4>
          <p>
          <Trans >{t('PolicyArtticle6Detail1')}</Trans>
          </p>
          <h4><Trans >{t('PolicyArtticle7')}</Trans></h4>
          <p>
          <Trans >{t('PolicyArtticle7Detail1')}</Trans>
          </p>
          <h4><Trans >{t('PolicyArtticle8')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle8Detail1')}</Trans>

          </p>
          
          <h4><Trans >{t('PolicyArtticle9')}</Trans></h4>
          <p>
          <Trans >{t('PolicyArtticle9Detail1')}</Trans>
          </p>
          <p>
          <Trans >{t('PolicyArtticle9Detail2')}</Trans>
          </p>
                   
          <h4> <Trans >{t('PolicyArtticle10')}</Trans></h4>
          <p>
          <Trans >{t('PolicyArtticle10Detail1')}</Trans>
          </p>
         
          <h4> <Trans >{t('PolicyArtticle11')}</Trans></h4>
          <ul className="mb-15">
            <li>
            <Trans >{t('PolicyArtticle11Detail1')}</Trans>
            </li>
            <li>
            <Trans >{t('PolicyArtticle11Detail2')}</Trans>
            </li>
          </ul>
          <h4> <Trans >{t('PolicyArtticle12')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle12Detail1')}</Trans></p>
          
          <h4><Trans >{t('PolicyArtticle13')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle13Detail1')}</Trans>
          </p>
          
          <h4><Trans >{t('PolicyArtticle14')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle14Detail1')}</Trans></p>
          
          <h4><Trans >{t('PolicyArtticle15')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle15Detail1')}</Trans></p>
          
          <h4><Trans >{t('PolicyArtticle16')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle16Detail1')}</Trans></p>
          <h4><Trans >{t('PolicyArtticle17')}</Trans></h4>
          <p><Trans >{t('PolicyArtticle17Detail1')}</Trans></p>
        </div>
      </section>
    </div>
  );
};

export default Policy;
