import React from "react";
import "./PriceModal.scss";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용


const PriceModal = ({ showPrice, togglePrice }) => {
  const showHideClassName = showPrice
    ? `price-modal display-block`
    : `price-modal display-none`;
  const { t } = useTranslation();


  return (
    <div className={showHideClassName} onClick={togglePrice}>
      <section
        className="modal-contents"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-contents__header">
          <span><Trans >{t('rechargeCash')}</Trans></span>
          <div className="modal-contents__close-btn" onClick={togglePrice}>
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="modal-contents__main">
          <table>
            <thead>
              <tr>
                <th><Trans >{t('needTranslation')}</Trans></th>
                <th className="text-danger"><Trans >{t('addPayment')}</Trans></th>
                <th><Trans >{t('totalPayment')}</Trans></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>50,000 {t('cash')}</td>
                <td className="text-danger">5,000 {t('cash')}</td>
                <td>55,000 {t('won')}</td>
              </tr>
              <tr>
                <td>100,000 {t('cash')}</td>
                <td className="text-danger">10,000 {t('cash')}</td>
                <td>110,000 {t('won')}</td>
              </tr>
              <tr>
                <td>300,000 {t('cash')}</td>
                <td className="text-danger">30,000 {t('cash')}</td>
                <td>330,000 {t('won')}</td>
              </tr>
              <tr>
                <td>500,000 {t('cash')}</td>
                <td className="text-danger">50,000 {t('cash')}</td>
                <td>550,000 {t('won')}</td>
              </tr>
              <tr>
                <td>1,000,000 {t('cash')}</td>
                <td className="text-danger">150,000 {t('cash')}</td>
                <td>1,100,000 {t('won')}</td>
              </tr>
              <tr>
                <td>2,000,000 {t('cash')}</td>
                <td className="text-danger">300,000 {t('cash')}</td>
                <td>2,200,000 {t('won')}</td>
              </tr>
              <tr>
                <td>5,000,000 {t('cash')}</td>
                <td className="text-danger">800,000 {t('cash')}</td>
                <td>5,500,000 {t('won')}</td>
              </tr>
            </tbody>
          </table>
          <div className="card modal-contents__card mt-20 mb-20">
            <div className="card__header">
              <h4>{t('rechargeMethod')}</h4>
            </div>
            <div className="card__main">
              <ul>
                <li>
                <Trans >{t('depositAmonut')}</Trans>
                </li>
                <li>
                  <Trans >{t('depositAmonut_1')}</Trans>
                </li>
                <li>
                 <Trans >{t('taxInformation')}</Trans>
                  <ul>
                    <li><Trans >{t('cashReceipt')}</Trans></li>
                    <li>
                    <Trans >{t('taxInvoice')}</Trans>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PriceModal;
