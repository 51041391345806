import { Link } from "react-router-dom";
import React from "react";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const UserInfo = ({ userInfo = {}, onChangeFieldValue, onSubmit, errors }) => {
  const { t } = useTranslation();
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="card w-full">
        <div className="card__header pd-0">Profile</div>
        <div className="card__main">
          <div className="form-group mb-5">
            <label><Trans >{t('email')}</Trans></label>
            <div className="input-chaining">
              <input
                className={"form-control disabled"}
                type="text"
                name="email"
                placeholder={t('enterEmail')}
                value={userInfo.email}
                onChange={onChangeFieldValue}
                disabled
              />
            </div>
          </div>
          <div className="form-group radio">
            <label><Trans >{t('type')} </Trans></label>
            <div className="mt-5 mb-10 ">
              <label className={"c-radio"}>
                <input
                  type="radio"
                  name="memberType"
                  value="3"
                  checked={userInfo.memberType === 2 && userInfo.adminYn ==="Y"}
                  disabled
                />{" "}
                <span className="fa fa-circle" />
                {t('companyAdmin')} 
              </label>
              <label className="c-radio">
                <input
                  type="radio"
                  name="memberType"
                  value="2"
                  checked={userInfo.memberType === 2  && userInfo.adminYn ==="N"}
                  disabled
                />{" "}
                <span className="fa fa-circle" />
                {t('company')}
              </label>
              <label className={"c-radio"}>
                <input
                  type="radio"
                  name="memberType"
                  value="1"
                  checked={userInfo.memberType === 1}
                  disabled
                />{" "}
                <span className="fa fa-circle" />
                {t('individual')} 
              </label>
            </div>
          </div>
          <div className="form-group" 
          style={{
            display: userInfo.memberType === 2 ? "block" : "none",
          }}
          >
            <label><Trans >{t('companyName')} </Trans></label>
            <input
              className={`form-control disabled`}
              type="text"
              name="company"
              value={userInfo.company}
              onChange={onChangeFieldValue}
            />
            <p className="validation-message">{errors.company}</p>
          </div>
          <div className="form-group mb-10">
            <label><Trans >{t('name')} </Trans></label>
            <div className="input-chaining">
              {" "}
              <input
                className={`form-control disabled`}
                type="text"
                name="name"
                value={userInfo.name}
                disabled
              />
            </div>
          </div>
          <div className="form-group mb-15">
            <label><Trans >{t('contactNumber')} </Trans></label>
            <div className="input-chaining">
              <input
                className="form-control"
                type="text"
                name="hphone"
                maxLength="12"
                value={userInfo.hphone}
                onChange={onChangeFieldValue}
              />
            </div>
            <p className="validation-message">{errors.hphone}</p>
          </div>
        </div>
        <div className="card__footer align-items-end">
          <button className="btn btn-s mb-5">{t('modifyMeberInfo')} </button>

          <span><Trans >{t('noUseIpkk')} </Trans></span>
          <Link to="/my/quit">{t('withdrawalGoto')}</Link>
        </div>
      </div>
    </form>
  );
};

export default UserInfo;
