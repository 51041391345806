import React, { useState } from "react";
import "./Privacy.scss";
import {PRIVACY_CONTENTS} from "@/commonModule/Privacy/privacy_constant";
import { Trans, useTranslation } from 'react-i18next'; // i18next 사용

const Privacy = ({ showPrivacy, togglePrivacy }) => {
  const [contents, setContents] = useState(PRIVACY_CONTENTS['v4_0_' + localStorage.getItem("lang")]);

  const showHideClassName = showPrivacy
    ? `privacy-modal d-flex`
    : `privacy-modal display-none`;

  const changeVersion = (e) => {
    setContents(PRIVACY_CONTENTS[e.target.value]);
  }
  const { t } = useTranslation();

  return (
    <div className={showHideClassName} onClick={togglePrivacy}>
      <section
        className="privacy-modal-contents"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="privacy-modal-contents__header">
          <span><Trans >{t('privacyPolicy')}</Trans></span>
          <div
            className="privacy-modal-contents__close-btn"
            onClick={togglePrivacy}
          >
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className="privacy-modal-contents__main">
          <h3 className="mb-20">
            <span>{t('PRIV10001') + ' ' + t('privacyPolicy') } </span>
            <select className="version-selector" onChange={changeVersion} style={{display: 'none'}}>
              <option value={"v3_0" + t('IMG_LANG')}>v 3.0</option>
              <option value={"v4_0" + t('IMG_LANG')} selected>v 4.0</option>
            </select>
          </h3>
          <div dangerouslySetInnerHTML={{ __html: contents }} />
        </div>
      </section>
    </div>
  );
};

export default Privacy;
