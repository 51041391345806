import { clientLogout } from "@/commonSlices/authSlice";
import {  httpRequest } from "@/util/common";
import React, { Component } from "react";
import { connect } from "react-redux";
import BoardInquiry from "./BoardInquiry";
import { withTranslation  } from 'react-i18next'; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class BoardInquiryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTitle: "",
      boardInquiry: [],
      postsPerPage: 6,
      total: 0,
    };
  }

  /**
   * 서버에서 boardNews 정보 받아오기
   */
  componentDidMount() {
    this.fetchBoardInquiry(1);
  }

  render() {
    const { searchTitle, boardInquiry, postsPerPage, total } = this.state;

    return (
      <BoardInquiry
        searchTitle={searchTitle}
        onChangeFieldValue={this.handleChangeFieldValue}
        currentPosts={boardInquiry}
        totalPosts={total}
        postsPerPage={postsPerPage}
        onFetchPage={this.fetchBoardInquiry}
      />
    );
  }

  /**
   * 서버에서 새소식 정보 받아오기
   */
  fetchBoardInquiry = async (number) => {
    const { searchTitle, postsPerPage } = this.state;
    const { history } = this.props;
    try {
      const {
        data: { items, total },
      } = await httpRequest.get(
        `board/inquiry?page=${number}&size=${postsPerPage}&title=${searchTitle}`
      );

      this.setState({ ...this.state, boardInquiry: items, total });
    } catch (err) {
      history.push(`/error?code=${err.response.status}`);
    }
  };

  /**
   * 검색타이틀 인풋 변경
   * @param {*} e
   */
  handleChangeFieldValue = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };
}


export default connect(null, { clientLogout })(BoardInquiryContainer);
