export const COUNTRY_TYPE_INFO = {
  KR: {
    nameKo: "한국",
    nameEn: "South Korea", // 영어 이름 추가
    countryType: "KR",
  },
  US: {
    nameKo: "미국",
    nameEn: "United States", // 영어 이름 추가
    countryType: "US",
  },
  CN: {
    nameKo: "중국",
    nameEn: "China", // 영어 이름 추가
    countryType: "CN",
  },
  JP: {
    nameKo: "일본",
    nameEn: "Japan", // 영어 이름 추가
    countryType: "JP",
  },
  /* 추후 한중, 한일 추가 예정 */
};